import { defineStore } from 'pinia'

const usePricingStore = defineStore('pricing', {
    state: () => ({
        all: null,
        pricingParams: null,
        pricingParamsCount: null,
        paramsTotalPages: 0,
        currentPricingParam: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        groups: null,
        groupsCount: null,
        groupsTotalPages: 0,
        currentGroup: null,
        currentClientGroupZip: null,
        clientGroupsZip: null,
        clientGroupsZipCount: null,
        clientGroupsZipTotalPages: 0,
    }),

    actions: {
        searchPricings(payload, populate = true) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (
                        type.dbField === 'Id' ||
                        type.dbField === 'Pricing_Client.IdClient'
                    ) {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/pricing/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            } else {
                apiUrl = `/api/v1/pricing/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const formattedPricings = []
                            res.data.Records.forEach((pricing) => {
                                formattedPricings.push({
                                    id: pricing.Id,
                                    name: pricing.Name,
                                    active: pricing.Active,
                                    date_begin: pricing.DateBegin,
                                    date_end: pricing.DateEnd,
                                    type:
                                        pricing.Type === 'tour'
                                            ? 'order_group'
                                            : 'order_price_param',
                                    realType: pricing.Type,
                                    clients: pricing.Clients,
                                    pricing_param_clients:
                                        pricing.PricingParamClients,
                                    pricing_postal_code_group_clients:
                                        pricing.PricingPostalCodeGroupClients,
                                    Id: pricing.Id,
                                    Name: pricing.Name,
                                    Active: pricing.Active,
                                    DateBegin: pricing.DateBegin,
                                    DateEnd: pricing.DateEnd,
                                    Type: pricing.Type,
                                    Clients: pricing.Clients,
                                    PricingParamClients:
                                        pricing.PricingParamClients,
                                    PricingPostalCodeGroupClients:
                                        pricing.PricingPostalCodeGroupClients,
                                    PostalCode: '',
                                    DefaultPricing: pricing.DefaultPricing,
                                    ClientsToRemove: [],
                                    PricingParamClientsToRemove: [],
                                    PricingPostalCodeGroupClientsToRemove: [],
                                })
                            })

                            this.all = formattedPricings

                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createPricing(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/pricing', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updatePricing(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/pricing/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOnePricing(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/pricing/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deletePricingClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/pricingClient/deleteFromPricingWithChildren`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deactivatePricing(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/pricing/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deactivateManyPricings(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/pricing/deactivateMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        searchPricingParams(payload, populate = true) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/pricingParam/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            } else {
                apiUrl = `/api/v1/pricingParam/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const formattedPricingParams = []
                            res.data.Records.forEach((pricingParam) => {
                                const type =
                                    pricingParam.Type === 'volume'
                                        ? 'vol'
                                        : pricingParam.Type === 'size'
                                          ? 'param_size'
                                          : pricingParam.Type
                                formattedPricingParams.push({
                                    id: pricingParam.Id,
                                    title: pricingParam.Title,
                                    type,
                                    realType: pricingParam.Type,
                                    min: pricingParam.Min,
                                    max: pricingParam.Max,
                                })
                            })

                            this.pricingParams = formattedPricingParams
                            this.pricingParamsCount = count
                            this.paramsTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.pricingParams = null
                            this.pricingParamsCount = 0
                            this.paramsTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createPricingParam(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/pricingParam', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentPricingParam = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updatePricingParam(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/pricingParam/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentPricingParam = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOnePricingParam(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/pricingParam/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentPricingParam = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        deletePricingParam(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/pricingParam/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentPricingParam = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteManyPricingParam(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/pricingParam/deleteMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentPricingParam = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        searchGroups(payload, populate = true) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/pricingPostalCodeGroup/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            } else {
                apiUrl = `/api/v1/pricingPostalCodeGroup/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const formattedGroups = []
                            res.data.Records.forEach((group) => {
                                const postalCodes = []
                                if (group.PricingPostalCode) {
                                    group.PricingPostalCode.forEach(
                                        (postalCode) => {
                                            postalCodes.push(
                                                postalCode.PostalCode,
                                            )
                                        },
                                    )
                                }

                                formattedGroups.push({
                                    id: group.Id,
                                    name: group.Name,
                                    postal_codes: postalCodes.join(', '),
                                })
                            })

                            this.groups = formattedGroups
                            this.groupsCount = count
                            this.groupsTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.groups = null
                            this.groupsCount = 0
                            this.groupsTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/pricingPostalCodeGroup', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentGroup = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/pricingPostalCodeGroup/${payload.Id}`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        this.currentGroup = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/pricingPostalCodeGroup/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentGroup = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        deleteGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/pricingPostalCodeGroup/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentGroup = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteManyGroups(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/pricingPostalCodeGroup/deleteMany`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        this.currentGroup = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        searchClientPricingZipGroups(payload, populate = true) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/pricingPostalCodeGroupClient/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            } else {
                apiUrl = `/api/v1/pricingPostalCodeGroupClient/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=${populate}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const formattedGroups = []
                            res.data.Records.forEach((group) => {
                                formattedGroups.push({
                                    id: group.Id,

                                    client:
                                        group.Client.Name ??
                                        this.i18n.global.t('all'),
                                    pricing:
                                        group.Pricing.Name ??
                                        this.i18n.global.t('general'),
                                    pickup_zip_group:
                                        group.PricingPostalCodeGroupPickup
                                            ?.Name ?? this.i18n.global.t('all'),
                                    delivery_zip_group:
                                        group.PricingPostalCodeGroupDelivery
                                            ?.Name ?? this.i18n.global.t('all'),
                                    id_client: group.IdClient,
                                    id_pricing: group.IdPricing,
                                    value: group.Value,
                                    id_pickUpZip:
                                        group.IdPricingPostalCodeGroupPickup,
                                    id_deliveryZip:
                                        group.IdPricingPostalCodeGroupDelivery,
                                    date_begin: group.DateBegin,
                                })
                            })

                            this.clientGroupsZip = formattedGroups
                            this.clientGroupsZipCount = count
                            this.clientGroupsZipTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.clientGroupsZip = null
                            this.clientGroupsZipCount = 0
                            this.clientGroupsZipTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createClientPricingZipGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/pricingPostalCodeGroupClient', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentClientGroupZip = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateClientPricingZipGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/pricingPostalCodeGroupClient/${payload.Id}`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        this.currentClientGroupZip = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneClientPricingZipGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/pricingPostalCodeGroupClient/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentClientGroupZip = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        deleteClientPricingZipGroup(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/pricingPostalCodeGroupClient/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentClientGroupZip = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteManyClientPricingZipGroups(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/pricingPostalCodeGroupClient/deleteMany`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        this.currentClientGroupZip = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})

export { usePricingStore }
