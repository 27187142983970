<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'triggers_list'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="notificationsList"
                :items="triggers"
                :columns="columns"
                :tableWidth="'table-fixed w-auto'"
                :storeToSort="'notifications'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :summary="true"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null, true)"
                @update="openModal($event)"
                @duplicate="openModal($event, true, true)"
                @activate="doDeactivate($event, true)"
                @deactivate="doDeactivate($event)"
                @deactivateAll="doDeactivateMany($event)"
                @openEnumModalInfo="openEnumModalInfo($event)"
                @removeclients="doDeleteClientPricing($event)"
            >
            </filtrable-table>
        </div>

        <!-- <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :canCancel="false"
            :closeMark="false"
            :showSubmit="false"
            :mxHeight="'h-screen'"
            :buttonAlign="'justify-center'"
        >
            <template #body>
                <create-or-update-pricing
                    :isUpdate="isUpdate"
                    :isDuplicate="isDuplicate"
                    :pricingId="pricingId"
                    @cancel="openModal()"
                    @save="openModal()"
                ></create-or-update-pricing
            ></template>
        </modal> -->
        <!-- <modal
            v-if="showEnumModalInfo"
            id="enumModalInfo"
            :mxWidth="'w-2/5'"
            :canCancel="true"
            :showSubmit="false"
            :cancelText="'close'"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
            @closeModal="openEnumModalInfo(null)"
        >
            <template #body>
                <h2
                    class="text-xl flex justify-center font-bold mb-1 pt-4 pb-2"
                >
                    {{ $t('pricing_params') }}
                </h2>
                <div v-if="enumInfo.pricing_param_clients.length > 0">
                    <div
                        class="p-4"
                        v-for="(param, index) in enumInfo.pricing_param_clients"
                        :key="index"
                    >
                        {{
                            `${param.DateBegin} ⋅ ${param.PricingParam.Title} ⋅ ${param.Value} € `
                        }}
                    </div>
                </div>
                <div v-else>
                    <div
                        class="italic mt-3 flex justify-center items-center theme-color"
                    >
                        {{ $t('no_params_selected') }}
                    </div>
                </div>
                <h2
                    class="text-xl flex justify-center font-bold mb-1 pt-4 pb-2"
                >
                    {{ $t('pricing_postal_groups') }}
                </h2>
                <div
                    v-if="enumInfo.pricing_postal_code_group_clients.length > 0"
                >
                    <div
                        class="p-4"
                        v-for="(
                            group, index
                        ) in enumInfo.pricing_postal_code_group_clients"
                        :key="index"
                    >
                        {{
                            `${group.DateBegin} ⋅ ${group.PricingPostalCodeGroupPickup.Name} -> ${group.PricingPostalCodeGroupDelivery.Name} ⋅ ${group.Value} €`
                        }}
                    </div>
                </div>
                <div v-else>
                    <div
                        class="italic mt-3 flex justify-center items-center theme-color"
                    >
                        {{ $t('no_group_selected') }}
                    </div>
                </div>
            </template>
         
        </modal> -->
        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span
                                    v-if="
                                        toConfirm.name && toConfirm.description
                                    "
                                    class="mr-1"
                                >
                                    {{
                                        `${toConfirm.name} - ${toConfirm.description}`
                                    }}
                                </span>
                                <span v-else-if="toConfirm.name" class="mr-1">
                                    {{ toConfirm.name }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex items-center justify-center mt-4">
                        {{ $t(confirmModalText3) }}
                    </div>
                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import { mapActions, mapStores, mapWritableState } from 'pinia'

import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useNotificationStore } from '@/stores/notificationStore'
import { useUserStore } from '@/stores/userStore'
// import CreateOrUpdatePricing from '@/views/settings/pricings/CreateOrUpdatePricing.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'notificationsList',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
        // CreateOrUpdatePricing,
        Modal,
    },
    data() {
        return {
            enumInfo: {},
            showEnumModalInfo: false,
            entityTypeToDelete: 'notification_tc',
            showSummaryMap: false,
            pricingId: null,
            isUpdate: false,
            isDuplicate: false,
            show: false,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            eventTypeInterval: null,
            formData: {},
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
        }
    },
    watch: {
        triggers() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredtriggers')

        // id: +trigger.Id,
        //                                 id_type_evt: +trigger.IdTypeEvent,
        //                                 delait: +trigger.Delai,
        //                                 unit_delai: trigger.UnitDelai,
        //                                 action: trigger.Action,
        //                                 id_template_email:
        //                                     +trigger.IdTemplateEmail,
        //                                 id_template_sms: +trigger.IdTemplateSMS,
        this.columns = [
            // {
            //     name: 'active',
            //     filtrable: true,
            //     dbField: 'Active',
            //     type: 'select',
            //     sortable: false,
            //     withIconNoText: true,
            //     selected: {
            //         name: this.$t('active'),
            //         value: 1,
            //         dbField: 'Active',
            //     },
            //     options: [
            //         {
            //             name: 'active',
            //             value: 1,
            //             dbField: 'Active',
            //         },
            //         {
            //             name: 'deactivated',
            //             value: 0,
            //             dbField: 'Active',
            //         },
            //     ],
            // },
            {
                name: 'action',
                filtrable: true,
                dbField: 'Action',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.update,
                ),
            },
            // {
            //     name: 'type',
            //     filtrable: true,
            //     dbField: 'Type',
            //     type: 'select',
            //     sortable: false,
            //     options: [
            //         {
            //             name: 'order_group',
            //             value: 'tour',
            //             dbField: 'Type',
            //         },
            //         {
            //             name: 'order_price_param',
            //             value: 'order',
            //             dbField: 'Type',
            //         },
            //     ],
            // },
            {
                name: 'delai',
                filtrable: false,

                dbField: 'Delai',
                sortable: false,
            },
            {
                name: 'unit_delai',
                filtrable: false,

                dbField: 'UnitDelai',
                sortable: false,
            },
            // {
            //     name: 'date_end',
            //     filtrable: false,
            //     dbField: 'DateEnd',
            //     sortable: false,
            // },
            // {
            //     name: 'clients',
            //     filtrable: false,
            //     sortable: false,
            //     emitParent: true,
            //     enum: true,
            //     canRemoveEnum: checkAccess(
            //         this.userStore,
            //         this.notificationsActionAccess.update,
            //     ),
            //     clickableEnum: false,
            //     enumColNumber: 'grid-cols-3',
            // },

            // {
            //     name: 'creation_date',
            //     sortable: true,
            //     dbField: 'CreationDate',
            // },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.eventTypeInterval)
    },
    computed: {
        notificationsActionAccess() {
            return actionsAccess.notifications
        },
        ...mapWritableState(useNotificationStore, {
            currentTrigger: 'currentTrigger',
            triggers: 'allTriggers',
            count: 'countTriggers',
            fetchError: 'fetchError',
            totalPages: 'totalPagesTriggers',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.triggers.update,
                )
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.triggers.create,
                )
            ) {
                actions.push({
                    name: 'duplicate',
                    action: 'duplicate',
                    icon: 'clone',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.triggers.delete,
                )
            ) {
                // actions.push({
                //     name: 'deactivate',
                //     action: 'deactivate',
                //     icon: 'times',
                // })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.triggers.delete,
                )
            ) {
                // actions.push({
                //     name: 'deactivate_selection',
                //     action: 'deactivateAll',
                //     icon: 'times',
                // })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useNotificationStore, ['searchTriggers']),
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.notificationsActionAccess.triggers.create,
            )
        },
        upFilter(event) {
            clearInterval(this.eventTypeInterval)
            this.updateFilter(event)
        },

        formattedDate(date, withTime = false) {
            let formatted = new Date(date).toLocaleDateString(this.$i18n.locale)

            if (withTime) {
                formatted = new Date(date).toLocaleTimeString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )
            }

            return `${formatted}`
        },
        openEnumModalInfo(enumItem = null) {
            if (enumItem) {
                this.enumInfo.pricing_param_clients =
                    enumItem.pricing_param_clients ?? []
                this.enumInfo.pricing_postal_code_group_clients =
                    enumItem.pricing_postal_code_group_clients ?? []
            } else {
                this.enumInfo = {}
            }
            this.showEnumModalInfo = !this.showEnumModalInfo
        },
        openModal(id = null, create = false, duplicate = false) {
            this.isUpdate = false
            this.isDuplicate = false
            this.pricingId = null
            clearInterval(this.eventTypeInterval)
            if (id) {
                this.pricingId = id
                if (duplicate) {
                    this.isUpdate = false
                    this.isDuplicate = true
                } else {
                    this.isUpdate = true
                }
            } else {
                this.currentTrigger = null
                if (create === false) {
                    this.setResults()
                }
            }
            this.show = !this.show
        },

        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        // doDeactivate(id, activate = false) {
        //     clearInterval(this.eventTypeInterval)

        //     this.deactivatePricing(id)
        //         .then(() => {
        //             if (activate) {
        //                 this.$toast.success(this.$t('pricing_activate_ok'))
        //             } else {
        //                 this.$toast.success(this.$t('pricing_deactivate_ok', 1))
        //             }

        //             this.setResults()
        //         })
        //         .catch(() => {
        //             if (activate) {
        //                 this.$toast.error(this.$t('pricing_activate_ko'))
        //             } else {
        //                 this.$toast.error(this.$t('pricing_deactivate_ko', 1))
        //             }
        //         })
        // },
        // doDeactivateMany(ids) {
        //     this.deactivateManyPricings(ids)
        //         .then(() => {
        //             this.$toast.success(this.$t('pricing_deactivate_ok', 2))

        //             this.setResults()
        //         })
        //         .catch(() => {
        //             this.$toast.error(this.$t('pricing_deactivate_ko', 2))
        //         })
        // },

        // doDeleteClientPricing(data) {
        //     clearInterval(this.eventTypeInterval)
        //     clearInterval(this.eventTypeInterval)
        //     this.confirmAction = null
        //     this.elementsToConfirm = []
        //     this.entityTypeToDelete = 'client'
        //     this.confirmModalHeader = 'delete_modal_generic_header'
        //     this.confirmModalText = 'delete_modal_generic_text'
        //     this.confirmModalText2 = 'irreversible_action'
        //     this.confirmModalText3 = 'delete_modal_pricing_text_3'
        //     this.elementsToConfirm.push({ name: data.enumItem.Name })
        //     this.showConfirmModal = true
        //     const { PricingParamClients } = data.enumItem
        //     const { PricingPostalCodeGroupClients } = data.enumItem

        //     const deleteInterval = setInterval(async () => {
        //         if (this.confirmAction !== null) {
        //             clearInterval(deleteInterval)
        //             this.elementsToConfirm = []
        //             if (this.confirmAction === true) {
        //                 this.confirmAction = null
        //                 this.deletePricingClient({
        //                     IdPricing: data.item.id,
        //                     IdClient: data.enumItem.Id,
        //                     PricingParamClients,
        //                     PricingPostalCodeGroupClients,
        //                 })
        //                     .then(() => {
        //                         this.setResults()
        //                         this.$toast.success(this.$t('delete_ok'))
        //                     })
        //                     .catch(() => {
        //                         this.$toast.error(this.$t('delete_ko'))
        //                     })
        //             } else {
        //                 this.confirmAction = null

        //                 this.$toast.info(this.$t('action_canceled'))
        //             }
        //         }
        //     }, 150)
        // },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredtriggers')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredtriggers')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchTriggers(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredtriggers', search)
            this.eventTypeInterval = setInterval(() => {
                this.searchTriggers(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredtriggers')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
