
const translations = {
    En: {
        password: 'Password',
        dashboard: 'Dashboard',
        En: 'En',
        Fr: 'Fr',
        login: 'Email address or login',
        email_address: 'Email address',
        pw_notice:
            'The password requires at least seven characters, an uppercase, a lowercase, a number and a special character. You must at least reach green to be able to validate your password.',
        my_account: 'My account',
        confirm_password: 'Confirm password',
        firstname: 'Firstname',
        lastname: 'Lastname',
        phone: 'Phone number',
        logout: 'Logout',
        notifications: 'Notifications',
        open_menu: 'Open menu',
        forgot_password: 'Forgot password ?',
        connect: 'Log In',
        show: 'Show',
        destination: 'Destination',
        origin: 'Origin',
        hide: 'Hide',
        nightmode: 'Night mode',
        lang_switch: 'Switch language',
        company_name: 'Company name',
        address: 'Address',
        postal_code: 'Postal code',
        city: 'City',
        registration_number: 'SIRET',
        client_type: 'Client type',
        payment_type: 'Payment type',
        search: 'Search',
        new_client: 'New client',
        clients: 'Clients',
        client: 'Client',
        invoices: 'Invoices',
        vehicles: 'Vehicles',
        orders: 'Orders | Deliveries',
        becycle_orders: 'Orders | Deliveries',

        new_order: 'New order | Plan my delivery',
        import_orders: 'Import orders from file',
        imported_orders: 'Imported orders',
        imported_order: 'Imported order',
        imp_orders: 'Imported orders',

        tours: 'Tours',
        suppliers: 'Suppliers',
        areas: 'Areas',
        users: 'Users',
        drivers: 'Drivers',
        settings: 'Settings',
        login_error: 'Incorrect login or password',
        delivery_addresses: 'Addresses',
        mass_update: 'Mass updates',
        loading: 'Loading...',
        name: 'Name',
        missing_password: 'Password required.',
        missing_login: 'Email address or login required.',
        reference: 'Reference',
        full_order: 'Check order',
        update_ref_address: 'Apply changes to the reference postal address',
        number_of_parcels: 'Number of parcels',
        delivery_mode: 'Delivery mode',
        id: 'Id',
        weight: 'Weight (kg)',
        unit_weight: 'Unit weight (kg)',
        worth: 'Worth (€)',
        driver: 'Driver',
        reset_pw_ok:
            'If the account entered is valid, an email will be sent with instructions to update the password.',
        reset_pw_instructions:
            'The password reset instructions will be sent to your registered email address.',
        reset_pw_modal_title: 'Reset your password',
        send: 'Send',
        cancel: 'Cancel',
        creation_date: 'Creation date',
        modification_date: 'Update date',

        contact_information: 'Contact information',
        client_information: 'Client information',
        driver_information: 'Personnal informations',
        work_information: 'Work informations',

        password_strength: {
            start: 'Start typing to check your password strength',
            weak: 'Weak',
            better: 'Still weak, keep on trying!',
            nice: 'We are getting there...',
            good: 'Nice, but you can still do better',
            perfect: 'Congratulations, you made it!',
        },
        passwords_dont_match: "Passwords don't match",
        client_create_ok: 'Client successfully created',
        client_create_ko: 'Client creation failed ',
        search_address:
            'Search for address. Address fields will automatically be filled.',
        no_records_found: 'No records found',
        order: 'Sort order',
        sort_by: 'Sort by',
        items_per_page: 'Records per page',
        occasional: 'Occasional',
        month_invoice: 'Month invoice',
        monthinvoice: 'Month invoice',
        filters: 'Filters',
        notselected: 'Not selected',
        check: 'Check',
        by_name: 'by name',
        page: 'Page',
        of: 'of',
        results: '{n} Result | {n} Results',
        no_filter: 'No filter',
        steps: 'Steps',
        step: 'Step',

        status: 'Status',
        in_progress: 'In progress',
        finished: 'Finished',
        uploaded: 'Uploaded',
        inProgress: 'In progress',
        errors: 'Errors',
        by_client: 'by client',
        by_reference: 'by reference',
        delivery_type: {
            express: 'Express',
            fast: 'Fast',
            normal: 'Normal',
        },
        express: 'Express',
        fast: 'Fast',
        normal: 'Normal',
        delivery_status: {
            in_progress: 'In progress',
            finished: 'Finished',
            errors: 'Errors',
        },
        uploads: {
            in_progress: 'In progress',
            finished: 'Finished',
            uploaded: 'Uploaded',
            errors: 'Errors',
        },
        simulation_status: {
            errors: 'Errors',
            archived: 'Archived',
            requested: 'Requested',
            in_progress: 'In progress',
            finished: 'Finished',
        },
        delete: 'Delete',
        edit: 'Edit',
        action: 'Actions',
        search_name: 'Name',
        all: 'All',
        transfer: 'Transfer',
        to: 'to',
        optionnal: 'optionnal',
        client_update_ok: 'Client successfuly updated',
        client_update_ko: 'Client update failed ',
        address_update_ok: 'Address successfuly updated',
        address_update_ko: 'Address update failed ',
        address_create_ok: 'Address successfuly created',
        address_create_ko: 'Address creation failed ',
        client_delete_ok: 'Client successfuly deleted',
        client_delete_ko: 'Client deletion failed',
        user_delete_ok: 'User successfuly deleted',
        user_delete_ko: 'User deletion failed',
        address_delete_ok: 'Delivery address successfuly deleted',
        address_delete_ko: 'Delivery address deletion failed',
        select_one: 'Select one',
        update_client: 'Client update',
        contacts: 'Contacts',
        new_item: 'Create',
        delivery_addresses_for: 'Delivery adresses for',
        for: 'for',
        update_address: 'Address update',
        contact_and_wait: 'Contact and waiting time',
        delivery_hours: 'Delivery hours',
        driver_info: 'Driver information',
        additional_info: 'Additional information',
        waiting_time: 'Waiting time',
        contact_firstname: 'Contact firstname',
        contact_lastname: 'Contact lastname',

        contact_name_imported: 'Imported contact name',
        phone_imported: 'Imported phone',
        waiting_time_info:
            'The waiting time is expressed in minutes. The default value is 5 minutes.',
        opening_time: 'Opening time',
        closing_time: 'Closing time',
        open: 'Open',
        closed: 'Closed',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        delete_selection: 'Delete selected elements',
        save: 'Save',
        business_hour_updated: 'Business hours updated',
        business_hour_update_error: 'Error when updating business hours',
        order_step_correction: 'Delivery addresses correction',
        update_order: 'Order update',
        order_step: 'Order step',
        generate_invoice: 'Generate invoice',
        inprogress: 'In progress',
        new_address: 'New delivery address',

        address_type: 'Address type',
        back: 'Back',
        bad_zip_format: 'Bad postal code format',
        bad_phone_format: 'Bad phone number format',
        bad_time_format: 'Bad time format',
        bad_hour_format: 'Bad hour format',
        bad_date_format: 'Bad date format',
        select_client_info: 'Please select a client',
        select_driver_info: 'Please select a driver',
        select_contact_info: 'Please select a contact',
        select_vehicle_info: 'Please select a vehicle',
        required_field: 'Value is required',
        weekly_planning: 'Weekly planning',
        reference_postal_address: 'Reference postal address',
        no_ref_address: 'No reference postal address found',
        incomplete_order: 'Incomplete order',
        add_contact: 'Add contact',
        new_contact: 'New contact',
        update_contact: 'Update contact',
        order_delete_ok: 'Order successfuly deleted',
        order_delete_ko: 'Order deletion failed',
        delivery_delete_ok: 'Delivery successfuly deleted',
        delivery_delete_ko: 'Delivery deletion failed',
        vehicle_type: 'Vehicle type',
        vehicleType: 'Vehicle type',
        price_charged: 'Charged price (€)',
        add_steps: 'Add steps',
        add_step: 'Add step',

        contact: 'Contact',
        packages: 'Packages',
        OrderPackages: 'Packages',
        package: 'Package',

        references: 'References',
        internal_comments: 'Internal comments',
        number: 'Number',
        package_type: 'Package type',
        width: 'Width (cm)',
        length: 'Length (cm)',
        height: 'Height (cm)',
        package_add_not_allowed: 'Add a step before adding a package',
        between: 'Between',
        from: 'From',
        before: 'Before',
        pick_up_label: 'Pick up',
        picup_label: 'Pick up',

        pick_up: 'Pick up on',

        delivery_label: 'Delivery',
        delivery: 'Delivery on',

        on: 'On',
        bad_time_range:
            'The value of the first time selected must be before the second one. ',
        can_select_address: 'Pick an existing address or fill in form',
        can_select_address_v1: 'Pick an address',
        order_create_ok: 'Order successfully created',
        order_create_ko: 'Order creation failed ',
        order_update_ok: 'Order successfuly updated',
        order_update_ko: 'Order update failed',
        delivery_create_ok: 'Delivery successfully created',
        delivery_create_ko: 'Delivery creation failed ',
        delivery_update_ok: 'Delivery successfuly updated',
        delivery_update_ko: 'Delivery update failed',
        and: 'and',
        form_errors: 'Form contains errors',
        check_last_package: 'The last package you entered is not valid',
        bad_packages: 'Some packages are not complete',
        package_reference_missing: 'Package reference is required.',
        package_type_missing: 'Pick a package type.',
        simulations: 'Simulations',
        steps_count: 'Orders count',
        tours_count: 'Tours count',
        distance: 'Distance (km)',
        distance_nokm: 'Distance',
        duration: 'Duration',
        not_affected: 'Unaffected step | Unaffected steps',
        report: 'Report',
        new_driver: 'New driver',
        update_driver: 'Update driver',
        driver_update_ok: 'driver successfuly updated',
        driver_update_ko: 'driver update failed',
        driver_create_ok: 'driver successfully created',
        driver_create_ko: 'driver creation failed ',
        driver_delete_ok: 'driver successfully deleted',
        driver_delete_ko: 'driver deletion failed ',
        tour_delete_ok: 'Tour successfully deleted',
        tour_delete_ko: 'Tour deletion failed ',
        localisation: 'Localisation',
        address_name: 'Address name',
        phone_pro: 'Professional phone',
        phone_perso: 'Personnal phone',
        emergency_contact: 'Emergency contact',
        phone_secondary: 'Secondary phone',
        area: 'Area',
        vehicle: 'Vehicle',
        mobile_account: 'Mobile app account',
        select_account: 'Pick a user ',
        select_vehicle: 'Pick a vehicle',
        select_area: 'Pick an area',
        supplier: 'Supplier',
        select_supplier: 'Pick a supplier',
        subcontractor: 'Subcontractor',
        start_end_pos: 'Start and end locations',
        last_known_pos: 'Last known location',
        start: 'Start',
        end: 'End',
        latitude: 'Latitude',
        longitude: 'Longitude',
        mobile: 'Mobile phone',
        contact_update_ok: 'Contact successfuly updated',
        contact_update_ko: 'Contact update failed',
        contact_create_ok: 'Contact successfuly created',
        contact_create_ko: 'Contact creation failed',
        contact_delete_ok: 'Contact successfuly deleted',
        contact_delete_ko: 'Contact deletion failed',
        no_contact_found_for_client: 'No contact found for this client',
        new_simulation: 'New simulation',
        params: 'Parameters',
        requirements: 'Requirements',
        generate: 'Generate',
        tour_simulation: 'Tour simulation',
        date: 'Date',
        simulation_name: 'Simulation name',
        drivers_requirement: "Take driver's schedule into account",
        vehicles_requirement: 'Take vehicles into account',
        areas_requirement: 'Take areas into account',
        co2_requirement: 'Take CO2 consumption into account',
        price_requirement: 'Take delivery price into account',
        simulation_delete_ok: 'Simulation successfuly deleted',
        simulation_delete_ko: 'Simulation deletion failed',
        vehicle_types: 'Vehicle types',
        registration: 'Registration / serial number',
        brand: 'Brand',
        model: 'Model',
        update_vehicle: 'Vehicle update',
        update_vehicle_type: 'Vehicle type update',
        powered: 'Powered by',

        new_vehicle: 'New vehicle',
        new_vehicle_type: 'New vehicle type',

        vehicle_information: 'Vehicle informations',
        select_type: 'Pick a vehicle type',
        vehicle_delete_ok: 'Vehicle successfuly deleted',
        vehicle_delete_ko: 'Vehicle deletion failed',
        vehicle_update_ok: 'Vehicle successfuly updated',
        vehicle_update_ko: 'Vehicle update failed',
        vehicle_create_ok: 'Vehicle successfuly created',
        vehicle_create_ko: 'Vehicle creation failed',
        associated_driver: 'Associated driver',
        select_driver: 'Pick a driver',
        vehicle_specifications: 'Vehicle specifications',
        package_specifications: 'Packages specifications',
        volume: 'Vehicle volume (m³)',
        max_volume: 'Max. volume capacity (%)',
        volume_info:
            'In m³ (Set the value to 0 on Width - Length - Height to enter the volume manually)',
        in_percent: 'In %',
        max_load: 'Maximum load (kg)',
        package_max_height: 'Package maximum height (cm)',
        package_max_length: 'Package max. length (cm)',
        package_max_width: 'Package max. width (cm)',

        add_maintenance: 'Add maintenance',
        update_maintenance: 'Update maintenance',

        maintenance: 'maintenance | Maintenances',
        start_date: 'Start date',
        end_date: 'End date',
        informations: 'Informations',
        title: 'Title',
        maintenance_create_ok: 'Maintenance successfuly added',
        maintenance_create_ko: 'Maintenance creation failed',
        maintenance_update_ok: 'Maintenance successfuly updated',
        maintenance_update_ko: 'Maintenance update failed',
        maintenance_delete_ok: 'Maintenance successfuly deleted',
        maintenance_delete_ko: 'Maintenance deletion failed',
        active: 'Active',
        deactivated: 'Not active',
        deactivate: 'Deactivate',
        activate: 'Activate',

        deactivate_selection: 'Deactivate selected elements',
        activate_selection: 'Activate selected elements',

        driver_deactivate_ok: 'Driver successfuly deactivated',
        driver_deactivate_ko: 'Driver deactivation failed',
        driver_activate_ok: 'Driver successfuly activated',
        driver_activate_ko: 'Driver activation failed',
        container_deactivate_ok: 'Package format successfuly deactivated',
        container_deactivate_ko: 'Package format deactivation failed',
        container_activate_ok: 'Package format successfuly activated',
        container_activate_ko: 'Package format activation failed',
        vehicle_deactivate_ok: 'Vehicle successfuly deactivated',
        vehicle_deactivate_ko: 'Vehicle deactivation failed',
        vehicle_activate_ok: 'Vehicle successfuly activated',
        vehicle_activate_ko: 'Vehicle activation failed',
        refresh: 'Refresh',
        planning: 'Planning',
        driver_planning: "Driver's planning -",
        planning_create_ok: 'Planning created',
        planning_create_ko: 'Planning creation failed',
        simulation_generation_ok: 'Simulation generation launched',
        simulation_generation_ko: 'Simulation generation was not launched',
        morning: 'Morning',
        afternoon: 'Afternoon',
        add: 'Add',
        planning_update_ok: "Driver's planning successfully updated",
        planning_update_ko: "Driver's planning update failed",
        tours_date: 'Tours',
        grouping: 'Grouping of deliveries',
        ref_address_update_ok: 'Reference address successfully updated',
        ref_address_update_ko: 'Reference address update failed',
        fix_lat_lng_info_1:
            'Move the "?" marker on the map  or refill the address 1 field to correct the GPS coordinates of the order step.',
        fix_lat_lng_info_2:
            'Additional address information can be entered in the address 2 field.',
        step_needs_correction: 'Click to update this step',
        relaunch: 'Relaunch',
        relaunch_selection: 'Relaunch generation for selection',
        simulation_merge_ok: 'Simulations merge successfuly launched',
        simulation_merge_ko: 'Simulations merge failed',
        file: 'File',
        lines_to_import: 'Lines to import',
        imported_lines: 'Imported lines',
        order_file_title: 'Orders file -',
        download: 'Download',
        new_area: 'New area',
        update_area: 'Update area',
        area_type: 'Area type',
        area_by_bike: 'Bicycle delivery zone',
        area_error: 'Please modify the area to save.',
        area_create_ok: 'Area successfully created',
        area_create_ko: 'Area creation failed',
        area_update_ok: 'Area successfully updated',
        area_update_ko: 'Area update failed',
        area_deactivate_ok: 'Area successfuly deactivated',
        area_deactivate_ko: 'Area deactivation failed',
        area_activate_ok: 'Area successfuly activated',
        area_activate_ko: 'Area activation failed',
        activate_area: 'Active area',
        activate_vehicle_type: 'Active vehicle',

        activate_driver: 'Active driver',
        deactivated_area: 'Deactivated area',
        new_supplier: 'New supplier',
        update_supplier: 'Supplier update',
        supplier_create_ok: 'Supplier successfully created',
        supplier_create_ko: 'Supplier creation failed',
        supplier_update_ok: 'Supplier successfully updated',
        supplier_update_ko: 'Supplier update failed',
        billing: 'Invoicing',
        postal_address: 'Postal address',
        software_type: "Orders' software type",
        api_url: 'API URL',
        login_only: 'Login',
        pw_reset: 'password reset',
        prov_of_service: 'Provision of services : Hourly rate',
        eur_per_h: '€ / h',
        eur_per_km: '€ / km',
        eur_per_step: '€ / delivery point',
        pricing_per_km: 'Pricing per kilometer',
        pricing_per_tour: 'Tour: Cost of the delivery point',
        user_update_ok: 'Personnal informations successfully updated',
        user_update_ko: 'Personnal informations update failed',
        pricing: 'Pricing',
        tracking: 'Track package',
        tracking_all: 'Track packages',

        print_label: 'Print package label',
        print_all_labels: 'Print all labels',

        account_informations: 'User credentials',
        personnal_infos: 'Postal informations',
        sign_up: 'Create account',
        email: 'Email address',
        welcome: 'Welcome',
        user_create_ko: 'Account creation failed',
        user_create_ok: 'Account successfully created',
        email_used_for_login: 'The email you will use to login to your account',

        next: 'Next',
        // stop_tour: 'Stop tour',
        my_addresses: 'My addreses',
        my_orders: 'My deliveries',
        my_invoices: 'My invoices',
        shepherd: {
            final_button: 'Finish',
            no_thanks: 'No thanks',
            ok_go: 'Ok give me a tour !',
            restart_tour: 'Relaunch tour',
            stop_tour: 'Stop tour',
            create_account: {
                start: {
                    title: 'Welcome',
                    text: 'Here is a tutorial to help you create an account. This account will allow you to create new orders and track their delivery in real time.',
                },
                step_1: {
                    title: 'Dark mode',
                    text: 'This button allows you to switch to dark mode. This mode displays your home page, toolbar, settings and some other pages in dark tones.',
                },
                step_2: {
                    title: 'Switch language',
                    text: 'This button allows you to change the language in which the site will be displayed.',
                },
                step_3: {
                    title: 'Relaunch guide',
                    text: 'This button allows you to restart the tutorial for the current page.',
                },
                step_4: {
                    title: 'Login',
                    text: 'This Email field will be used as your login when you connect to the application. It is this email that will be used to receive notifications on the progress of your deliveries.',
                },
                step_5: {
                    title: 'Password check',
                    text: 'This button makes the current password visible.',
                },
                step_6: {
                    title: 'Password strength',
                    text: 'This gauge tells you the strength of the password you want to use. You will need at least 8 characters, 1 Capital letter, 1 Number and 1 Special character (!,*,?,%, ...).',
                },
                step_7: {
                    title: 'Company name',
                    text: 'Enter your company name here. If you leave this field blank, it will automatically be filled with your first and last names.',
                },
                step_8: {
                    title: 'Invoicing address',
                    text: 'Enter your invoicing address here. Start typing a few characters, and the engine will give you address suggestions. You can also ignore these suggestions and type in your address manually.',
                },
                step_9: {
                    title: 'Cancel account creation',
                    text: 'This button will take you back to the login screen.',
                },
                step_10: {
                    title: 'Create my account',
                    text: 'This button allows you to validate your account creation request. Once your account is created you will receive an email to validate your email address.',
                },
            },
        },
        bill_number: 'Invoice number',
        end_month: 'End of month invoice',
        bad_credentials: 'Wrong login or password',
        user_already_exists: 'A user already exists with these credentials',
        weekly_planning_management: 'Weekly planning management',
        today: 'Today',
        new_order_B2C: 'Plan my delivery',
        update_order_B2C: 'Update my delivery',
        duplicate: 'Duplicate',
        add_package: 'Add package',
        update_package: 'Update package',

        package_info: 'Package informations',
        price: 'My price',
        lxwxh: '(L x W x H)',
        value: 'Value',
        container_type: 'Container type (optionnal)',
        no_temp_specified: 'No temperature specified',
        frozen: 'Lower than -18°',
        hot: 'Higher than 30°',
        cool: 'Between 0° and 6°',
        temperature: 'Temperature',
        no_address_selected: 'No delivery address selected yet',
        no_package_selected: 'No package format selected yet',

        pallet: 'Pallet | Pallets',
        half_pallet: 'Half pallet | Half pallets',
        flat_wine_box: 'Flat wine box (6 btls) | Flat wine boxes (6 btls)',
        wine_box_down: 'Side wine box (6 btls) | Side wine boxes (6 btls)',
        wine_box_up: 'Up wine box (6 btls) | Up wine boxes (6 btls)',
        standard_cheese_box: 'Standard cheese box | Standard cheese boxes',
        select_package_format: 'Select a package format',
        gros_colis: 'Big Package',
        quantity: 'Quantity',
        no_package_type_error: 'Please select a package format.',
        update_and_copy: 'Update and create identical package',
        save_and_copy: 'Save and create identical package',
        update_and_copy_orderfile:
            'Update and create identical order file type',
        save_and_copy_orderfile: 'Save and create identical order file type',
        create_address: 'Create new delivery address',
        create_container: 'Create package format',
        create_my_address: 'Delivery address creation',
        create_my_pickup: 'Pickup address creation',
        create_my_container: 'Create custom package format',
        back_to_predefined_addresses: 'Go back to preexisting addresses',
        back_to_predefined_containers: 'Go back to preexisting package formats',
        package_delete_ok: 'Package successfuly deleted',
        package_delete_ko: 'Package deletion failed',
        package_name: 'Package format name',
        description: 'Description',
        value_required: 'This field cannot be blank',
        containers: 'Package formats',
        my_containers: 'My package formats',
        package_format_delete_ok: 'Package format successfuly deleted',
        package_format_delete_ko: 'Package format deletion failed',
        size: 'Size (L x W x H)',
        new_container: 'New package format',
        update_container: 'Update package format',
        container_update_ok: 'Package format successfuly updated',
        container_update_ko: 'Package format update failed',
        container_create_ok: 'Package format successfully created',
        container_create_ko: 'Package format creation failed',
        container_not_editable: 'This package format is not editable',
        send_rp_link: 'Send reset password link',
        operator: 'Operator',
        default_header: 'Title',
        contact_support: 'Please contact support for more informations.',
        package_format_not_found: 'Package format cannot be found',
        no_driver_selected: 'No driver selected',
        no_vehicle_type_selected: 'No vehicle type selected',
        update: 'Update',
        create: 'Create',
        type: 'Type',
        consumption: 'Consumption',
        annual_cost: 'Annual cost',
        legs: 'Legs',
        gas: 'Gas',
        gasoline: 'Gasoline',
        diesel: 'Diesel',
        electric: 'Electricity',
        hybrid_gasoline: 'Hybrid gasoline',
        hybrid_diesel: 'Hybrid diesel',
        plugin_hybrid_gasoline: 'Plugin hybrid gasoline',
        plugin_hybrid_diesel: 'Plugin hybrid diesel',
        bike: 'Bike',
        cargo_bike: 'Cargo bike',
        cargoBike: 'Cargo bike',
        electric_bike: 'Electric bike',
        electric_car: 'Electric car',
        deliveryTricycle: 'Delivery Tricycle',
        delivery_tricycle: 'Delivery Tricycle',
        car: 'Car',
        van: 'Van',
        boat: 'Boat',
        truck: 'Truck',
        not_specified: 'Not specified',
        delivery_name: 'My delivery name',
        delivery_title: 'Delivery',
        delivery_name_info:
            'Give a name to your delivery to find it more easily!',
        delivery_name_info_operator:
            'Give a name to your delivery for your client to find it more easily!',
        affected: 'Step affected to a tour',
        new: 'Step not affected to a tour yet',
        executed: 'Finished',
        affectationError: 'Affectation error',
        no_status_found: 'Undefined status for this step',
        steps_errors: 'Some steps are incomplete',
        finished_steps: 'Finished steps',
        delivery_start_end: 'Pickup & Delivery',
        break: 'Break',
        hydrogen: 'Hydrogen',
        client_ref: 'Client reference',
        last_step_finished: 'Package delivered',
        delivery_finished: 'Delivered',
        delivery_new: 'Waiting to be taken in charge',

        edit_activity: 'Edit activity',
        new_activity: 'Create activity',

        start_time: 'Start time',
        end_time: 'End time',
        pick_color: 'Pick a color',
        short_from: 'From',
        short_before: 'To',
        operator_ref: 'Delivery reference',
        copy_to_clipboard: 'Copy to clipboard',
        copied_to_clipboard: 'Copied to clipboard',
        activity_create_ko: 'Error while creating activity',
        atwork: 'Work ',
        off_paid: 'Off in contract (calculated in worked hours)',
        off_not_paid: 'Off out of contract (not calculated in worked hours)',

        activity_type: 'Activity type',
        show_disabled_activities: 'Show disabled activities',
        previous: 'Previous',
        update_user: 'Update user',
        new_user: 'Create user',
        create_and_send_mail: 'Create and send password setting link to user',
        user_type: 'User type',
        own_account_btn: 'I will be using this account',
        for_other_account_btn: 'This account is for someone else',
        create_matching_driver: 'Create matching driver',
        create_matching_client: 'Create matching client',

        do_not_create_matching_driver: 'Do not create matching driver',
        do_not_create_matching_client: 'Do not create matching client',
        create_user_account: 'Create mobile app and website account',
        or: 'Or',
        create_user_rp_info:
            "An email will be send to this email address to set account's password",
        contract_hour: 'Contract hour',
        week: 'Week | Weeks',
        PDF: 'PDF file',
        map: 'Map',
        packages_status: 'Package status| Packages statuses',
        orders_settings: 'Deliveries settings',
        notifications_settings: 'Notifications settings',
        pricings_settings: 'Pricing settings',
        status_subtext_pt_1:
            'You can here set the packages statuses you need and that will be displayed to your clients.',
        status_subtext_pt_2:
            'For example, you can create a specific status for when your client creates a delivery and you have not collected the package yet : "Not picked up" or "Awaiting pick up".',
        status_subtext_pt_3:
            'You can also create specific refused delivery statuses : "Refused torn package", "Refused cold chain integrity compromised".',

        new_package_status: 'New package status',
        new_authorisation: 'New authorisation',
        new_role: 'New role',

        status_name: 'Status name',
        status_name_info:
            'Text that will be displayed to your client and your lists',
        deleted_ok: 'Successfull deletion',
        deleted_ko: 'Deletion failed',
        create_ok: 'Successfull creation',
        create_ko: 'Creation failed',
        update_ok: 'Successfull update',
        update_ko: 'Update failed',
        update_package_status: 'Update package status',
        code_already_exists: 'The code you picked is already used',
        status_code: 'Status code',
        auth_code: 'Authorisation code',
        role_code: 'Role code',

        code: 'Code',
        error_occured: 'An error occured.',
        overlap_modal_header: 'Overlapping activities',
        overlap_modal_button: 'Continue adding activity',
        overlap_activity:
            'The activity you want to add has schedules that overlap with an existing activity.',
        overlap_activity2: 'Do you want to add it anyway ?',
        action_canceled: 'Action canceled by user',
        status_code_not_editable: 'Status code is not editable',
        auth_code_not_editable: 'Authorisation code is not editable',
        role_code_not_editable: 'Role code is not editable',

        pin_drag_info: 'Click on upper panel driver to reassign this step.',
        upload_file: 'Add file | Add files',
        file_type: 'File type',
        wrong_file_type_csv:
            ' is not a csv file, therefore cannot be uploaded.',
        wrong_file_type_pdf:
            ' is not a pdf file, therefore cannot be uploaded.',
        wrong_file_type_xls:
            ' is not an excel file, therefore cannot be uploaded.',
        error: 'Error',
        active_file_drop_zone_1: 'Drop them here',
        active_file_drop_zone_2: ' to add them',
        inactive_file_drop_zone_1:
            'Drag your files here or click the drop zone to select them.',
        new_file_type: 'New orders file type',
        file_type_name: 'File type name',
        create_new_file_type: 'New file type',
        update_file_type: 'Update file type',
        integrate_simulation: 'Integrate simulation',
        add_tour: 'Add tour',
        sim_integration_ok: 'Simulation successfully integrated to tour',
        sim_integration_ko:
            'An error occured during simulation integration to tour',
        assign_driver: 'Assign driver',
        assign_other_driver: 'Assign another driver',
        assign_vehicle: 'Assign vehicle',
        change_start_time: 'Change start time',
        time_determined_by_software:
            'Time schedule determined by the software.',
        time_determined_by_driver: 'Time schedule determined by the driver.',
        time_determined_custom:
            'Custom time schedule (select time here below).',
        end_with: 'End tour with this step',
        start_with: 'Start tour with this step',
        B2B: 'B2B',
        B2C: 'B2C',
        required_fields: 'Fields with * are required.',
        map_loading: 'Loading map',
        save_and_relaunch: 'Save and recalculate',
        recalculation_in_progress: 'Tours optimisation in progress',
        daily_view: "Live - Today's tours",
        date_error_before:
            "Invalid date : chosen date is before previous step's date or in the past. | Invalid date : chosen date is before pick up date. ",
        date_error_after:
            "Invalid date : chosen date is after next step's date. | Invalid date : chosen date is after delivery date.",
        past_date: 'Invalid date: chosen date is in the past or malformed.',
        my_package_format: 'This package format belongs to me',
        mine_only: 'Mine only',
        global_only: 'Globals only',

        global_pack_format: 'Global package format',
        belongs_to_other_user: 'This package format belongs to another user',
        hour: 'hour | hours',
        day: 'Day | Days',
        minute: 'minute | minutes',
        daily: 'Daily live',
        pickup: 'Pick up',
        old_map: 'Old Map',
        package_format: 'package formats',
        error_while_loading: 'Error while loading ',
        show_today_only: 'Today',
        delete_many_ok: 'Elements successfully deleted',
        delete_many_ko: 'An error occured while deleting elements',
        no_available_for_date: 'No result for chosen date',
        undefined_address: 'Undefined address',
        trackings: 'Packages trackings',
        trackings_documents: 'Documents',
        details: 'Detail',
        tracking_step: 'Tracking step',
        delivery_code: 'Delivery code',
        package_reference: 'Package reference',
        completion_date: 'Completion date',
        transit: 'Transit',
        // pickup: 'Pick up',
        redelivery: 'Redelivery',
        collect: 'Collect',
        pictures: 'Pictures',
        signatures: 'Signatures',
        // delivery: 'Delivery',
        actions: 'Actions',
        view_file: 'View file',
        no_tracking_data_available: 'No tracking data available yet',
        trackings_and_proofs: 'Package tracking & documents',
        external_ref: 'External reference',
        conteneurRef: 'Container ref',
        unaffect: 'Remove from tour',
        steps_coordinates_errors_1: 'Some steps are not',
        steps_coordinates_errors_2: ' properly geolocated.',
        step_coordinates_error: 'Not properly geolocated',
        solo_step: 'Orphan step',
        check_order: 'Please check order',
        incomplete_step_not_affectable:
            'This step cannot be affected to a tour',
        hide_show_google_pois: 'Hide / show Google points of interest',
        create_container_for_all: 'Create package format for all clients',
        all_clients: 'Global package format for all clients',
        delete_tour_header: 'Tour deletion',
        confirm: 'Confirm',
        delete_tour_text: 'Are you sure you want to delete this tour ? ',
        unaffect_step_header: 'Unasign step',
        unaffect_step_text:
            'Are you sure you want to unasign this step from tour ? ',
        regular_activity: 'Regular',
        contract_hours: 'Contract hours',
        weekly_hours: 'Weekly total',
        hours_done: 'Effective hours',
        hours_supp: 'Overtime',
        hours_miss: 'Missing hours',
        merge: 'Merge',
        no_delivery_name: 'No delivery name',

        distance_last_step: 'Distance from previous step',
        duration_last_step: 'Duration from previous step',
        dur_n_dist_last_step: 'Distance & duration from previous step',

        bad_time_range_steps:
            'Chosen delivery time is incompatible with pick up chosen time',
        bad_time_range_steps_2:
            'Chosen pick up time is incompatible with delivery chosen time',
        ms_status: 'Status | Statuses',
        ms_option: 'Option | Options',
        force_finish: 'Switch step to "finished"',
        force_inprogress: 'Switch step to "in progress"',
        authorisations: 'Authorisations',
        translations: 'Translations',
        biiim_admin_interface: 'Biiim admin interface',
        update_authorisation: 'Update authorisation',
        update_role: 'Update role',
        logs: 'Logs',
        google_api_use: 'Google API use',
        Name: 'Name',
        Description: 'Description',
        ModificationDate: 'Modification date',
        Id: 'Id',
        HttpCode: 'HTTP code',
        EntityObject: 'Entity object',
        EntityName: 'Entity name',
        Function: 'Function',
        Response: 'Response',
        mail_templates: 'Email templates',
        roles: 'Roles',
        is_used_modal_header: 'Activity is used in a schedule',
        is_used_modal_button_update_all: 'Update activity and schedules',
        is_used_activity:
            'This activity is already used in one or more schedules.',
        is_used_activity2:
            'Would you like to update this activity and all existing activities in schedules,',
        is_used_activity3:
            'or create a new one that will not affect existing schedules ?',
        create_new_activity: 'Create new activity',
        is_used_container_modal_header: 'Package format is used in an order',
        is_used_container_modal_button_update_all:
            'Update package format and orders',
        is_used_container:
            'This package format is already used in one or more orders.',
        is_used_container2:
            'Would you like to update this package format and all existing package formats in orders,',
        is_used_container3:
            'or create a new one that will not affect existing orders ?',
        create_new_container: 'Create new package format',
        add_to_role: 'Add to role',
        search_by_name: 'By name',
        search_by_code: 'By code',

        search_role: 'Search roles',
        role_already_has_auth: 'This role already has this authorisation',
        action_not_allowed: 'Action not allowed',
        add_to_roles: 'Add to roles',
        add_roles: ' Add role to users | Add roles to users',
        admin: 'Admin',
        super_admin: 'SuperAdmin',

        add_role_to_users: 'Add role to users',

        selected_users: 'user selected | users selected',
        selected_roles: 'role selected | roles selected',

        roles_list: 'Users roles',
        remove: 'Remove',
        add_authorisations: 'Add authorisations to roles',
        already_selected_info:
            'Selection by user type will not remove already selected users',
        operators: 'Operators',
        admins: 'Administrators',
        visitors: 'Visitors',
        add_roles_to_user: 'Add roles to user',
        not_authorised:
            'You do not have the authorisation to access this resource.',
        opening_hours: 'Opening hours',
        delete_modal_generic_header: ' Deletion confirmation',
        delete_modal_generic_text:
            'Are you sure you want to delete this element ?',
        delete_modal_generic_text_2:
            'All depending elements will be updated (users, authorisations, roles).',
        delete_modal_generic_text_3:
            'All depending elements will be deleted (docks, defenses, inspections).',
        role: 'Role',
        user: 'User',
        authorisation: 'Authorisation',
        estimated: 'Estimated',
        real: 'Real',
        software_calculated_time: 'Software calculated end / delivery time',
        software_calculated_time_begin:
            'Software calculated start / pick up time',

        software_calculated_time_new:
            'Updated software calculated end / delivery time',
        software_calculated_time_new_begin:
            'Updated software calculated start / pick up time',

        real_deliv_time: 'Real end / delivery time',
        real_pickup_time: 'Real start / pick up time',

        picked_up: 'Package picked up',
        new_address_generic: 'New address',
        previous_step_incomplete: 'Previous step is incomplete',
        delivery_step_incomplete: 'Delivery step is incomplete',

        same_address: 'Pick up and delivery have the same address',
        delete_steps_pair_info:
            'The pick up / delivery pair will be removed from the order.',
        delete_all_unsaved_steps:
            'Are you sure you want to delete all unsaved steps ?',
        delete_all_unsaved_steps_info:
            'To remove only a specific pick up / delivery pair, click on the trash can on top of it.',
        update_and_copy_order: 'Update and create identical order',
        save_and_copy_order: 'Save and create identical order',
        copy_order: 'Create identical order',

        display_finished: 'Display finished tours',
        fetching_data: 'Fetching data',
        real_driver_tour: 'Driver real tour',
        software_calc_tour: 'Software optimised tour',
        delivered_order: 'Finished steps',
        no_driver_trace: "Driver's real route unavailable",
        billing_info: 'Invoicing information',
        billing_address: 'Invoicing address',
        billing_addr_same_as_client: 'Same as client address',
        billing_type: 'Invoicing type',
        previous_step_not_done: 'Previous steps are not finished.',
        open_in_new_tab: 'Open in a new tab',
        client_comments: 'Client comments',
        comments: 'Comments',
        round_trip: 'Round trip',
        recurrence: 'Order recurence',
        order_is_recurrent: 'This order will be recurrent',
        repeat_every: 'Repeat every',
        month: 'Month | Months',
        year: 'Year | Years',
        repeat_on: 'Repeats on',
        mon: 'M',
        tue: 'T',
        wed: 'W',
        thu: 'T',
        fri: 'F',
        sat: 'S',
        sun: 'S',
        ends: 'Ends',
        after: 'After',
        never: 'Never',
        occurrence: 'Occurrence | Occurrences',
        recurrent_orders: 'Recurrent orders',
        starts: 'Starts on',
        pack_num: 'Packages count',
        picked_days_error: 'You did not select week days for recurrence',
        update_recurrent: 'Update recurrent order',
        not_implemented: 'Not implemented yet',
        recurrent_deactivate_ok: 'Recurrent order successfuly deactivated',
        recurrent_deactivate_ko: 'Recurrent deactivation failed',
        recurrent_activate_ok: 'Recurrent order successfuly activated',
        recurrent_activate_ko: 'Recurrent activation failed',
        recurrence_end: 'Recurrence end',

        done: 'Done | Done',
        waiting_for_payment: 'Waiting for payment',
        recurrent: 'Recurrent order',
        my_cart: 'My cart',
        pay: 'Pay this delivery',
        pay_pack_only: 'Pay this package only',
        see_cart: 'See my cart',
        cannot_update_recurrent:
            'Please Contact your operator to update this package.',
        cannot_delete_recurrent:
            'Please Contact your operator to delete this package.',
        cannot_duplicate_recurrent:
            'Please Contact your operator to duplicate this package.',
        round_trip_label: 'Back trip',
        billing_contact: 'Invoice address contact',
        got_it: 'Got it !',
        occasional_client_order_not_displayed_until_payment:
            'Web payment orders will not be displayed in orders list until payment',
        address_update: 'Update this address',
        contact_update: 'Update this contact',
        back_to_predefined_contacts: 'Go back to preexisting contacts',
        email_same_as_client: 'Same email as client',
        web: 'Web Payment',
        unpaid_orders: 'Unpaid web orders',
        waitingforpayment: 'Waiting for payment',
        send_reminder_mail: 'Send a reminder e-mail',
        send_reminder_mail_with_link:
            'Send a reminder e-mail with payment link',
        set_as_paid: 'Set as paid',
        packages_info: 'Package | Packages',
        success_setting_as_paid:
            'Selected order successfully set as paid | Selected orders successfully set as paid',
        success_sending_reminder_mail:
            'A reminder e-mail was successfully sent to the client | Reminder e-mail were successfully sent to clients',
        error_setting_as_paid:
            'Order status was not set as "paid" | Some order statuses were not updated to "paid"',
        error_sending_reminder_mail:
            'Reminder e-mail was not sent | Some reminder e-mails were not sent',
        reminder_not_sent: 'Reminder not sent for following orders',
        user_not_found: 'User not found',
        order_not_found: 'Order not found',
        unknown_error: 'Unknown error, please contact  support team.',
        order_ref: 'Order reference',
        order_id: 'Biiim order ID',
        not_unique_email: 'Record is not unique in database',
        no_contact_for_order: 'No contact found for this order',
        order_set_as_paid: 'Order status successfully updated  as paid',
        order_not_set_as_paid:
            'Status was not updated as "paid" for following orders',
        order_not_waiting_for_payment:
            'Order has already been paid or set to paid status ',
        irreversible_action: 'This action is irreversible',
        confirm_set_as_paid:
            'Confirm setting following orders status to "paid"',
        action_confirm_generic_header: 'Confirm action',
        delete_modal_generic_text_plural:
            'Are you sure you want to delete these elements ?',
        default_billing_info: 'Set to client invoicing information by default.',
        web_payment_info:
            'If set to "Web Payment", orders will be displayed in the unpaid orders list until the payment is received.',
        default_billing_info2:
            'You can modify these parameters for this specific order.',
        database: 'Database',
        credit_card: 'Credit card',

        cash: 'Cash',
        times: 'times',
        ends_after: 'Ends after',
        show_planned_rec: 'Show / hide planned recurrent orders',
        planned: 'Planned',
        edit_date: 'Edit date',
        update_recurrent_date_modal_header: 'Update planned order date',
        update_recurrent_date_text:
            'Here you can change the date of the planned order. It will then automatically be generated on the new date you choose.',
        universe: 'Universe | Universes',
        course: 'Course',
        universes_list: 'Universes list',
        none: 'None',
        your_universes: 'Your universes',
        new_universe: 'New universe',
        update_universe: 'Update universe',
        universe_already_exists: 'A Universe with chosen name already exists',
        delete_modal_generic_text_universe:
            'All depending elements will be updated (users, clients, orders).',
        universes: 'Universes',
        add_universes: 'Add universe to users | Add universes to users',
        default_universe_info: 'Set to client universe information by default.',
        no_payment_available:
            'Online payment not available. Please contact your operator.',
        already_paid: 'This delivery has already been paid',
        payment_success: 'Your payment was successful !',
        payment_success_2: 'Your delivery is now waiting to be taken care of.',
        payment_failed: 'Something went wrong during payment process.',
        payment_failed_2: 'Please try again or contact your operator.',
        back_to_deliveries: 'Back to my deliveries',
        my_unpaid_deliveries: 'My unpaid deliveries',
        see_details: 'See details',
        refund: 'Ask for refund',
        refunds: 'Web payments & Refunds',
        refund_success: 'Refund successful ',
        refund_error: 'Error while sending refund request',
        validate_refund: 'Refund client',
        cancel_refund: 'Cancel refund',
        refund_detail: 'Refund detail',
        refund_request_modal_header: 'Refund request',
        close: 'Close',
        deliv_refund: 'Deliveries',
        ref_to_give: 'Payment reference to give to your operator',
        contact_support_cancel_refund:
            'Please contact support to cancel your delivery and ask for a refund',
        refund_info_text:
            'Some deliveries may not appear below. This means that you already have been refunded or that they are part of another payment session. If you also wish to be refunded for these deliveries, please request it from your operator.',
        paid: 'Paid',
        full_ref: 'Full refund',
        partial_refund: 'Partial refund',
        update_step: 'Step correction',
        refund_com: 'Refund comment',
        last_modified_by: 'Modified by ',
        refunded_amount: 'Refunded amount (€)',
        ocs: 'Orders',
        amount: 'Amount (€) ',
        payment_ref: 'Payment reference',
        provider: 'Payment provider',
        payment_result: 'My payment',
        refund_higher_than_amount:
            'Amount to refund cannot be higher than paid amount',
        refund_comment: 'Refund comment',
        amount_to_refund: 'Refund amount (€)',
        refund_modal_header: 'Web payment refund',
        refund_button: 'Validate refund',
        requested_by_customer: 'Requested by customer',
        fraudulent: 'Fraudulent',
        duplicate_payment: 'Payment duplication',
        refund_reason: 'Refund reason',
        refundable_amount: 'Refundable amount (€)',
        refund_must_select_oc: 'You must select at least one order to refund',
        refunded: 'Refunded',
        refund_driver_info_text:
            'Refunded orders will automatically be removed from tour.',
        full_refund: 'Full refund',
        order_update_failed_payment:
            'Payment was successful but something went wrong during order update. Please contact your operator.',
        order_update_failed_refund:
            'Refund was successful but something went wrong during order update. Please contact Biiim support.',
        provider_checkout_create_error:
            'There seems to be an error with the payment provider. Please contact your operator.',
        biiim_checkout_create_error:
            'An error occured during process. Please contact your operator.',
        payment_not_paid:
            'It seems that this order is not paid. Therefore it cannot be refunded. Please contact your operator for more informations.',
        payment_session_not_found:
            'Payment session does not exist. Please contact Biiim support for more information.',
        provider_payment_intent_not_found:
            'Payment session does not exist. Please check your payment provider records. ',
        forced_by_operator:
            'Payment forced by operator. Has not been paid via payment provider service, ',
        order_to_bill_ko:
            'WARNING , order status update did not work. Please contact Biiim support.',
        one_time_force_payment: 'Force payment this time only',
        set_client_to_month_invoice: 'Set client to monthly invoicing',
        paid_by_credit_card: 'Paid by credit card',
        paid_by_check: 'Paid by check',
        paid_by_cash: 'Paid by cash',
        paid_by_transfer: 'Paid by bank transfer',
        offerd: 'Offered',
        applied_to_all_orders:
            'All existing future unpaid orders will be updated for this client.',
        unique_id: 'Unique identifier',
        checkout_session_not_updated:
            'Checkout session for this order was not updated.',
        no_client_found_please_create_one:
            'No client found, please create one.',
        tour_view: 'Tour map',
        simulation_view: 'Simulation map',
        contact_biiim: 'Please contact Biiim support for more informations.',
        unable_to_integrate_simulation: 'Simulation integration error',
        sim_integration_error_text_1:
            'Simulation cannot be integrated for selected day.',
        sim_integration_error_text_2:
            'Some steps already are affected to a driver or a tour.',
        simulation_generation: 'Simulation generation',
        envelope: 'Envelope | Envelopes',
        default_package: 'Default package',
        reset_password_link_sent: 'Reset password link sent.',
        reset_password_link_not_sent:
            'An error occured while sending reset password email.',
        default_container_info: 'This is your default package format.',
        operator_update_default:
            'To update this information, please contact Biiim support.',
        set_as_default: 'Set as default package',
        set_as_default_for_client: 'Set as default package for client',
        default_container_ok_for_client:
            'Default package successfully updated for client',
        default_container_ko_for_client:
            'Default package update failed for client',
        default_container_ok: 'Your default package was successfully updated',
        default_container_ko: 'Your default package update failed',
        owned_by: 'Owned by',
        invoicings: 'Orders to invoice',
        amount_to_bill: 'Invoice amount (€) ',
        generate_bill_for_many: 'Generate unique invoice for selected orders',
        generate_bill_for_many_sales:
            'Generate unique invoice for selected sales',

        generate_bill: 'Generate invoice',
        invoice_generation_launched: 'Invoice generation launched',
        invoice_generation_failed: 'Invoice generation failed',
        invoice_generation_not_same_client:
            'Orders to invoice belong to different clients',
        invoice_generation_not_same_universe:
            'Orders to invoice belong to different universes',
        due_date: 'Due date',
        date_invoice: 'Invoice date',
        distance_duration: 'Distance & duration',
        ht: 'Excl. VAT (€)',
        ttc: 'Incl. VAT (€)',
        no_data_available: 'No data available',
        unpaid_invoices: 'Unpaid invoices',
        set_invoice_as_paid: 'Set invoice as paid',
        set_many_as_paid: 'Set selected invoices as paid',
        payment_date: 'Paid on',
        send_reminder: 'Send reminder email to client',
        send_many_reminders: 'Send reminder email to selected clients',
        success_sending_invoice:
            'Invoice successfully sent to client | Invoices successfully sent to clients',
        error_sending_invoice:
            'Invoice could not be sent to client | Some invoices could not be sent to clients',
        select_payment_date: 'Select a payment date',
        validate: 'Validate',
        error_invoice_set_as_paid: 'Invoice could not be set as paid',
        success_invoice_set_as_paid: 'Invoice successfully set as paid',
        send_invoice_by_mail: 'Send invoice to client by email',
        creditcard: 'Credit card',
        send_many_invoices: 'Send invoices to selected clients',
        send_many_invoices_with_link:
            'Send invoices to selected clients with payment link',
        client_delivery_addresses: 'Client delivery addresses',
        also_update_user_login: 'Update user login',
        also_update_client_email: 'Update client email',
        web_driver_assign_info:
            'Web payment info : You will be able to assign a driver or/and a vehicule once the order is paid.',
        login_biiim_info: 'Let us guide you !',
        session_timed_out: 'You have been logged out. Please log in again',
        steps_to_correct: 'Address to correct | Addresses to correct',
        waiting_for_web_payment:
            'Order waiting for web payment | Orders waiting for web payment',
        unaffected_orders:
            'Step not affected to a  tour | Steps not affected to a tour',
        todays_total_tours: 'Tour today | Tours today',
        todays_total_orders: 'Order today | Orders today',
        waiting_orders: 'Order waiting | Orders waiting ',
        in_progress_orders: 'Order in progress | Orders in progress',
        finished_orders: 'Delivered order | Delivered orders',
        not_invoiced: 'Not invoiced order | Not invoiced orders',
        invoiced_not_paid: 'Not paid invoice| Not paid invoices',
        your_invoices: 'Your invoices',
        vehicle_maintenances_plus_30:
            'Vehicle to be serviced in the next 30 days |  Vehicles to be serviced in the next 30 days',
        imports_errors:
            'File import error in the past fortnight | File import errors in the past fortnight',
        imports_errors_subtext:
            '(To remove this notification, delete the file and reimport it) | (To remove this notification, delete the files and reimport them)',
        simulations_errors:
            'Simulation generation error in the past fortnight | Simulation generation errors in the past fortnight',
        simulations_errors_subtext:
            '(To remove this notification, relaunch the simulation) | (To remove this notification, relaunch the simulations)',
        wrong_payment_type_for_import: 'Client update needed',
        wrong_payment_type_for_import_1:
            'The client you chose does not have the correct payment type set for file import.',
        wrong_payment_type_for_import_2:
            'Please select the new payment type to apply to the client and click the "update" button, or click "cancel".',
        inactive_driver: 'Selected driver is inactive, please select another',
        clocked_in: 'Clocked in',
        clocked_out: 'Clocked out',
        not_clocked_in: 'Not clocked in',
        same_order_steps: 'Other order steps',
        generate_invoice_as_paid: 'Generate invoice as paid',
        invoice_not_generated: 'Invoice not generated',
        for_login: 'login',
        add_different_package_type: 'Add different package',
        invoiced: 'Order already invoiced',
        invoice_available: 'Invoice available',
        all_orders: 'All orders',
        imported_only: 'Imported only',
        not_imported_only: 'Not imported only',
        order_status: 'Orders Status',
        imported: 'Imported',
        imported_from_file:
            'Order imported from a file | Orders imported from a file',
        remove_package: 'Remove package',
        one_package_tracking: 'Package tracking',
        invoice_generation_no_contact_client:
            'No billing user found for this client',
        invoice_generation_no_billing_address:
            'No billing address found for this client',
        already_invoiced: 'Order already invoiced',
        tour_tours: 'Tour | Tours',
        not_editable: 'Not  editable',
        contact_client_history: 'Customer communication history',
        prospect: 'Sales lead',
        sales_person: 'Sales person',
        meeting_date: 'Meeting date',
        pinned: 'Pinned',
        no_notes_yet: 'No notes yet',
        new_note: 'New note',
        your_notes: 'Your notes',
        note: 'Note | Notes',
        no_pinned_notes_yet: 'No pinned notes yet',
        reunion: 'Reunion',
        other: 'Other action',
        email_note: 'Email',
        set_reminder: 'Reminder',
        select_salesperson_info: 'Sales person',
        write_note:
            'Click here to start writing, paste your Word file content or import a Word file',
        title_info: 'Will help you find your note more easily',
        attachement: 'Attachment | Attachments',
        note_type: 'Note type',
        client_not_found: 'Client not found',
        update_note: 'Update note',
        can_add_note: 'You can now add a note',

        save_client_first: 'Please save client before adding a note',
        save_client_before_contract:
            'Please save client before adding a contract',
        save_as_pdf: 'Export as PDF',
        save_as_word: 'Export as Word (.doc)',
        import_from_doc: 'Import from Word (.doc, .docx)',
        new_reminder: 'New reminder',
        reminder_date: 'Reminder date',
        users_to_notify: 'Users to notify',
        bad_emails_format: 'Bad email address format',
        error_occured_setting_reminder:
            'An error occured while setting reminder',
        past_event: 'Past event',
        save_and_send: 'Save and also send reminder to concerned users now',
        update_price: 'Update price',
        error_updating_price: 'Error while updating price',
        demo_price: 'Price',
        demo_price_info: '€ VAT included',
        archived: 'Archived',
        unarchive: 'Unarchive',
        archived_status: 'Status',
        ongoing: 'Ongoing',
        archive: 'Archive',
        unarchive_selection: 'Unarchive selection',
        archive_selection: 'Archive selection',
        late_vehicle_maintenance:
            'Vehicle with overdue servicing |  Vehicles with overdue servicing',
        set_as_done: 'Set as done',
        todo: 'To do',
        late: 'Late',
        done_date: 'Done on',
        urgent: 'Urgent',
        not_urgent: 'Not urgent',
        maintenances_list: 'Maintenances',
        only_late_plus_todo_30:
            'Show only late and to do + 30 days maintenances',
        arch_tc: 'Archived | Archived',
        see_det: 'See Details',
        contracts: 'Contracts',
        client_name: 'Client',
        signature_date: 'Signature date',
        contract_type: 'Contract type',
        notice: 'Notice period',
        auto_renew: 'Renewal',
        alert_before_end: 'Reminder before end',
        rental: 'Rental',
        work_fixed_term: 'Fixed-term work',
        work_open_ended: 'Open-ended work',
        add_contract: 'Add contract',
        update_contract: 'Update contract',
        yes_with_mail: 'Yes, with email to client',
        not_applicable: 'Not applicable',
        no_client: 'Client not found',
        contract_duration: 'Duration (month)',
        contract_create_ok: 'Contract successfully created',
        contract_update_ok: 'Contract successfully updated',
        contract_create_ko: 'Contract creation failed',
        contract_update_ko: 'Contract update failed',
        yesWithEmail: 'Yes, with email to client',
        see_contracts: 'Display contracts',
        hide_contracts: 'Hide contracts',
        open_ended: 'Open-ended',
        openEnded: 'Open-ended',
        no: 'No',

        yes: 'Yes',
        order_complement: 'Complement',
        sale_name: 'Designation',
        vat: 'VAT',
        no_complement_yet: 'No complementary sale',
        can_add_contract: 'You can now add a contract',
        order_group: 'Delivery grouping',
        add_tour_to_order: 'Add a group - Enter group name',
        orderGroup: 'Delivery grouping',
        orderGrouping: 'Delivery grouping',
        order_group_modal_header: 'Delivery group update',
        order_group_modal_info:
            'Updates made here will impact all deliveries in the delivery group',
        order_pricing_scenario: 'Scenario for delivery pricing',
        tour_pricing_scenario: 'Scenario for delivery group pricing',
        api_doc: 'API Documentation',
        forced_price: 'Agreed price',
        copy_jwt: 'Copy Auth Token',
        comment_not_visible_by_client: 'Comment not visible by client',
        comment_visible_by_client_info: 'Your client can see this comment',
        imported_pdf: 'Imported from PDF',
        no_driver: 'No driver',
        delivery_files: 'Order documents',
        print_order_files: "Print orders' documents",
        see_file: 'View file (opens in new tab)',
        tour_opti_error_steps_not_assigned:
            'Some steps are not assigned, optimisation not possible with chosen constraints',
        tour_opti_error_multiple_tours:
            'Multiple tours have been produced, optimisation not possible with chosen constraints',
        total_ttc: 'incl. VAT',
        total_ht: 'excl. VAT',
        action_required: 'Action required',
        opti_error_choice: 'Do you want to force this modification ?',
        opti_error_info: 'Modification may impact several tours.',
        one_up: 'Move up',
        one_down: 'Move down',
        tour_opti_error_deliv_before_pickup:
            'Delivery step cannot be before pick up step',
        create_step_break: 'Create a break',
        break_step_label: 'Break step',
        pick_up_between: 'Between',
        delivery_and: 'And',
        articles: 'Articles',
        update_article: 'Article update',
        create_article: 'Create article',
        coef: 'Coefficient',
        price_pack_ht: 'Flate Rate excl. VAT',
        price_percent: '% of order price',
        price_written: 'Displayed on invoice',
        pick_article: 'Select article',
        hazardous_products: 'Hazardous products',
        update_hazardous_product: 'Update hazardous product',
        create_hazardous_product: 'Create hazardous product',
        group_onu: 'Group',
        onu_class: 'Class',
        no_hazard_yet: 'No hazardous product yet',
        net_weight: 'Net weight',
        unit_ht: 'Unit price excl. VAT',
        vat_amount: 'VAT amount',
        see_sales: 'View extra sales',
        hide_sales: 'Hide extra sales',
        generate_transport_slip: 'Generate transport slip',
        print_transport_slip: 'Print transport slip',
        print_selected_transport_slips: 'Print selected transport slips',
        generate_selected_transport_slips:
            "Generate selected order's transport slips",
        slips_generation_launched: 'Slips generation launched',
        slips_generation_failed: 'Slips generation failed',
        tour_opti_error_forced:
            'Tour has previously been manually modified, a modification will trigger a new optimisation.',
        new_article: 'New article',
        my_credits: 'My credits',
        valid_until: 'Valid until',
        generate_proforma: 'Generate proforma',
        generate_proforma_for_many:
            'Generate unique proforma for selected orders',
        generate_proforma_for_many_sales:
            'Generate unique proforma for selected sales',
        with_vat: 'With VAT',
        no_credits: 'No credits',
        credits: 'Credits',
        global_address: 'Global address',
        sales: 'Sales',
        new_hazardous_product: 'New hazardous product',
        update_sale: 'Update sale',
        new_sale: 'New sale',
        purchase_price: 'Subcontractor purchase price',
        selling_price: 'Subcontractor selling price',

        seal_number: 'Seal number',
        external_reference: 'External reference',
        step_comment: 'Step comment',
        send_charter: 'Send order charter document',
        add_credits: 'Add credits',
        update_credits: 'Update credits',
        address_not_geolocated: 'Address not geolocated',
        credits_ok: 'Credits successfully added',
        credits_ko: 'Credits addition failed',
        no_credits_yet: 'No credits yet',
        assign_to_driver: 'Assign selected orders to driver',
        finish_many_steps: 'Finish selected steps',
        assign: 'Assign',
        import_config: 'Import config',
        processor_to_use: 'Document AI processor to use',
        client_keys: 'Client matching keywords',
        address_keys: 'Address matching keywords',
        keywords_placeholder:
            'Please separate each word or word group with a coma',
        select_address_info: 'Please select an address',
        processor_placeholder: 'Enter processors ids, separated by a coma',
        key_words: 'Keywords',
        followed_by: 'Monitored by',
        container_number: 'Container number',
        ste_name: 'Company name',
        default_contact: 'Default contact',
        ste: 'Company',
        new_address_for: 'New delivery address',
        vat_not_specified: 'VAT not specified',
        ref_address_extra_info_update: 'Update additional address information',
        onu_authorized: 'Authorized to transport hazardous products',
        client_has_no_processor:
            'Cannot import PDF file for this client. Please contact Cédric',
        address_data_missing: 'Some address information is missing',
        linked_steps: 'Show linked steps',
        relaunch_ok: 'Relaunch successful',
        upload_user_file: 'Add a file (excluding order forms)',
        files_uploaded: 'Files successfully uploaded.',
        upload_file_from_user_info:
            'You can add files that will be linked to the selected order. They are stored in our database for your convenience. They will not be processed.',
        order_fields: 'Order fields',
        file_fields:
            'Matching column number or field name (for pdf) in your file',
        send_reminder_with_payment_link: 'Send reminder with payment link',
        send_with_payment_link: 'Send by email with payment link',

        containers_on_hold: 'Containers on hold',
        elapsed: 'Elapsed time',
        deadline: 'Deadline',
        d: 'd',
        onHoldSince: 'On hold since',

        pdf: 'PDF',
        csv: 'CSV',
        xls: 'XLS*',
        extension: 'File extension',
        order_file_fields: {
            ExternalReference: 'External reference',
            DeliveryDate: 'Delivery date',
            PickupDate: 'Pick up date',

            AddressPickupName: 'Pick up address name',
            AddressPickup1: 'Pick up address',
            AddressPickup2: 'Address complement',
            AddressPickup3: 'Address complement',
            AddressPickup4: 'Address complement',
            AddressPickupPostalCode: 'Pick up postal code',
            AddressPickupCity: 'Pick up city',
            AddressDeliveryName: 'Delivery address name',
            AddressDelivery1: 'Delivery address',
            AddressDelivery2: 'Address complement',
            AddressDelivery3: 'Address complement',
            AddressDelivery4: 'Address complement',
            AddressDeliveryPostalCode: 'Delivery postal code',
            AddressDeliveryCity: 'Delivery city',
        },
        required_field_missing: 'Required field missing',
        duplicate_field_value: 'Duplicate field value',
        force_with_position: 'Force to chosen position',
        position: 'Position',
        force_with_position_info:
            'You can force recalculation without indicating where to position the selected steps by clicking directly on "confirm".',
        force_with_position_info_2:
            'The tour will then be reoptimized and you will lose your previous modifications.',
        force_with_position_info_3:
            'Or you can choose a position to add the steps in the tour by filling in the field below.',
        save_n_visualise: 'Save and visualise order charter (opens new window)',
        error_occured_while_generating_charter:
            'Error while generating order charter',
        relaunch_import: 'Relaunch import',
        cancel_import: 'Cancel import (delete orders)',
        purchase_price_required: 'Purchase price is required',
        driver_required: 'Driver is required',
        cancel_imported: 'Cancel import (delete unassigned orders)',
        delete_file: 'Delete file (orders will not be deleted)',
        cancel_ok: 'Orders cancellation successful',
        cancel_ko: 'Orders cancellation failed',
        client_credit: 'Client credit',
        clientCredit: 'Client credit',
        email_status: {
            sent: { short: 'Sent', info: 'Sent', chart: 'Sent' },
            requests: {
                short: 'Requested',
                chart: 'Requests',
                info: 'Email provider received request',
            },
            request: {
                short: 'Requested',
                chart: 'Requests',
                info: 'Email provider received request',
            },

            delivered: {
                short: 'Delivered',
                info: 'Delivered',
                chart: 'Delivered',
            },
            hard_bounce: {
                short: 'Error',

                info: 'Message permanently rejected by recipient mail server. Please check recipient email.',
            },
            hardBounces: {
                short: 'Error',
                chart: 'Hard bounces',
                info: 'Message permanently rejected by recipient mail server. Please check recipient email.',
            },
            soft_bounce: {
                short: 'Error',
                info: 'Message temporarily rejected by recipients mail server. Please check recipient email.',
            },
            softBounces: {
                short: 'Error',
                chart: 'Soft bounces',
                info: 'Message temporarily rejected by recipients mail server. Please check recipient email.',
            },
            click: {
                short: 'Clicked',
                info: 'Recipient interacted with the email',
            },
            opens: { chart: 'Opens' },
            uniqueOpens: { chart: 'Unique opens' },

            clicks: { chart: 'Clicks' },
            spamReports: { chart: 'Spam reports' },
            loadedByProxy: { chart: 'Opened by proxy' },
            uniqueClicks: { chart: 'Unique clicks' },
            open: {
                short: 'Opened',
                chart: 'Opened',
                info: 'Recipient opened the email',
            },
            opened: {
                short: 'Opened',
                chart: 'Opened',
                info: 'Recipient opened the email',
            },
            unique_opened: {
                short: 'First opening',
                chart: 'First opening',
                info: 'Recipient opened the email',
            },
            spam: {
                short: 'Spam',
                info: 'Email marked as spam by recipients email provider',
            },
            blocked: {
                short: 'Blocked',
                chart: 'Blocked',
                info: 'Email prevented to reach the recipients inbox due to blacklisted sender etc.',
            },
            invalid: {
                short: 'Invalid',
                chart: 'Invalid emails',

                info: 'Senders email is not invalid or does not exist',
            },
            unsubscribed: {
                short: 'Unsubscribed',
                chart: 'Unsubscribed',

                info: 'Recipient has unsubscribed from receiving further emails from the sender',
            },
            deferred: {
                short: 'Deferred',
                chart: 'Deferred',

                info: 'Email delivery has been delayed by the recipients mail server',
            },
            error: {
                short: ' Error',
                chart: 'Transfer errors',
                info: 'An error occurred during the mail transfer from sender to recipient',
            },
            proxy_open: {
                short: 'Opened',
                info: 'Proxy tells that the email has been opened',
            },
            unique_proxy_open: {
                short: 'Opened',
                info: 'Proxy tells that the email has been opened',
            },
            invalid_email: {
                short: 'Invalid',
                info: 'Senders email is invalid',
            },
        },
        aggregatedStats: 'Aggregated Stats',
        aggregated_tats: 'Aggregated Stats',

        events: 'Events',
        events_table: 'Events details',
        subject: 'Subject',
        event: 'Event',
        messageId: 'Message ID',
        to_dashboard: 'Go to dashboard',
        to_email_dashboard: 'Go to email dashboard',
        emails_dashboard: 'Emails dashboard',
        see_last_stats: 'See stats for the past ',
        last_days: ' days',
        templateId: 'Template ID',
        event_date: 'Event date',
        no_stats_found: 'No statistics found',
        harbors: 'Harbors',
        docks: 'Docks',
        inspections: 'Inspections',
        dock: 'Dock',
        harbor: 'Harbor',
        defenses: 'Defenses',
        defense: 'Defense',
        harbor_form: {
            title: 'Create a Harbor',
            name: 'Name',
            description: 'Description',
            address1: 'Main Address',
            address2: 'Secondary Address (optional)',
            postalCode: 'Postal Code',
            city: 'City',
            country: 'Country',
        },
        dock_form: {
            title: 'Docks & Defenses',
            name: 'Dock Name',
            description: 'Description',
            latitude: 'Latitude',
            longitude: 'Longitude',
            add: 'Add dock',
            update: 'Update  dock',
            cancel_update: 'Cancel',
            add_n_def: 'Add  dock and add defenses',
            update_n_def: 'Update  dock and add defenses',
            existingDocks: 'Existing Docks',
        },
        defense_form: {
            title: 'Add defenses',
            name: 'Defense Name',
            latitude: 'Latitude',
            longitude: 'Longitude',
            description: 'Description',
            add: 'Add defense',
            update: 'Update defense',
            cancel_update: 'Cancel',
            existingDefenses: 'Existing Defenses',
        },
        inspection_form: {
            name: 'Inspection Name',
            description: 'Inspection description',
        },
        def_tc: 'Defense | Defenses',
        coming_soon: 'Coming soon...',
        summary: 'Summary',
        select_harbor: 'Select a Harbor',
        select_dock: 'Select a Dock',
        no_results: 'No results',
        select_dock_warning: 'You must add the dock to add defenses',
        dock_tc: 'Dock | Docks',
        harbor_tc: 'Harbor | Harbors',
        defense_tc: 'Defense | Defenses',
        inspection_tc: 'Inspection | Inspections',
        start_inspection: 'Start Inspection',
        inspection_modal_header: 'Inspect selected dock',
        inspect: 'Inspect',
        inspection_ok: 'Inspection launched',
        inspection_ko: 'Error while requesting inspection',
        last_inspection: 'Last inspection',
        drag_pin_or_enter_coords:
            'Drag the pin on map or enter the coordinates',
        dock_updates_not_saved: 'Dock updates not saved',
        defense_updates_not_saved: 'Defense updates not saved',
        cancel_selection: 'Cancel selected elements',
        canceled: 'Canceled',
        pricing_tc: 'Pricing | Pricings',
        param_tc: 'Parameter | Parameters',
        vol: 'Volume (m3)',
        param_size: 'Size (m)',
        pricings_list: 'Pricings',
        pricings_params: 'Pricing parameters',
        pricing_postal_groups: 'Pricings by postal codes groups',
        date_begin: 'Start date',
        date_end: 'End date',
        order_price_param: 'Order',
        postal_groups_list: 'Postal codes groups',
        add_param: 'Add parameter',
        update_param: 'Update parameter',
        pricing_param_modal_info:
            'Updates made here will affect all pricings to which the parameter is linked.',
        pricing_params_tc: 'Pricing parameter | Pricing parameters',
        pricing_params: 'Pricing parameters',
        min: 'Min.',
        max: 'Max.',
        km: 'Km',
        zip_groups: 'Postal codes groups',
        postalcode_groups_tc: 'Postal codes group | Postal codes groups',
        group_modal_info:
            'Updates made here will affect all pricings to which the group is linked.',
        postal_codes: 'Postal codes',
        add_postal_code_group: 'Add postal code group',
        update_postal_code_group: 'Update postal code group',
        general: 'Global',
        value_per_km: 'Value per km',
        delivery_zip_group: 'Delivery postal code group',
        pickup_zip_group: 'Pickup postal code group',
        update_client_postal_code_group: 'Update client postal code group',
        add_client_postal_code_group: 'Add client postal code group',
        pricing_activate_ok: 'Pricing successfuly activated',
        pricing_activate_ko: 'Pricing activation failed',
        pricing_deactivate_ok:
            'Pricing successfuly deactivated | Pricings successfuly deactivated',
        pricing_deactivate_ko:
            'Pricing deactivation failed | Pricings deactivation failed',
        activate_pricing: 'Activate pricing',
        fixed_price: 'Fixed price for delivery',
        fixed_price_short: 'Fixed price',
        select_clients: 'Start typing to search for clients',
        all_clients_selected: 'Pricing will apply to all clients',
        default_pricing: 'Default pricing',
        selected_clients: 'Selected client | Selected clients',
        selected_params: 'Selected parameter | Selected parameters',
        select_items: 'Start typing to search',
        no_params_selected: 'No parameter selected',
        no_group_selected: 'No group selected',
        pricing_created: 'Pricing successfully created',
        pricing_updated: 'Pricing successfully updated',
        delete_modal_pricing_text_3:
            'All depending elements will be deleted (parameters, postal codes groups)',
        delete_ok: 'Successfully deleted',
        delete_ko: 'Deletion failed',
        no_default_pricing: 'No default pricing',
        copy_noun: 'Copy',
        co2_emission_manufacturer: 'Manufacturer CO2 emissions ',
        empty_weight: 'Tare weight',
        containers_on_hold_tc: 'Container on hold | Containers on hold',
        eventType_list: 'Event types',
        triggers_list: 'Triggers',
        trigger_tc: 'Trigger | Triggers',
        sendEmail: 'Send email',
        sendSms: 'Send SMS',
        sendEmailWhatsApp: 'Send email and WhatsApp',
        sendEmailSms: 'Send email and SMS',
        sendWhatsApp: 'Send WhatsApp',
        sendSmsWhatsApp: 'Send SMS and WhatsApp',
        sendEmailSmsWhatsApp: 'Send email, SMS and WhatsApp',
        email_template: 'Email template',
        sms_template: 'SMS template',
        wa_template: 'WhatsApp template',
        no_template_assigned: 'No template assigned',
        type_event: 'Event type',
        select_trigger: 'Select trigger',
        delay: 'Delay',
        unit_delay: 'Unit',
        add_trigger: 'Add trigger',
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        available_email_templates: 'Available email templates',
        available_sms_wa_templates: 'Available SMS/WhatsApp templates',
        notification_activate_ok: 'Event type successfuly activated',
        notification_activate_ko: 'Event type activation failed',
        notification_deactivate_ok:
            'Event type  successfuly deactivated | Event type s successfuly deactivated',
        notification_deactivate_ko:
            'Event type  deactivation failed | Event type s deactivation failed',
        activate_event: 'Activate event',
        waTemplates_list: 'WhatsApp templates',
        smsTemplates_list: 'SMS templates',
        emailTemplates_list: 'Email templates',
    },
    Fr: {
        waTemplates_list: 'Modèles WhatsApp',
        smsTemplates_list: 'Modèles SMS',
        emailTemplates_list: 'Modèles Email',
        activate_event: 'Activer l’événement',
        notification_activate_ko: "L'activation du type d'évènement a échoué",
        notification_activate_ok:
            "Le type d'évènement a été activé avec succès",
        notification_deactivate_ko:
            "La désactivation du type d'évènement a échoué | La désactivation des types d'évènements a échoué",
        notification_deactivate_ok:
            "Le type d'évènement a été désactivée avec succès | Les types d'évènements ont été désactivées avec succès",

        available_sms_wa_templates: 'Modèles de SMS/WhatsApp disponibles',
        available_email_templates: 'Modèles d’email disponibles',
        minutes: 'Minutes',
        hours: 'Heures',
        days: 'Jours',
        add_trigger: 'Ajouter un déclencheur',
        delay: 'Délai',
        unit_delay: 'Unité',
        select_trigger: 'Sélectionner un déclencheur',
        type_event: 'Type d’événement',
        no_template_assigned: 'Aucun modèle attribué',
        email_template: 'Modèle d’email',
        sms_template: 'Modèle de SMS',
        wa_template: 'Modèle de WhatsApp',

        sendEmail: 'Envoyer un email',
        sendSms: 'Envoyer un SMS',
        sendEmailWhatsApp: 'Envoyer un email et WhatsApp',
        sendEmailSms: 'Envoyer un email et SMS',
        sendWhatsApp: 'Envoyer un WhatsApp',
        sendSmsWhatsApp: 'Envoyer un SMS et WhatsApp',
        sendEmailSmsWhatsApp: 'Envoyer un email, SMS et WhatsApp',
        trigger_tc: 'Déclencheur | Déclencheurs',
        eventType_list: 'Types d’événements',
        triggers_list: 'Déclencheurs',
        containers_on_hold_tc: 'Conteneur en attente | Conteneurs en attente',
        empty_weight: 'Poids à vide',
        co2_emission_manufacturer: 'Émissions CO2 constructeur',
        copy_noun: 'Copie',
        no_default_pricing: 'Aucune tarification par défaut',
        delete_ok: 'Suppression réussie',
        delete_ko: 'Échec de la suppression',
        delete_modal_pricing_text_3:
            'Tous les éléments dépendants seront supprimés (paramètres, groupes de codes postaux)',
        pricing_updated: 'Tarification mise à jour avec succès',
        pricing_created: 'Tarification créée avec succès',
        no_group_selected: 'Aucun groupe sélectionné',
        no_params_selected: 'Aucun paramètre sélectionné',
        select_items: 'Commencez à taper pour rechercher',
        selected_params: 'Paramètre sélectionné | Paramètres sélectionnés',
        selected_clients: 'Client séléectionné | Clients sélectionnés',
        default_pricing: 'Tarification par défaut',
        all_clients_selected:
            "La tarification pourra s'appliquer à tous les clients",
        select_clients: 'Commencez à taper pour rechercher des clients',
        fixed_price_short: 'Prix fixe',
        fixed_price: "Prix fixe d'une livraison",
        activate_pricing: 'Activer la tarification',
        pricing_deactivate_ok:
            'La tarification a été désactivée avec succès | Les tarifications ont été désactivées avec succès',
        pricing_deactivate_ko:
            'La désactivation de la tarification a échoué | La désactivation des tarifications a échoué',
        pricing_activate_ko: 'L’activation de la tarification a échoué',
        pricing_activate_ok: 'La tarification a été activée avec succès',
        update_client_postal_code_group:
            'Mettre à jour le groupe de code postal client',
        add_client_postal_code_group: 'Ajouter un groupe de code postal client',
        delivery_zip_group: 'Groupe de code postal de livraison',
        pickup_zip_group: 'Groupe de code postal de ramassage',
        value_per_km: 'Valeur par km',
        general: 'Général',
        add_postal_code_group: 'Ajouter un groupe de code postal',
        update_postal_code_group: 'Mettre à jour le groupe de code postal',
        postal_codes: 'Codes postaux',
        zip_groups: 'Groupes de code postaux',
        pricing_params: 'Paramètres de tarification',
        group_modal_info:
            'Les mises à jour effectuées ici impacteront toutes les tarifications auxquelles le groupe est lié',
        postalcode_groups_tc:
            'Groupe de codes postaux | Groupes de codes postaux',
        km: 'Km',
        min: 'Min.',
        max: 'Max.',
        pricing_params_tc:
            'Paramètre de tarification | Paramètres de tarification',
        pricing_param_modal_info:
            'Les mises à jour effectuées ici impacteront toutes les tarifications auxquelles le paramètre est lié',
        add_param: 'Ajouter un paramètre',
        update_param: 'Mettre à jour le paramètre',
        postal_groups_list: 'Groupes de code postaux',
        order_price_param: 'Commande',
        date_begin: 'Date de début',
        date_end: 'Date de fin',
        pricings_list: 'Tarifications',
        pricings_params: 'Paramètres de tarification',
        pricing_postal_groups: 'Tarifications par groupes code postaux',
        vol: 'Volume (m3)',
        param_size: 'Taille (m)',
        pricing_tc: 'Tarification | Tarifications',
        param_tc: 'Paramètre | Paramètres',
        canceled: 'Annulé',
        cancel_selection: 'Annuler les éléments sélectionnés',
        dock_updates_not_saved: 'Mises à jour du quai non enregistrées',
        defense_updates_not_saved:
            'Mises à jour de la défense non enregistrées',
        drag_pin_or_enter_coords:
            'Déplacez le point sur la carte ou saisissez les coordonnées',
        last_inspection: 'Dernière inspection',
        inspection_ok: 'Inspection lancée',
        inspection_ko: "Erreur lors de la demande d'inspection",
        inspection_form: {
            name: "Nom de l'inspection",
            description: "Description de l'inspection",
        },
        inspect: 'Inspecter',
        inspection_modal_header: 'Inspecter le quai sélectionné',
        start_inspection: "Commencer l'inspection",
        dock_tc: 'Quai | Quais',
        harbor_tc: 'Port | Ports',
        defense_tc: 'Défense | Défenses',
        inspection_tc: 'Inspection | Inspections',

        no_results: 'Aucun résultat',
        select_harbor: 'Sélectionnez un port',
        select_dock: 'Sélectionnez un quai',
        summary: 'Résumé',
        coming_soon: 'Bientôt disponible...',

        // fr.json
        def_tc: 'Défense | Défenses',

        defenses: 'Défenses',
        defense: 'Défense',
        harbor_form: {
            title: 'Créer un Harbor',
            name: 'Nom',
            namePlaceholder: 'Nom du Harbor',
            description: 'Description',
            address1: 'Adresse principale',
            address2: 'Adresse secondaire',
            postalCode: 'Code Postal',
            city: 'Ville',
            country: 'Pays',
        },
        dock_form: {
            title: 'Quais & Défenses',
            name: 'Nom du quai',
            description: 'Description',
            latitude: 'Latitude',
            longitude: 'Longitude',
            add: 'Ajouter ce quai',
            add_n_def: 'Ajouter le quai et des défenses',
            update_n_def: 'Mettre à jour le quai et ajouter des défenses',
            update: 'Mettre à jour le quai',
            cancel_update: 'Annuler ',
            existingDocks: 'Quais existants',
        },
        defense_form: {
            title: 'Ajouter des Défenses',
            name: 'Nom de la Défense',
            description: 'Description',
            latitude: 'Latitude',
            longitude: 'Longitude',
            add: 'Ajouter la Défense',
            cancel_update: 'Annuler ',
            update: 'Mettre à jour la Défense',

            existingDefenses: 'Défenses existantes',
        },
        select_dock_warning:
            'Vous devez ajouter le quai pour lui attibuer des défenses',

        // en.json (example)

        dock: 'Quai',
        harbor: 'Port',
        harbors: 'Ports',
        docks: 'Quais',
        inspections: 'Inspections',
        details: 'Détail',
        no_stats_found: 'Aucune statistique trouvée',
        event_date: "Date de l'événement",
        templateId: 'ID du template',
        see_last_stats: 'Voir les statistiques des ',
        last_days: ' derniers jours',
        events_table: 'Détails des événements',

        emails_dashboard: 'Tableau de bord des emails',
        to_dashboard: 'Aller au tableau de bord',
        to_email_dashboard: 'Aller au tableau de bord des emails',
        aggregatedStats: 'Statistiques Agrégées',
        aggregated_stats: 'Statistiques Agrégées',

        events: 'Événements',

        subject: 'Sujet',
        event: 'Événement',
        messageId: 'ID du Message',
        email_status: {
            requests: {
                short: 'Demandé',
                chart: 'Demandes',
                info: 'La demande a été reçue par le fournisseur de service email.',
            },
            request: {
                short: 'Demandé',
                chart: 'Demande',
                info: 'La demande a été reçue par le fournisseur de service email.',
            },
            opens: { chart: 'Ouvertures' },
            uniqueOpens: { chart: 'Ouvertures uniques' },

            clicks: { chart: 'Clics' },
            spamReports: { chart: 'Signalés comme spam' },
            loadedByProxy: { chart: 'Ouvert par proxy' },
            uniqueClicks: { chart: 'Clics uniques' },
            sent: { short: 'Envoyé', info: 'Envoyé', chart: 'Envoyés' },
            delivered: { short: 'Délivré', info: 'Délivré', chart: 'Délivrés' },
            hard_bounce: {
                short: 'Erreur',
                info: "Message définitivement rejeté par le serveur du destinataire. Vérifiez l'email du destianataire.",
            },
            hardBounces: {
                short: 'Erreur',
                chart: 'Rejets définitifs',
                info: "Message définitivement rejeté par le serveur du destinataire.Vérifiez l'email du destianataire.",
            },
            soft_bounce: {
                short: 'Erreur',
                chart: 'Rejets temporaires',
                info: "Message temporairement rejeté par le serveur du destinataire. Vérifiez l'email du destianataire.",
            },
            softBounces: {
                short: 'Erreur',
                chart: 'Rejets temporaires',
                info: "Message temporairement rejeté par le serveur du destinataire. Vérifiez l'email du destianataire.",
            },
            click: { short: 'Clické', info: 'Le destinaire à clické le lien' },
            open: { short: 'Ouvert', info: "Le destinataire a ouvert l'email" },
            opened: {
                short: 'Ouvert',
                chart: 'Ouverts',
                info: "Le destinataire a ouvert l'email",
            },
            unique_opened: {
                short: 'Première ouverture',
                chart: 'Ouvert',
                info: "Le destinataire a ouvert l'email",
            },

            spam: {
                short: 'Spam',
                info: "Email signalé comme spam par le fournisseur d'accès du destinataire",
            },
            blocked: {
                short: 'Bloqué',
                chart: 'Bloqués',
                info: "L'email n'a pas pu atteindre la boîte de réception du destinataire en raison d'un expéditeur figurant sur une liste noire, etc.",
            },
            invalid: {
                short: 'Invalide',
                chart: 'Emails invalides',
                info: "L'adresse email de l'expéditeur n'est pas invalide ou n'existe pas.",
            },
            unsubscribed: {
                short: 'Désinscription',
                chart: 'Désinscriptions',
                info: "Le destinataire s'est désinscrit pour ne plus recevoir de courriels de l'expéditeur.",
            },
            deferred: {
                short: 'Retardé',
                chart: 'Retardés',
                info: 'La livraison du courrier électronique a été retardée par le serveur de messagerie du destinataire.',
            },
            error: {
                short: 'Erreur',
                chart: 'Erreurs de transfert',
                info: "Une erreur s'est produite lors du transfert du courrier de l'expéditeur au destinataire.",
            },
            proxy_open: {
                short: 'Ouvert',
                info: "Le proxy indique si l'e-mail a été ouvert",
            },
            unique_proxy_open: {
                short: 'Ouvert',
                info: "Le proxy indique si l'e-mail a été ouvert",
            },
            invalid_email: {
                short: 'Invalide',
                info: "L'adresse email de l'expéditeur n'est pas valide",
            },
        },
        client_credit: 'Crédit client',
        clientCredit: 'Crédit client',
        cancel_ok: 'Annulation des commandes effectuée',
        cancel_ko: 'Annulation des commandes échouée',
        delete_file: 'Supprimer le fichier (les commandes sont conservées)',
        cancel_imported:
            'Annuler l’import (supprime les commandes non affectées)',
        driver_required: 'Livreur requis',
        purchase_price_required: 'Les prix d’achat est requis',

        cancel_import: 'Annuler l’import (supprime les commandes)',
        relaunch_import: 'Relancer l’import',
        error_occured_while_generating_charter:
            "Une erreur est survenue lors de la génération du document d'affrètement",
        save_n_visualise:
            "Enregistrer et visualiser le document d'affrètement (nouvelle fenêtre)",
        position: 'Position',
        force_with_position: 'Forcer à la position choisie',
        force_with_position_info:
            'Vous pouvez forcer le recalcul sans indiquer où positionner les étapes choisies en cliquant directement sur "confirmer".',
        force_with_position_info_2:
            'La tournée sera alors réoptimisée et vous perdrez vos modifications précedentes.',
        force_with_position_info_3:
            'Ou vous pouvez choisir une position pour ajouter les étapes dans la tournée en remplissant le champ ci-dessous.',
        duplicate_field_value: 'Valeur de champ dupliquée',
        required_field_missing: 'Champ obligatoire manquant',
        order_file_fields: {
            ExternalReference: 'Référence externe',
            DeliveryDate: 'Date de livraison',
            PickupDate: 'Date de ramassage',
            AddressPickupName: 'Nom adresse de ramassage',
            AddressPickup1: 'Adresse de ramassage',
            AddressPickup2: "Complément d'adresse",

            AddressPickup3: "Complément d'adresse",
            AddressPickup4: "Complément d'adresse",
            AddressPickupPostalCode: 'Code postal de ramassage',
            AddressPickupCity: 'Ville de ramassage',
            AddressDeliveryName: 'Nom adresse de livraison',
            AddressDelivery1: 'Adresse de livraison',
            AddressDelivery2: 'Complément adresse',
            AddressDelivery3: 'Complément adresse',
            AddressDelivery4: 'Complément adresse',
            AddressDeliveryPostalCode: 'Code postal de livraison',
            AddressDeliveryCity: 'Ville de livraison',
        },
        onHoldSince: 'En attente depuis le',
        d: 'j',
        deadline: "Temps d'attente limite",
        elapsed: 'Temps écoulé',
        containers_on_hold: 'Conteneurs en attente',
        extension: 'Extension de fichier',
        pdf: 'PDF',
        csv: 'CSV',
        xls: 'XLS',

        send_with_payment_link: 'Envoyer par mail avec un lien de paiement',
        send_reminder_with_payment_link:
            'Envoyer un rappel avec un lien de paiement',
        order_fields: 'Champs de commande',
        file_fields:
            'Numéro de colonne ou nom du champ (pour les fichiers pdf) correspondant dans votre fichier',
        files_uploaded: 'Fichiers ajoutés avec succès.',

        upload_file_from_user_info:
            'Vous pouvez ajouter des fichers qui seront liés à la commande choisie. Ils sont uniquement stockés dans notre base de donnée pour faciliter vos démarches. Aucun traitement ne sera effectué.',
        upload_user_file: 'Ajouter un fichier (hors bon de commande)',
        relaunch_ok: 'Relance effectuée',
        linked_steps: 'Voir les étapes liées',
        address_data_missing:
            "Certaines informations de l'adresse sont manquantes",
        client_has_no_processor:
            "Impossible d'importer un fichier PDF pour ce client. Veuillez contacter Cédric",
        onu_authorized: 'Habilité transport de marchandises dangereuses',
        ref_address_extra_info_update:
            'Mettre à jour les informations complémentaires de l’adresse',
        vat_not_specified: 'TVA non renseignée',
        new_address_for: 'New delivery address',

        ste: 'Société',
        default_contact: 'Contact par défaut',
        ste_name: 'Nom de la société',
        container_number: 'Numéro conteneur',
        followed_by: 'Suivie par',
        key_words: 'Mots clés',
        address_keys: 'Mots clés de correspondance adresse',
        processor_placeholder:
            'Entrez des identifiants de processeurs, séparés par une virgule',
        select_address_info: 'Veuillez sélectionner une adresse',
        keywords_placeholder:
            'Veuillez séparer chaque mot ou groupe de mots par une virgule',
        client_keys: 'Mots clés de correspondance client',
        address_matching_keys: 'Mots clés de correspondance adresse',
        processor_to_use: 'Processeur Document AI à utiliser',
        import_config: 'Configuration des imports',
        assign_to_driver: 'Affecter les commandes sélectionnées à un livreur',
        finish_many_steps: 'Terminer les étapes sélectionnées',
        assign: 'Affecter',
        no_credits_yet: 'Aucun crédit',
        crefits_ko: 'Échec de l’ajout de crédits',
        credits_ok: 'Crédits ajoutés avec succès',
        update_article: 'Mise à jour de l’article',
        create_article: 'Créer un article',
        address_not_geolocated: 'Adresse non géolocalisée',
        add_credits: 'Ajouter des crédits',
        update_credits: 'Mettre à jour les crédits',
        send_charter: "Envoyer le document d'affrètement",
        step_comment: 'Commentaire étape',
        external_reference: 'Référence externe',
        conteneurRef: 'Réf. conteneur',
        seal_number: 'Numéro de plomb',
        purchase_price: "Prix d'achat sous-traitance",
        selling_price: 'Prix de vente sous-traitance',

        update_sale: 'Mettre à jour la vente',
        new_sale: 'Nouvelle vente',
        new_hazardous_product: 'Nouveau produit dangereux',
        sales: 'Ventes',
        global_address: 'Adresse globale',
        credits: 'Crédits',
        no_credits: 'Aucun crédit',
        with_vat: 'Avec TVA',
        generate_proforma_for_many:
            'Générer une proforma unique pour les commandes sélectionnées',
        generate_proforma_for_many_sales:
            'Générer une proforma unique pour les ventes sélectionnées',
        generate_proforma: 'Générer une proforma',
        valid_until: "Valide jusqu'au",
        my_credits: 'Mes crédits',
        new_article: 'Nouvel article',
        tour_opti_error_forced:
            'La tournée a précédement été modifiée manuellement, une modification entrainera une nouvelle optimisation.',
        generate_selected_transport_slips:
            'Générer les bordereaux de transport pour les commandes sélectionnées',
        slips_generation_failed: 'Échec de la génération des bordereaux',
        slips_generation_launched: 'Lancement de la génération des bordereaux',
        generate_transport_slip: 'Générer le bordereau de transport',
        print_transport_slip: 'Imprimer le bordereau de transport',
        print_selected_transport_slips:
            'Imprimer les bordereaux de transport sélectionnés',
        see_sales: 'Voir les ventes complémentaires',
        hide_sales: 'Masquer les ventes complémentaires',
        vat_amount: 'Montant TVA',
        unit_ht: 'Prix unitaire HT',
        net_weight: 'Poids net',
        no_hazard_yet: 'Aucun produit dangereux pour le moment',

        group_onu: 'Groupe',
        onu_class: 'Classe',
        hazardous_products: 'Produits dangereux',
        create_hazardous_product: 'Créer un produit dangereux',
        update_hazardous_product: 'Mettre à jour le produit dangereux',
        pick_article: 'Sélectionnez un article',
        coef: 'Coefficient',
        price_pack_ht: 'Forfait HT',
        price_percent: '% du prix de la commande',
        price_written: 'Affiché sur la facture',

        articles: 'Articles',
        pick_up_between: 'Entre',
        delivery_and: 'Et',
        break_step_label: 'Étape de rupture',
        create_step_break: 'Créer une rupture',
        tour_opti_error_deliv_before_pickup:
            "L'étape de livraison ne peut pas se faire avant l'étape d'enlèvement",
        one_up: "Remonter l'étape",
        one_down: "Descendre  l'étape",
        opti_error_info:
            'La modification demandée peut impacter plusieurs tournées.',
        opti_error_choice: 'Souhaitez-vous forcer cette modification ?',
        action_required: 'Action requise',
        total_ht: 'HT',
        total_ttc: 'TTC',
        tour_opti_error_multiple_tours:
            "Plusieurs tournées ont été produites, l'optimisation n'est pas possible avec les contraintes choisies",
        tour_opti_error_steps_not_assigned:
            'Des étapes sont non assignées, optimisation impossible avec les contraintes choisies',
        see_file: 'Voir le Fichier (ouvre un nouvel onglet)',
        print_order_files: 'Imprimer les documents des commandes',
        delivery_files: 'Documents commande',
        no_driver: 'Pas de livreur',
        imported_pdf: 'Importé depuis PDF',
        comment_visible_by_client_info: 'Votre client peut voir ce commentaire',
        comment_not_visible_by_client: 'Commentaire non visible par le client',
        copy_jwt: "Copier le jeton d'authentification",
        forced_price: 'Prix convenu',
        api_doc: 'Documentation API',
        order_pricing_scenario: 'Scénario de tarification pour la livraison',
        tour_pricing_scenario:
            'Scénario de tarification pour le regroupement de livraisons',
        order_group_modal_header: 'Mise à jour du regroupement de livraisons',
        order_group_modal_info:
            'Les mises à jour apportées ici impacteront toutes les livraisons faisant partie du groupe de livraisons',
        orderGrouping: 'Regroupement de livraisons',
        orderGroup: 'Regroupement de livraisons',
        add_tour_to_order: 'Ajouter un groupe - Entrez le nom du groupe',

        order_group: 'Regroupement de livraisons',

        can_add_contract: 'Vous pouvez maintenant ajouter un contrat',
        no_complement_yet: 'Aucune vente complémentaire',
        vat: 'TVA',
        sale_name: 'Désignation',
        order_complement: 'Complément',
        no: 'Non',
        yes: 'Oui',
        open_ended: 'Indéterminée',
        openEnded: 'Indéterminée',

        invoiced: 'Commande déjà facturée',
        signature_date: 'Date de signature',

        hide_contracts: 'Masquer les contrats',
        see_contracts: 'Afficher les contrats',
        yesWithEmail: 'Oui, avec email au client',
        contract_create_ko: 'Erreur lors de la création du contrat',
        contract_create_ok: 'Contrat créé avec succès',
        contract_update_ko: 'Erreur lors de la mise à jour du contrat',
        contract_update_ok: 'Contrat mis à jour avec succès',
        contract_duration: 'Durée (mois)',
        no_client: 'Client non trouvé',
        yes_with_mail: 'Oui, avec email au client',
        not_applicable: 'Non applicable',
        update_contract: 'Mettre à jour le contrat',
        add_contract: 'Ajouter un contrat',
        rental: 'Location',
        work_fixed_term: 'Travail à durée déterminée',
        work_open_ended: 'Travail à durée indéterminée',
        auto_renew: 'Renouvellement',
        alert_before_end: 'Rappel avant échéance',
        notice: 'Préavis',
        client_name: 'Client',
        contract_type: 'Type de contrat',
        contracts: 'Contrats',
        see_det: 'Voir les détails',
        arch_tc: 'Archivée | Archivées',

        only_late_plus_todo_30:
            'Afficher seulement les maintenances en retard et à faire + 30 jours',
        maintenances_list: 'Maintenances',
        not_urgent: 'Non urgent',
        urgent: 'Urgent',
        done_date: 'Effectuée le',
        set_as_done: 'Marquer comme effectuée',
        todo: 'À faire',
        late: 'En retard',
        late_vehicle_maintenance:
            "Vehicule dont l'entretien est en retard | Vehicules dont l'entretien est en retard ",

        archived_status: 'Statut',
        archive: 'Archiver',
        unarchive: 'Désarchiver',
        unarchive_selection: 'Désarchiver la sélection',
        archive_selection: 'Archiver la sélection',
        archived: 'Archivé',
        ongoing: 'En cours',
        demo_price: 'Prix',
        demo_price_info: '€ TTC',
        error_updating_price: 'Erreur lors de la mise à jour du prix',
        update_price: 'Mettre à jour le prix',
        electric_bike: 'Vélo électrique',
        electric_car: 'Voiture électrique',
        save_and_send:
            'Enregistrer et envoyer aussi le rappel aux utilisateurs concernés maintenant',
        past_event: 'Événement passé',
        error_occured_setting_reminder:
            'Erreur lors de la définition du rappel',
        bad_emails_format: 'Les adresses emails ne sont pas au bon format',
        new_reminder: 'Nouveau rappel',
        reminder_date: 'Date du rappel',
        users_to_notify: 'Utilisateurs à notifier',
        import_from_doc: 'Importer depuis Word (.doc, .docx)',
        save_as_pdf: 'Exporter en PDF',
        save_as_word: 'Exporter en Word (.doc)',
        save_client_first:
            "Veuillez enregistrer le client avant d'ajouter une note",
        save_client_before_contract:
            "Veuillez enregistrer le client avant d'ajouter un contrat",
        can_add_note: 'Vous pouvez maintenant ajouter une note',
        update_note: 'Modification de la note',
        client_not_found: 'Client non trouvé',
        note_type: 'Type de note',
        attachement: 'Pièce jointe | Pièces jointes',

        title_info: 'Vous permettra de retrouver plus facilement votre note',
        write_note:
            'Cliquez ici pour commencer à écrire, collez le contenu de votre fichier Word ou importez un fichier Word',
        select_salesperson_info: 'Commercial',
        set_reminder: 'Rappel',
        email_note: 'Email',
        reunion: 'Réunion',
        other: 'Autre action',
        no_pinned_notes_yet: 'Aucune note épinglée pour le moment',
        note: 'Note | Notes',
        your_notes: 'Vos notes',
        new_note: 'Nouvelle note',
        no_notes_yet: 'Aucune note pour le moment',
        pinned: 'Épinglées',
        sales_person: 'Commercial',
        meeting_date: 'Date de rendez-vous',
        prospect: 'Prospect',
        contact_client_history: 'Historique des communications client',
        not_editable: 'Non modifiable',
        tour_tours: 'Tournée | Tournées',
        already_invoiced: 'Commande déjà facturée',
        invoice_generation_no_contact_client:
            'Aucun utilisateur de facturation paramétré pour le client',
        invoice_generation_no_billing_address:
            'Aucune adresse de facturation paramétrée pour le client',
        one_package_tracking: 'Suivi du colis',
        remove_package: 'Supprimer le colis',
        imported_from_file:
            'Commande importée depuis un fichier| Commandes importées depuis un fichier',
        imported: 'Importée',
        order_status: 'Statut des commandes',
        all_orders: 'Toutes les commandes',
        imported_only: 'Importées uniquement',
        not_imported_only: 'Non importées uniquement',
        invoice_available: 'Facture disponible',
        ivoiced: 'Commande déjà facturée.',
        for_login: 'identifiant de connexion',
        add_different_package_type: 'Ajouter un colis différent',

        invoice_not_generated: 'Facture non générée',
        generate_invoice_as_paid: 'Générer facture comme réglée',
        same_order_steps: 'Autres étapes de la commande',
        clocked_in: 'Pointé - Présent',
        clocked_out: 'Pointé - Absent',
        not_clocked_in: 'Non pointé',
        inactive_driver:
            'Le livreur sélectionné est inactif, veuillez en sélectionner un autre',

        wrong_payment_type_for_import_1:
            "Le client que vous avez choisi n'a pas le bon moyen de paiement pour l'import de fichier de commandes.",
        wrong_payment_type_for_import_2:
            'Veuillez sélectionner le nouveau moyen de paiement à appliquer au client et cliquer sur le bouton "mettre à jour", ou cliquez sur "annuler" .',
        wrong_payment_type_for_import: 'Mise à jour du client nécessaire',
        imports_errors_subtext:
            '(Pour supprimer cette notification, supprimez le fichier et réimportez-le) | (Pour supprimer cette notification, supprimez les fichiers et réimportez-les)',
        simulations_errors_subtext:
            '(Pour supprimer cette notification, relancez la simulation) | (Pour supprimer cette notification, relancez les simulations)',
        simulations_errors:
            'Erreur de génération de simulation au cours des 15 derniers jours | Erreurs de génération de simulation au cours des 15 derniers jours',
        imports_errors:
            'Erreur d’import de fichier au cours des 15 derniers jours | Erreurs d’import de fichier au cours des 15 derniers jours',
        vehicle_maintenances_plus_30:
            'Véhicule va entrer en maintenance dans les 30 prochains jours | Véhicules vont entrer en maintenance dans les 30 prochains jours',
        your_invoices: 'Vos factures',
        invoiced_not_paid:
            'Facture en attente de paiement | Factures en attente de paiement',
        not_invoiced: 'Commande non facturée | Commandes non facturées',
        finished_orders: 'Commande livrée | Commandes livrées',
        in_progress_orders: 'En cours de livraison | En cours de livraison',
        waiting_orders:
            'En attente de prise en charge | En attente de prise en charge',
        todays_total_orders: "Commande aujourd'hui | Commandes aujourd'hui",
        todays_total_tours: "Tournée aujourd'hui | Tournées aujourd'hui",
        unaffected_orders:
            "Étape ne faisant pas partie d'une tournée | Étapes ne faisant pas partie d'une tournée",

        waiting_for_web_payment:
            'En attente de paiement web | En attente de paiement web',
        steps_to_correct: 'Adresse à corriger | Adresses à corriger',
        session_timed_out:
            'Vous avez été déconnecté. Veuillez vous reconnecter',
        login_biiim_info: 'Laissez vous guider !',
        web_driver_assign_info:
            'Information paiement web : Vous pourrez affecter un livreur et/ou un véhicule une fois la commande payée.',
        also_update_user_login:
            "Mettre à jour l'identifiant de connexion de l’utilisateur",
        also_update_client_email: 'Mettre à jour l’email du client',

        client_delivery_addresses: 'Adresses de livraison du client',
        send_many_invoices:
            'Envoyer les factures aux clients sélectionnés aux clients sélectionnés',
        send_many_invoices_with_link:
            'Envoyer les factures avec un lien de paiement',

        creditcard: 'Carte bancaire',
        send_invoice_by_mail: 'Envoyer la facture au client par email',
        error_invoice_set_as_paid:
            'La facture n’a pas pu être marquée comme payée',
        success_invoice_set_as_paid: 'La facture a été marquée comme payée',
        validate: 'Valider',
        select_payment_date: 'Sélectionner une date de paiement',
        success_sending_invoice:
            'Facture envoyée au client | Factures envoyées aux clients',
        error_sending_invoice:
            'La facture n’a pas pu être envoyée au client | Certaines factures n’ont pas pu être envoyées aux clients',
        send_reminder: 'Envoyer un email de rappel au client',
        send_many_reminders:
            'Envoyer un email de rappel aux clients sélectionnés',
        set_many_as_paid: 'Marquer les factures sélectionnées comme payées',
        payment_date: 'Réglée le',
        set_invoice_as_paid: 'Marquer la facture comme payée',
        unpaid_invoices: 'Factures en attente de règlement',
        no_data_available: 'Donnée non disponible',

        ht: 'HT (€)',
        ttc: 'TTC (€)',
        distance_duration: 'Distance & durée',

        due_date: "Date d'échéance",
        date_invoice: 'Date de facturation',
        invoice_generation_not_same_client:
            'Les commandes à facturer appartiennent à plusieurs clients',
        invoice_generation_not_same_universe:
            'Les commandes à facturer appartiennent à plusieurs univers',
        invoice_generation_launched: 'Génération de la facture en cours',
        invoice_generation_failed: 'Erreur lors de la génération de la facture',
        generate_bill_for_many:
            'Générer la facture unique pour les commandes séléctionnées',
        generate_bill_for_many_sales:
            'Générer la facture unique pour les ventes séléctionnées',
        generate_bill: 'Générer la facture',
        amount_to_bill: 'Montant à facturer (€) ',
        invoicings: 'Commandes à facturer',
        owned_by: 'Appartient à',
        default_container_ok: 'Votre colis par défaut a été mis à jour',
        default_container_ko:
            'La mise à jour de votre colis par défaut a échoué',
        default_container_ok_for_client:
            'Colis par défaut mis à jour pour le client',
        default_container_ko_for_client:
            'La mise à jour du colis par défaut a échoué pour le client ',
        set_as_default_for_client:
            'Définir comme colis par défaut pour le client',
        set_as_default: 'Définir comme colis par défaut',
        operator_update_default:
            'Pour modifier cette information, veuillez contacter le support Biiim.',
        default_container_info: 'Ceci est votre format de colis par défaut.',
        reset_password_link_not_sent:
            'Une erreur est survenue lors de l’envoi du lien de réinitialisation de mot de passe.',
        reset_password_link_sent:
            'Lien de réinitialisation de mot de passe envoyé.',
        default_package: 'Colis par défaut',
        envelope: 'Enveloppe | Enveloppes',
        simulation_generation: "Génération d'une simulation",
        contact_biiim:
            'Veuillez contacter le support Biiim pour plus d’informations.',
        sim_integration_error_text_1:
            'La simulation ne peut pas être intégrée pour la journée sélectionnée.',
        sim_integration_error_text_2:
            'Certaines étapes sont déjà affectées à un livreur ou à une tournée.',

        unable_to_integrate_simulation: 'Erreur d’intégration de la simulation',

        tour_view: 'Carte de la tournée',
        simulation_view: 'Carte de la simulation',
        no_client_found_please_create_one:
            'Aucun client trouvé, veuillez en créer un.',
        checkout_session_not_updated:
            'La session de paiement pour cette commande n’a pas été mise à jour.',
        unique_id: 'Identifiant unique',
        applied_to_all_orders:
            'Toutes les commandes futures déjà existantes et non réglées seront mises à jour pour le client.',
        one_time_force_payment: 'Forcer le paiement cette fois seulement',
        set_client_to_month_invoice:
            'Passer le client en facturation mensuelle',
        paid_by_credit_card: 'Réglé par carte bancaire',
        paid_by_check: 'Réglé par chèque',
        paid_by_cash: 'Réglé en espèces',
        paid_by_transfer: 'Réglé par virement bancaire',
        offerd: 'Course offerte',
        order_to_bill_ko:
            "ATTENTION , la mise à jour du statut de la commande n'a pas fonctionné. Veuillez contacter le support Biiim.",
        forced_by_operator:
            "Paiement forcé par l’opérateur. N'a pas été payé via le fournisseur de service de paiement",
        provider_payment_intent_not_found:
            'La session de paiement n’existe pas. Veuillez vérifier les enregistrements de votre fournisseur de paiement.',
        payment_session_not_found:
            "La session de paiement n’existe pas. Veuillez contacter le support Biiim pour plus d'informations",
        payment_not_paid:
            'Il semble que cette commande ne soit pas payée. Elle ne peut donc pas être remboursée. Veuillez contacter votre opérateur pour plus d’informations.',
        biiim_checkout_create_error:
            'Une erreur est survenue du processus. Veuillez contacter votre opérateur.',
        provider_checkout_create_error:
            "Il semble qu'il y ait une erreur avec le fournisseur de paiement. Veuillez contacter votre opérateur.",
        order_update_failed_payment:
            'Le paiement a été effectué avec succès mais une erreur est survenue lors de la mise à jour de la commande. Veuillez contacter votre opérateur.',
        order_update_failed_refund:
            'Le remboursement a été effectué avec succès mais une erreur est survenue lors de la mise à jour de la commande . Veuillez contacter le support Biiim.',
        full_refund: 'Remboursement intégral',
        refund_driver_info_text:
            'Les commandes remboursées étant affectées à une tournée seront automatiquement retirées de celle-ci.',
        refunded: 'Remboursé',
        refund_must_select_oc:
            'Vous devez sélectionner au moins une commande à rembourser',
        refundable_amount: 'Montant remboursable (€)',
        refund_reason: 'Motif du remboursement',
        requested_by_customer: 'Demande du client',
        fraudulent: 'Paiement frauduleux',
        duplicate_payment: 'Paiement dupliqué',
        refund_button: 'Valider le remboursement',
        refund_modal_header: "Remboursement d'un paiement web",
        refund_comment: 'Commentaire sur le remboursement',
        amount_to_refund: 'Montant à rembourser (€)',
        refund_higher_than_amount:
            'Le montant à remboursé ne peut pas être supérieur au montant réglé',
        payment_result: 'Mon paiement',
        provider: 'Fournisseur de paiement',
        payment_ref: 'Référence de paiement',
        amount: 'Montant (€)',
        ocs: 'Commandes',
        refunded_amount: 'Montant remboursé (€) ',
        last_modified_by: 'Modifié par ',
        refund_com: 'Commentaire remboursement',
        update_step: "Correction de l'étape",
        paid: 'Payé',
        full_ref: 'Remboursement total',
        partial_refund: 'Remboursement partiel',
        refund_info_text:
            "Il est possible que certaines livraisons n'apparaissent pas ci-dessous. Cela signifie soit que vous avez déjà été remboursé, soit qu'elles font partie d'une autre session de paiement. Si vous souhaitez également être remboursé pour ces livraisons, faites en la demande auprès de votre opérateur.",

        contact_support_cancel_refund:
            'Veuillez contacter le support pour annuler votre livraison et demander un remboursement',
        ref_to_give: 'Référence de paiement à indiquer à votre opérateur',
        deliv_refund: 'Livraisons concernées',
        close: 'Fermer',
        refund_request_modal_header: 'Demande de remboursement',
        refund_detail: 'Détail du remboursement demandé',
        validate_refund: 'Rembourser le client',
        cancel_refund: 'Annuler le remboursement',
        refund_success: 'Remboursement effectué avec succès!',
        refund_error: "Erreur lors de l'envoi de la demande de remboursement",
        refunds: 'Paiements web & Remboursements',
        refund: 'Demander un remboursement',
        see_details: 'Ma livraison en détail',
        my_unpaid_deliveries: 'Mes livraisons en attente de paiement',
        back_to_deliveries: 'Retour à mes livraisons',
        payment_failed: 'Une erreur est survenue lors du paiement.',
        payment_failed_2: 'Veuillez réessayer ou contacter votre opérateur.',
        payment_success: 'Votre paiement a été effectué avec succès!',
        payment_success_2:
            'Votre livraison est maintenant en attente de prise en charge.',
        already_paid: 'Cette livraison a déjà été payée',
        no_payment_available:
            'Paiement en ligne non disponible. Veuillez contacter votre opérateur.',
        default_universe_info: "Défini par l'univers du client par défaut.",

        add_universes:
            'Ajouter un univers aux utilisateurs| Ajouter des univers aux utilisateurs',
        universes: 'Univers',

        delete_modal_generic_text_universe:
            'Tous les éléments dépendants seront mis à jour (utilisateurs, clients, commandes).',
        universe_already_exists: 'Un univers avec ce nom existe déjà',
        new_universe: 'Nouvel univers',
        update_universe: "Mise à jour de l'univers",
        your_universes: 'Vos univers',
        none: 'Aucun',
        universes_list: 'Liste des univers',
        course: 'Course',

        universe: 'Univers | Univers',
        update_recurrent_date_text:
            'Vous pouvez ici modifier la date de la commande planifiée. Elle sera alors automatiquement générée à la nouvelle date choisie.',
        update_recurrent_date_modal_header:
            'Modifier la date de la commande planifiée',
        edit_date: 'Modifier la date',
        planned: 'Planifiée',

        show_planned_rec:
            'Afficher / masquer les commandes récurrentes planifiées',
        ends_after: 'Se termine après',
        times: 'fois',
        credit_card: 'Carte bancaire',
        cash: 'Espèces',
        database: 'Base de données',
        default_billing_info2:
            'Vous pouvez modifier ces paramètres pour cette commande spécifique.',
        web_payment_info:
            'Si défini sur "Paiement web", les commandes seront affichées dans la liste des commandes non réglées jusqu\'à ce que le paiement soit reçu.',
        default_billing_info:
            'Définies par les informations de facturation du client par défaut.',
        delete_modal_generic_text_plural:
            'Êtes-vous sûr de vouloir supprimer ces éléments ?',
        action_confirm_generic_header: "Confirmer l'action",
        confirm_set_as_paid:
            'Confirmer la modification du statut des commandes suivantes en "payé"',
        irreversible_action: 'Cette action irréversible',
        order_not_waiting_for_payment:
            "Le statut de la commande est déjà réglé ou a déjà été mis à jour en statut 'payé'",
        order_not_set_as_paid:
            'Statut non mis à jour en "payé" pour les commandes suivantes',
        order_set_as_paid: 'Statut de la commande modifié en payée avec succès',
        no_contact_for_order: 'Aucun contact trouvé pour cette commande',
        not_unique_email:
            "L 'enregistrement n'est pas unique dans la base de données",
        order_id: 'Identifiant Biiim de la commande',
        order_ref: 'Référence de la commande',
        unknown_error: 'Erreur inconnue, veuillez contacter le support.',
        user_not_found: 'Utilisateur non trouvé',
        order_not_found: 'Commande non trouvée',
        reminder_not_sent: 'Relances non envoyées pour les commandes suivantes',
        error_setting_as_paid:
            'Le statut de la commande n\'a pas pu être modifié en "payé" | Les statuts de certaines commandes n\'ont pas pu être modifiés en "payé"',
        error_sending_reminder_mail:
            "L'email de relance n'a pas pu être envoyé | Certains emails de relance n'ont pas pu être envoyés",
        success_setting_as_paid:
            'Le statut de la commande a été modifié en "payé" | Les statuts des commandes sélectionnées ont été modifiés en "payé"',
        success_sending_reminder_mail:
            'Un e-mail de relance a été envoyé au client | Des e-mails de relance ont été envoyés aux clients',
        packages_info: 'Colis | Colis',
        set_as_paid: 'Marquer comme payé',
        send_reminder_mail: 'Envoyer un e-mail de relance',
        send_reminder_mail_with_link:
            'Envoyer un e-mail de relance avec un lien de paiement',
        waitingforpayment: 'En attente de paiement',
        unpaid_orders: 'Commandes web non réglées',
        web: 'Paiement web',
        email_same_as_client: 'Même email que le client',
        back_to_predefined_contacts:
            'Retourner à la liste des contacts existants',

        contact_update: 'Modifier ce contact',
        address_update: 'Modifier cette adresse',
        occasional_client_order_not_displayed_until_payment:
            "Les commandes en paiement web ne seront pas affichées dans la liste des commandes tant que le paiement n'aura pas été effectué.",
        got_it: "J'ai compris !",
        billing_contact: "Contact de l'adresse de facturation",
        round_trip_label: 'Trajet retour',

        delivery_step_incomplete: 'Informations de livraison incomplètes',

        cannot_update_recurrent:
            'Veuiller contactez votre opérateur pour modifier ce colis.',
        cannot_delete_recurrent:
            'Veuillez contacter votre opérateur pour supprimer ce colis.',
        cannot_duplicate_recurrent:
            'Veuillez contacter votre opérateur pour dupliquer ce colis.',
        see_cart: 'Voir mon panier',

        pay_pack_only: 'Régler seulement ce colis',

        pay: 'Régler cette livraison ',
        my_cart: 'Mon panier',
        recurrent: 'Commande récurrente',

        waiting_for_payment: 'En attente de paiement',

        recurrent_activate_ok: 'Commande récurrente activée avec succès',
        recurrent_activate_ko:
            "Erreur lors de l'activation de la commande récurrente",
        done: 'Terminé | Terminés',
        recurrence_end: 'Fin de la récurrence',

        recurrent_deactivate_ok: 'Commande récurrente désactivée avec succès',
        recurrent_deactivate_ko:
            'Erreur lors de la désactivation de la commande récurrente',

        not_implemented: 'Pas encore implémenté',
        update_recurrent: 'Mise à jour de la commande récurrente',
        picked_days_error:
            "Vous n'avez pas renseigné de jours de la semaine pour la récurrence",
        pack_num: 'Nombre de paquets',

        starts: 'Commence le',

        recurrent_orders: 'Commandes récurrentes',

        occurrence: 'Occurrence | Occurrences',
        after: 'Après',
        never: 'Jamais',
        ends: 'Se termine',

        mon: 'L',
        tue: 'M',
        wed: 'M',
        thu: 'J',
        fri: 'V',
        sat: 'S',
        sun: 'D',
        repeat_on: 'Répéter le',

        repeat_every: 'Répéter tou(te)s les',
        week: 'Semaine | Semaines',
        month: 'Mois | Mois',
        year: 'An | Ans',
        recurrence: 'Récurrence de la commande',
        order_is_recurrent: 'Cette commande sera récurrente',
        round_trip: 'Aller - retour',

        client_comments: 'Commentaires client',
        internal_comments: 'Commentaires internes',
        open_in_new_tab: 'Ouvrir dans un nouvel onglet',
        previous_step_not_done: 'Les étapes précédentes ne sont pas réalisées',

        billing_type: 'Type de facturation',

        billing_addr_same_as_client: "Identique à l'adresse du client",
        billing_info: 'Informations de facturation',
        billing_address: 'Adresse de facturation',
        no_driver_trace: 'Parcours réel du livreur indisponible',
        delivered_order: 'Étapes terminées',
        real_driver_tour: 'Parcours réel du livreur',
        software_calc_tour: 'Tournée optimisée par le logiciel',
        fetching_data: 'Récupération des données',
        display_finished: 'Afficher les tournées terminées',
        update_and_copy_order: 'Mettre à jour et créer une commande similaire',
        save_and_copy_order: 'Sauvegarder et créer une commande similaire',
        copy_order: 'Créer une commande similaire',
        delete_steps_pair_info:
            'Le couple enlèvement / livraison sera supprimé de la commande.',
        delete_all_unsaved_steps:
            'Êtes vous sûr(e) de vouloir supprimer toutes les étapes non sauvegardées ?',
        delete_all_unsaved_steps_info:
            'Pour supprimer uniquement un couple enlèvement / livraison, cliquez sur la corbeille située au dessus.',
        same_address: "L'enlèvement et la livraion ont la même adresse",

        previous_step_incomplete: "L'étape précédente est incomplète",

        new_address_generic: 'Nouvelle adresse',

        picked_up: 'Colis récupéré',

        software_calculated_time:
            'Heure de fin / de livraison calculée par le logiciel',
        software_calculated_time_begin:
            "Heure de départ / d'enlèvement calculée par le logiciel",

        software_calculated_time_new:
            'Heure de fin / de livraison calculée par le logiciel mise à jour',
        software_calculated_time_new_begin:
            "Heure de départ / d'enlèvement calculée par le logiciel mise à jour",
        real_deliv_time: 'Heure réelle de fin / livraison',
        real_deliv_time_begin: "Heure réelle d'enlèvement",
        real_start_time: 'Heure de début réelle',
        estimated: 'Estimée',
        real: 'Réelle',
        role: 'Rôle',
        user: 'Utilisateur',
        authorisation: 'Autorisation',
        delete_modal_generic_text_2:
            'Tous les éléments dépendants seront mis à jour (utilisateurs, autorisations, rôles).',
        delete_modal_generic_text_3:
            'Tous les éléments dépendants seront supprimés (quais, défenses, inspections).',
        delete_modal_generic_header: 'Confirmation de la suppression',
        delete_modal_generic_text:
            'Êtes-vous sûr(e) de vouloir supprimer cet élément ?',

        opening_hours: "Horaires d'ouverture",

        not_authorised:
            'Vous ne possédez pas les autorisations nécessaires pour accéder à cette ressource.',

        add_roles_to_user: "Ajout de rôles à l'utilisateur",
        operators: 'Opérateurs',
        admins: 'Administrateurs',
        visitors: 'Visiteurs',
        already_selected_info:
            "L'ajout par type d'utilisateur ne supprimera pas les utilisateurs déjà sélectionnés",
        add_role_to_users: ' Ajout du rôle aux utilisateurs',

        remove: 'Retirer',
        add_authorisations: 'Ajouter des autorisations aux rôles',

        roles_list: 'Rôles utilisateurs',

        selected_users: 'utilisateur selectionné | utilisateurs sélectionnés',
        selected_roles: 'rôle selectionné | rôles sélectionnés',

        admin: 'Admin',
        super_admin: 'SuperAdmin',

        add_roles:
            'Ajout du rôle aux utilisateurs | Ajout des rôles aux utilisateurs',

        add_to_roles: 'Ajouter aux rôles',

        action_not_allowed: 'Action non autorisée',

        role_already_has_auth: 'Ce rôle possède déjà cette autorisation',

        new_role: 'Nouveau rôle',
        update_role: 'Mise à jour du rôle',

        search_by_name: 'Par nom',
        search_by_code: 'Par code',

        search_role: 'Rechercher des rôles',

        add_to_role: 'Ajouter à un rôle',

        is_used_modal_header: 'Activité déjà utilisée',

        is_used_modal_button_update_all:
            "Mettre à jour l'activité et les agendas",
        is_used_activity:
            'Cette activité est déjà utilisée sur un ou plusieurs agendas.',
        is_used_activity2:
            "Souhaitez-vous mettre à jour cette activité et l'ensemble des activités existantes dans les agendas,",
        is_used_activity3:
            "ou en créer une nouvelle qui n'affectera pas les agendas existants ?",
        create_new_activity: 'Créer une nouvelle activité',
        is_used_container_modal_header: 'Colisage déjà utilisé',

        is_used_container_modal_button_update_all:
            'Mettre à jour le colisage et les commandes',
        is_used_container:
            'Ce colisage est déjà utilisé sur une ou plusieurs commandes.',
        is_used_container2:
            "Souhaitez-vous mettre à jour ce colisage et l'ensemble des colisages existants dans les commandes,",
        is_used_container3:
            "ou en créer un nouveau qui n'affectera pas les commandes existantes ?",
        create_new_container: 'Créer un nouveau colisage',
        roles: 'Rôles',
        mail_templates: "Templates d'emails",
        Name: 'Nom',
        Description: 'Description',
        ModificationDate: 'Date de Modification',
        Id: 'Id',
        HttpCode: 'Code HTTP',
        EntityObject: 'Objet entité ',
        EntityName: 'Nom entité',
        Function: 'Fonction',
        Response: 'Réponse',
        google_api_use: 'Utilisation API Google',

        logs: 'Logs',

        new_authorisation: 'Nouvelle autorisation',
        update_authorisation: "Mise à jour de l'autorisation",

        biiim_admin_interface: "Interface d'administration Biiim",

        authorisations: 'Autorisations',
        translations: 'Traductions',

        force_finish: "Passer l'étape en 'terminé'",
        force_inprogress: "Passer l'étape en 'en cours'",
        ms_status: 'Statut | Statuts',
        ms_option: 'Option | Options',

        distance_last_step: "Distance avec l'étape précédente",
        duration_last_step: "Durée par rapport à l'étape précédente",
        dur_n_dist_last_step:
            "Distance & durée par rapport à l'étape précédente",

        bad_time_range_steps:
            "L'heure choisie pour la livraison est incompatible avec l'heure choisie pour l'enlèvement",
        bad_time_range_steps_2:
            "L'heure choisie pour l'enlèvement est incompatible avec l'heure choisie pour la livraison",

        co2_requirement: 'Prendre en compte la consommation de CO2',
        price_requirement: 'Prendre en compte le prix des courses',
        no_delivery_name: "Cette livraison n'a pas de nom",

        merge: 'Fusionner',
        contract_hours: 'Heures contractuelles',
        hours_done: 'Heures effectuées',
        hours_supp: 'Heures supplémentaires',
        hours_miss: 'Heures manquantes',
        weekly_hours: 'Total semaine',
        regular_activity: 'Régulier',

        delete_tour_header: 'Suppression de tournée',
        confirm: 'Confirmer',
        delete_tour_text:
            'Êtes-vous sûr(e) de vouloir supprimer cette tournée ? ',
        unaffect_step_header: "Retirer l'étape de la tournée",
        unaffect_step_text:
            'Êtes-vous sûr(e) de vouloir retirer cette étape de la tournée ? ',
        all_clients: 'Colisage général pour tous les clients',

        hide_show_google_pois: "Afficher / masquer les points d'intêret Google",
        create_container_for_all: 'Créer le colisage pour tous les clients',

        incomplete_step_not_affectable:
            'Cette étape ne peut pas être affectée à une tournée.',

        check_order: 'Veuillez vérifier la commande',

        solo_step: 'Étape orpheline',

        steps_coordinates_errors_1: 'Toutes les étapes ne sont pas',
        steps_coordinates_errors_2: ' correctement géolocalisées.',
        step_coordinates_error: 'Étape mal géolocalisée',

        unaffect: 'Retirer de la tournée',

        external_ref: 'Référence externe',
        trackings_and_proofs: 'Suivi colis et documents',

        no_tracking_data_available:
            'Aucune donnée de suivi disponible pour le moment',

        view_file: 'Voir le document',
        actions: 'Actions',

        pictures: 'Photos',
        signatures: 'Signatures',
        transit: 'En transit',
        // pickup: 'Pick up',
        redelivery: 'Relivraison',
        collect: 'Collecte',
        // delivery: 'Delivery',
        completion_date: 'Date de réalisation',
        tracking_step: 'Étape du suivi',

        package_reference: 'Référence colis',

        detail: 'Détail',

        trackings: 'Suivi des colis',
        tracking_documents: 'Documents',
        undefined_address: 'Adresse non définie.',

        no_available_for_date: 'Aucun résultat pour la date choisie',

        delete_many_ok: 'Eléments supprimés avec succès.',
        delete_many_ko:
            "Une erreur s'est produite lors de la suppression des éléments",
        show_today_only: "Aujourd'hui",

        error_while_loading: 'Erreur de chargement ',

        package_format: 'colisages',

        old_map: 'Ancienne carte',

        pickup: 'Enlèvement',

        daily: 'Live du jour',

        hour: 'heure | heures',
        day: 'Jour | Jours',
        minute: 'minute | minutes',
        belongs_to_other_user: 'Ce colisage appartient à un autre utilisateur',

        mine_only: 'Seulement les miens',
        global_only: 'Genéraux',
        my_package_format: "Ce colisage m'appartient",
        global_pack_format: 'Ce colisage est général',
        past_date:
            'Date invalide: la date choisie est dans le passé ou mal formée.',
        date_error_before:
            "Date invalide: La date choisie est antérieure à celle de l'étape précédente ou est dans le passé. | Date invalide: La date choisie est antérieure à celle de la date d'enlèvement.",
        date_error_after:
            "Date invalide: La date choisie est postérieure à celle de l'étape suivante. | Date invalide: La date choisie est postérieure à la date de livraison.",
        daily_view: 'LIVE - Tournées du jour',
        save_and_relaunch: 'Sauvegarder et recalculer',
        recalculation_in_progress: 'Optimisation des tournées en cours',

        map_loading: 'Chargement de la carte',

        required_fields: "Les champs marqués d'une * sont requis.",

        B2B: 'B2B',
        B2C: 'B2C',
        end_with: 'Finir avec cette étape',
        start_with: 'Commencer avec cettte étape',
        assign_driver: 'Affecter un livreur',
        assign_other_driver: 'Affecter un autre livreur',
        assign_vehicle: 'Affecter un véhicule',
        change_start_time: "Modifier l'heure de départ",
        time_determined_by_software: 'Horaire déterminé par le logiciel.',
        time_determined_by_driver: 'Horaire du livreur.',
        time_determined_custom: 'Horaire personnalisé (choisir ci-dessous).',
        sim_integration_ok: 'Simulation intégrée avec succès dans la tournée',
        sim_integration_ko:
            "Une erreur s'est produite durant l'intégration de la simulation dans la tournée",
        add_tour: 'Ajouter une tournée',
        integrate_simulation: 'Intégrer la simulation',

        create_new_file_type: 'Nouveau type de fichier',
        update_file_type: 'Mise à jour du type de fichier',
        file_type_name: 'Nom du type de fichier',

        new_file_type: 'Nouveau type de fichier de commandes',

        error: 'Erreur',
        active_file_drop_zone_1: 'Déposez vos fichiers ici',
        active_file_drop_zone_2: ' pour les ajouter.',
        inactive_file_drop_zone_1:
            'Faites glisser vos fichiers ici ou cliquez dans la zone de dépôt pour les ajouter.',

        wrong_file_type_csv:
            " n'est pas au format csv et ne peut donc pas être chargé.",
        wrong_file_type_pdf:
            " n'est pas au format pdf et ne peut donc pas être chargé.",
        wrong_file_type_xls:
            " n'est pas au format excel et ne peut donc pas être chargé.",
        errors: 'Erreurs',
        uploaded: 'Chargé',
        inProgress: 'En cours',
        upload_file: 'Ajouter le fichier | Ajouter les fichiers',
        file_type: 'Type de fichier',
        pin_drag_info:
            'Cliquez sur un livreur présent dans le paneau du haut pour réassigner cette étape.',

        status_code_not_editable: "Le code de statut n'est pas modifiable",
        auth_code_not_editable:
            "Le code de l'autorisation n'est pas modifiable",
        role_code_not_editable: "Le code du rôle n'est pas modifiable",
        action_canceled: "Action annulée par l'utilisateur",

        overlap_modal_header: "Chevauchement d'activités",
        overlap_modal_button: "Continuer l'ajout d'activité",
        overlap_activity:
            "L'activité que vous souhaitez ajouter comporte des horaires qui chevauchent ceux d'une activité existante.",
        overlap_activity2: 'Souhaitez-vous la sauvegarder quand même ?',

        code: 'Code',
        error_occured: "Une erreur s'est produite.",

        status_code: 'Code du statut',
        auth_code: "Code de l'autorisation",
        role_code: 'Code du rôle',

        code_already_exists: 'Le code choisi est déjà utilisé',
        deleted_ok: 'Supprimé avec succès',
        create_ok: 'Créé avec succès',
        create_ko: 'Echec lors de la création',
        update_ok: 'Mis à jour avec succès',
        update_ko: 'Echec lors de la mise à jour',
        deleted_ko: 'Echec lors de la suppression',
        update_package_status: 'Mise à jour du statut de colis',
        new_package_status: 'Nouveau statut de colis',
        status_name: 'Nom du statut',
        status_name_info:
            "Texte qui sera affiché à vos clients sur l'application et dans vos listings",
        packages_status: 'Statut du colis | Statuts des colis',

        orders_settings: 'Paramètres des livraisons',
        notifications_settings: 'Paramètres des notifications',
        pricings_settings: 'Paramètres de la tarification',
        status_subtext_pt_1:
            'Vous pouvez ici créer et gérer les status des colis dont vous avez besoin et qui seront affichés à vos clients.',
        status_subtext_pt_2:
            "Vous pouvez par exemple créer un statut spécifique pour le cas où une commande a été passée mais que le colis n'a pas encore été récupéré par vos équipes : 'Pas encore récupéré' ou 'En attente chez le client'. ",
        status_subtext_pt_3:
            "Ou bien encore créer des statuts de refus de colis spécifiques : 'Refusé non commandé', 'Refusé colis déchiré', 'Refusé chaîne du froid rompue'.",

        map: 'Plan',

        PDF: 'Fichier PDF',

        contract_hour: 'Heures contractuelles',

        or: 'Ou',
        create_user_rp_info:
            'Un courriel sera envoyé à cette adresse email pour initialiser le mot de passe du compte',

        create_user_account: "Créer un compte pour l'application et le site",

        create_matching_driver: 'Créer le livreur correspondant',
        create_matching_client: 'Créer le client correspondant',
        do_not_create_matching_driver: 'Ne pas créer le livreur correspondant',
        do_not_create_matching_client: 'Ne pas créer le client correspondant',
        own_account_btn: "Je serai l'utilisateur de ce compte",
        for_other_account_btn: 'Ce compte est pour un autre utilisateur',

        user_type: "Type d'utilisateur",

        create_and_send_mail:
            "Créer et envoyer le courriel d'initialisation de mot de passe à l'utilisateur",

        update_user: 'Mise à jour utilisateur',
        new_user: 'Nouvel utilisateur',

        previous: 'Précédent',

        show_disabled_activities: 'Afficher les activités désactivées',

        activity_type: "Type d'activité",

        atwork: 'Travaillé',
        off_paid: 'Chomé contractuel (calculé dans les heures effectuées)',
        off_not_paid:
            'Chomé hors contrat de travail (non calculé dans les heures effectuées)',

        activity_create_ko: "Erreur lors de la création de l'activité",

        vehicle_deactivate_ok: 'Véhicule désactivé avec succès',
        vehicle_deactivate_ko: 'Erreur lors de la désactivation du véhicule',
        vehicle_activate_ok: 'Véhicule activé avec succès',
        vehicle_activate_ko: "Erreur lors de l'activation du Véhicule",
        copy_to_clipboard: 'Copier dans le presse papier',
        copied_to_clipboard: 'Copié dans le presse papier',
        operator_ref: 'Référence livraison',

        short_from: 'De',
        short_before: 'À',
        pick_color: 'Choisissez une couleur',

        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        edit_activity: "Modifier l'activité",
        new_activity: 'Créer une activité',

        last_step_finished: 'Colis livré',
        delivery_finished: 'Livré',
        delivery_new: 'En attente de prise en charge',
        client_ref: 'Référence client',
        break: 'Rupture',
        hydrogen: 'Hydrogène',

        delivery_start_end: 'Enlèvement & Destination',

        steps_errors: 'Certaines étapes sont incomplètes',
        finished_steps: 'Étapes terminées',
        no_status_found: "Statut de l'étape non defini",
        delivery_name_info_operator:
            'Donnez un nom à votre livraison pour que votre client la retrouve plus facilement!',
        affected: 'Étape affectée à une tournée',
        new: 'Étape pas encore affectée à une tournée',
        executed: 'Terminée',
        affectationError: "Erreur d'affectation",

        delivery_title: 'Livraison du',
        delivery_name_info:
            'Donnez un nom à votre livraison pour la retrouver plus facilement!',

        delivery_name: 'Nom de ma livraison',

        not_specified: 'Non renseigné',
        bike: 'Vélo',
        cargo_bike: 'Vélo cargo',
        cargoBike: 'Vélo cargo',
        deliveryTricycle: 'Tricycle',
        delivery_tricycle: 'Tricycle',
        car: 'Voiture',
        van: 'Fourgon / van',
        boat: 'Bateau',
        truck: 'Camion',
        legs: 'Jambes',
        gas: 'Gaz',
        gasoline: 'Essence',
        diesel: 'Diesel',
        electric: 'Electricité',
        hybrid_gasoline: 'Hybride essence',
        hybrid_diesel: 'Hybride diesel',
        plugin_hybrid_gasoline: 'Hybride essence rechargeable',
        plugin_hybrid_diesel: 'Hybride diesel rechargeable',
        consumption: 'Consommation',
        annual_cost: 'Coût annuel',
        type: 'Type',

        powered: 'Energie',

        update: 'Mettre à jour',
        create: 'Créer',

        no_driver_selected: 'Aucun livreur choisi',
        no_vehicle_type_selected: 'Aucun type de véhicule choisi',

        OrderPackages: 'Colis',

        package_format_not_found: 'Colisage introuvable',

        incomplete_order: 'Commande incomplète.',
        contact_support:
            "Veuillez contacter le support pour plus d'informations.",
        default_header: 'Titre',

        operator: 'Opérateur',

        container_not_editable: "Ce colisage n'est pas modifiable",
        send_rp_link: 'Envoyer un email de réinitialisation de mot de passe',

        container_update_ok: 'Colisage mis à jour',
        container_update_ko: 'Erreur lors de la mise à jour du colisage',
        container_create_ok: 'Colisage créé',
        container_create_ko: 'Erreur lors de la creation du colisage',
        new_container: 'Nouveau colisage',
        update_container: 'Mise à jour du colisage',
        size: 'Dimensions (L x l x H)',

        package_format_delete_ok: 'Colisage supprimé avec succès',
        package_format_delete_ko:
            'Une erreur est survenue lors de la suppression du colisage',
        value_required: 'Ce champ ne peut pas être vide',
        containers: 'Colisages',
        my_containers: 'Mes colisages',

        description: 'Description',

        package_name: 'Appellation',
        package_delete_ok: 'Colis supprimé avec succès',
        package_delete_ko: 'Erreur lors de la suppression du colis',
        back_to_predefined_addresses:
            'Retourner à la liste des adresses existantes',
        back_to_predefined_containers:
            'Retourner à la liste des colisages existants',
        create_my_address: 'Je créé mon adresse de livraison',
        create_my_pickup: "Je créé mon adresse d'enlèvement",
        create_my_container: 'Je créé mon colisage',

        create_address: 'Créer une nouvelle adresse de livraison',
        create_container: 'Créer un nouveau colisage',

        update_and_copy: 'Mettre à jour et créer un colis similaire',
        save_and_copy: 'Sauvegarder et créer un colis similaire',
        update_and_copy_orderfile:
            'Mettre à jour et créer un type fichier de commande similaire',
        save_and_copy_orderfile:
            'Sauvegarder et créer un type de fichier de commande similaire',
        quantity: 'Quantité',
        no_package_type_error: 'Veuillez sélectionner un colisage.',

        select_package_format: 'Choisissez un colisage',
        gros_colis: 'Gros colis',

        pallet: 'Palette | Palettes',
        half_pallet: 'Demi-palette | Demi-palettes ',
        flat_wine_box:
            'Carton 6 bouteilles à plat | Cartons 6 bouteilles à plat ',
        wine_box_down:
            'Carton 6 bouteilles couchées | Cartons 6 bouteilles couchées',
        wine_box_up:
            'Carton 6 bouteilles debouts | Cartons 6 bouteilles debout',
        standard_cheese_box:
            'Carton fromage standard | Cartons fromage standard',
        no_address_selected: 'Aucune adresse de livraison sélectionnée',
        no_package_selected: 'Aucun colisage sélectionné',

        temperature: 'Température',
        no_temp_specified: 'Aucune température spécifiée',
        frozen: 'Inférieure -18°',
        hot: 'Supérieure à 30°',
        cool: 'Entre 0° et 6°',
        value: 'Valeur',
        container_type: 'Type de conteneur (optionnel)',

        lxwxh: '(L x l x H)',

        price: 'Mon tarif',
        package_info: 'Informations sur le colis',

        add_package: "J'ajoute un colis",
        update_package: 'Je mets à jour mon colis',

        duplicate: 'Dupliquer',
        update_order_B2C: 'Mettre à jour ma livraison',
        new_order_B2C: 'Je programme ma livraison',
        today: "Aujourd'hui",
        weekly_planning_management: 'Gestion du planning hebdomadaire',
        user_already_exists: 'Un utilisateur existe déjà avec ces identifiants',

        bad_credentials: "L'identifiant et/ou le mot de passe sont erronés",
        end_month: 'Fin de mois',
        bill_number: 'Numéro de facture',

        next: 'Suivant',
        shepherd: {
            final_button: 'Terminer',
            ok_go: 'Ok guidez moi !',
            restart_tour: 'Relancer le guide',
            no_thanks: 'Non merci',
            stop_tour: 'Arreter le guide',
            create_account: {
                start: {
                    title: 'Bienvenue',
                    text: "Vous trouverez ici un didacticiel pour vous aider dans la création d'un compte. Ce compte va vous permettre de créer de nouvelles commandes et de suivre leurs livraisons en temps reel.",
                },
                step_1: {
                    title: 'Mode Sombre',
                    text: "Ce bouton vous permet de passer en mode sombre. Ce mode permet d'afficher votre page d'accueil, votre barre d'outils, vos paramètres et certaines autres pages dans des tons sombres. ",
                },
                step_2: {
                    title: 'Changement Langue',
                    text: "Ce bouton permet de changer la langue dans laquelle va s'afficher le site.",
                },
                step_3: {
                    title: 'Relancer le guide',
                    text: 'Ce bouton permet de relancer le didacticiel pour la page en cours.',
                },
                step_4: {
                    title: 'Identifiant',
                    text: "Ce champ Email vous servira d'identifiant lors de vos prochaines connexions à l'application. C'est cet email qui servira à recevoir des notifications sur l'avancement de vos livraisons.",
                },
                step_5: {
                    title: 'Contrôle du mot de passe',
                    text: 'Ce bouton permet de rendre visible le mot de passe en cours de saisie.',
                },
                step_6: {
                    title: 'Force du mot de passe',
                    text: 'Cette jauge vous indique la force du mot de passe que vous souhaitez utiliser. Il faudra au moins 8 caracteres, 1 Majuscule, 1 Chiffre et 1 Caractère special (!,*,?,%, ...)',
                },
                step_7: {
                    title: 'Raison Sociale',
                    text: 'Entrez ici le nom de votre entreprise. Si vous laissez ce champ vide, il sera automatiquement rempli avec vos noms et prénoms.',
                },
                step_8: {
                    title: 'Adresse de facturation',
                    text: "Entrez ici votre adresse de facturation. Commencez à taper quelques caractères, et le moteur vous fera des suggestions d'adresses. Vous pouvez également ignorer ces suggestions et taper votre adresse manuellement.",
                },
                step_9: {
                    title: 'Annuler la création de compte',
                    text: 'Ce bouton permet de retourner sur l’écran de connexion.',
                },
                step_10: {
                    title: 'Je créé mon compte',
                    text: 'Ce bouton permet de valider votre demande de création de compte. Une fois votre compte créé vous recevrez un email afin de valider votre adresse email.',
                },
            },
        },
        my_invoices: 'Mes factures',
        my_orders: 'Mes livraisons',
        my_addresses: 'Mes adresses',
        email_used_for_login:
            "L'adresse email que vous utiliserez pour vous connecter",
        user_create_ko: 'Erreur lors de la création du compte',
        user_create_ok: 'Compte créé avec succès!',

        welcome: 'Bienvenue ',
        email: 'Adresse email',
        sign_up: 'Je créé mon compte',
        account_informations: 'Références du compte',
        personnal_infos: 'Informations postales',
        print_label: "Imprimer l'étiquette du colis",
        print_all_labels: 'Imprimer toutes les étiquettes',
        tracking: 'Suivre le colis',
        tracking_all: 'Suivre les colis',

        pricing: 'Mon tarif',
        my_account: 'Mon compte',
        user_update_ok: 'Données personnelles mises à jour avec succès',
        user_update_ko:
            'Erreur lors de la mise à jour des données personnelles',
        pricing_per_tour: 'Tournée : Coût du point de livraison',
        eur_per_h: '€ / h',
        eur_per_km: '€ / km',
        eur_per_step: '€ / point de livraison',
        pricing_per_km: 'Tarification au kilomètre',

        prov_of_service: 'Mise à disposition : Taux horaire',
        login_only: 'Identifiant de connexion',
        api_url: 'URL API',
        software_type: 'Type de logiciel de commande',
        billing: 'Facturation',
        postal_address: 'Adresse postale',
        new_supplier: 'Nouveau fournisseur',
        update_supplier: 'Mise à jour fournisseur',
        supplier_create_ok: 'Fournisseur créé avec succès',
        supplier_create_ko: 'Erreur lors de la création du fournisseur',
        supplier_update_ok: 'Fournisseur mis à jour avec succès',
        supplier_update_ko: 'Erreur lors de la mise à jours du fournisseur',
        deactivated_area: 'Secteur désactivé',
        activate_area: 'Secteur actif',
        activate_vehicle_type: 'Type de véhicule actif',

        activate_driver: 'Livreur actif',
        area_deactivate_ok: 'Secteur désactivé avec succès',
        area_deactivate_ko: 'Erreur lors de la désactivation du secteur',
        area_activate_ok: 'Secteur activé avec succès',
        area_activate_ko: 'Erreur lors de la activation du secteur',
        area_create_ok: 'Secteur créé avec succès',
        area_create_ko: 'Erreur lors de la création du secteur',
        area_update_ok: 'Secteur mis à jour avec succès',
        area_update_ko: 'Erreur lors de la mise à jour du secteur',
        area_error: 'Veuillez modifier le secteur pour sauvegarder.',
        area_type: 'Type de secteur',
        area_by_bike: 'Zone de livraison à vélo',
        download: 'Télécharger',
        new_area: 'Nouveau secteur',
        update_area: 'Mise à jour secteur',
        order_file_title: 'Fichier de commandes -',
        file: 'Fichier',
        lines_to_import: 'Lignes à importer',
        imported_lines: 'Lignes importées',
        simulation_merge_ok: 'Fusion des simulations lancée avec succès',
        simulation_merge_ko: 'Erreur lors de la fusion des simulations',
        relaunch: 'Recalculer',
        relaunch_selection: 'Relancer la génération pour la selection',
        step_needs_correction: "Cliquez pour mettre à jour l'étape",
        ref_address_update_ok: 'Adresse de référence correctement mise à jour',
        ref_address_update_ko:
            "Erreur lors de la mise à jour de l'adresse de référence",
        grouping: 'Regroupement de livraisons',
        fix_lat_lng_info_1:
            "Déplacez le marqueur '?' sur la carte ou renseignez à nouveau le champ adresse 1 pour corriger les coordonnées GPS de l'étape.",
        fix_lat_lng_info_2:
            "Les compléments d'adresse peuvent être renseignés dans le champ adresse 2",
        tours_date: 'Tournées',
        driver: 'Livreur',
        add: 'Ajouter',
        morning: 'Matin',
        afternoon: 'Après-midi',
        planning_update_ok: 'Planning correctement mis à jour',
        planning_update_ko: 'Erreur lors de la mise à jour du planning',
        simulation_generation_ok: 'La génération de la simulation a commencé',
        simulation_generation_ko:
            'Erreur lors de la génération de la simulation',
        planning_create_ok: 'Planning mis à jour avec succès',
        planning_create_ko: 'Erreur lors de la mise à jour du planning',
        planning: 'Planning',
        driver_planning: 'Planning livreur -',
        refresh: 'Rafraîchir',
        deactivate: 'Désactiver',
        activate: 'Activer',

        driver_deactivate_ok: 'Livreur désactivé avec succès',
        driver_deactivate_ko: 'Erreur lors de la désactivation du livreur',
        driver_activate_ok: 'Livreur activé avec succès',
        driver_activate_ko: "Erreur lors de l'activation du livreur",
        container_deactivate_ok: 'Colisage désactivé avec succès',
        container_activate_ko: "Erreur lors de l'activation du colisage",
        container_activate_ok: 'Colisage activé avec succès',
        container_deactivate_ko: 'Erreur lors de la désactivation du colisage',
        vehicler_deactivate_ok: 'Véhicule désactivé avec succès',
        vehicler_activate_ko: "Erreur lors de l'activation du véhicule",
        vehicler_activate_ok: 'Véhicule activé avec succès',
        vehicler_deactivate_ko: 'Erreur lors de la désactivation du véhicule',
        deactivate_selection: 'Désactiver les elements selectionnés',
        activate_selection: 'Activer les elements selectionnés',

        maintenance_delete_ok: 'Maintenance supprimée avec succès',
        maintenance_delete_ko:
            'Erreur lors de la suppression de la maintenance',
        title: 'Titre',
        informations: 'Informations',
        active: 'Actif',
        deactivated: 'Désactivé',
        start_date: 'Date de début',
        end_date: 'Date de fin',
        maintenance: 'maintenance | Maintenances',
        add_maintenance: 'Ajouter une maintenance',
        update_maintenance: 'Mise à jour de la maintenance',
        max_load: 'Charge maximale (kg)',
        package_max_height: "Hauteur maximale d'un colis (cm)",
        package_max_length: "Longueur max. d'un colis (cm)",
        package_max_width: "Largeur max. d'un colis (cm)",

        volume: 'Volume du véhicule (m³)',
        volume_info:
            'En m³ (Mettre la valeur à 0 sur Largeur - Longueur - Hauteur pour saisir manuellement le volume)',
        in_percent: 'En %',
        max_volume: 'Capacité max. du volume (%)',
        package_specifications: 'Caractéristiques des colis',
        vehicle_specifications: 'Caractéristiques du véhicule',
        associated_driver: 'Livreur associé',
        select_driver: 'Choix du livreur',
        maintenance_create_ok: 'Maintenance ajoutée avec succès',
        maintenance_create_ko: "Erreur lors de l'ajout de la maintenance",
        maintenance_update_ok: 'Maintenance mise à jour avec succès',
        maintenance_update_ko:
            'Erreur lors de la mise à jour de la maintenance',
        vehicle_create_ok: 'Véhicule créé avec succès',
        vehicle_create_ko: 'La création du véhicule a échouée',
        vehicle_update_ok: 'Véhicule modifié avec succès',
        vehicle_update_ko: 'La modification du véhicule a échouée',
        vehicle_delete_ok: 'Véhicule supprimé avec succès',
        vehicle_delete_ko: 'Erreur lors de la suppression du véhicule',
        registration: 'Immat. / n° de série',
        brand: 'Marque',
        model: 'Modèle',
        update_vehicle: 'Mise à jour véhicule',
        update_vehicle_type: 'Mise à jour type de véhicule',

        new_vehicle: 'Nouveau véhicule',
        new_vehicle_type: 'Nouveau type de véhicule',

        vehicle_information: 'Informations véhicule',
        vehicle_types: 'Types de véhicules',
        simulation_delete_ok: 'Simulation supprimée avec succès',
        simulation_delete_ko: 'Erreur lors de la suppression de la simulation',
        drivers_requirement: 'Prendre en compte le calendrier des livreurs',
        vehicles_requirement: 'Prendre en compte les véhicules',
        areas_requirement: 'Prendre en compte les secteurs',
        date: 'Date',
        simulation_name: 'Nom de la simulation',
        generate: 'Générer',
        tour_simulation: 'Simulation de tournée',
        params: 'Paramètres',
        requirements: 'Critères',
        no_contact_found_for_client: 'Aucun contact trouvé pour ce client',
        new_simulation: 'Nouvelle simulation',
        address_create_ok: 'Adresse créée avec succès',
        address_create_ko: "La création de l'adresse a échouée",
        contact_create_ok: 'Contact créé avec succès',
        contact_create_ko: 'La création du contact a échouée',
        contact_update_ok: 'Contact créée avec succès',
        contact_update_ko: 'La création du contact a échouée',
        contact_delete_ok: 'Contact supprimé avec succès',
        contact_delete_ko: 'La suppression du contact a échouée',
        driver_delete_ok: 'Livreur supprimé avec succès',
        driver_delete_ko: 'La suppression du livreur a échouée',
        tour_delete_ok: 'Tournée supprimée avec succès',
        tour_delete_ko: 'La suppression de la tournée a échouée',
        mobile: 'Téléphone portable',
        latitude: 'Latitude',
        longitude: 'Longitude',
        start: 'Départ',
        end: 'Arrivée',
        last_known_pos: 'Dernière position connue',
        start_end_pos: 'Positions de départ et arrivée',
        subcontractor: 'Sous traitant',
        supplier: 'Fournisseur',
        select_supplier: 'Choix du fournisseur',
        area: 'Secteur',
        vehicle: 'Véhicule',
        mobile_account: 'Compte pour application mobile',
        select_account: "Choix du compte pour l'application mobile",
        select_vehicle: 'Choix du véhicule',
        select_area: 'Choix du secteur',
        select_type: 'Choix du type de véhicule',
        work_information: 'Informations professionnelles',
        phone_secondary: 'Téléphone secondaire',
        emergency_contact: "Contact en cas d'urgence",
        phone_pro: 'Téléphone professionnel',
        phone_perso: 'Téléphone personnel',
        address_name: "Nom de l'adresse",
        localisation: 'Localisation',
        driver_information: 'Informations personnelles',
        new_driver: 'Nouveau livreur',
        update_driver: 'Mise à jour livreur',
        driver_update_ok: 'Livreur mis à jour avec succès',
        driver_update_ko: 'Erreur lors de la mise à jour du livreur',
        driver_create_ok: 'Livreur créé avec succès !',
        driver_create_ko: 'Echec lors de la création du livreur',
        simulation_status: {
            errors: 'Erreurs',
            archived: 'Archivée',
            requested: 'Demandée',
            in_progress: 'En cours',
            finished: 'Terminée',
        },
        simulations: 'Simulations',
        steps_count: 'Nombre commandes',
        tours_count: 'Nombre tournées',
        distance: 'Distance (km)',
        distance_nokm: 'Distance',

        duration: 'Durée',
        not_affected: 'Étape non affectée | Étapes non affectées',
        report: 'Rapport',
        package_reference_missing: 'Votre colis doit avoir une référence.',
        package_type_missing: 'Choisissez un type de colis.',
        bad_packages: 'Certaines informations sur le colis sont manquantes',
        check_last_package: "Le dernier colis entré n'est pas valide",
        form_errors: 'Le formulaire comporte des erreurs',
        order_update_ok: 'Commande mise à jour avec succès',
        order_update_ko: 'Erreur lors de la mise à jour de la commande ',
        order_create_ok: 'Commande créée avec succès !',
        order_create_ko: 'Echec lors de la création de la commande',
        delivery_update_ok: 'Livraison mise à jour avec succès',
        delivery_update_ko: 'Erreur lors de la mise à jour de la livraison ',
        delivery_create_ok: 'Livraison créée avec succès !',
        delivery_create_ko: 'Echec lors de la création de la livraison',
        and: 'et',
        can_select_address: 'Choisissez une adresse existante.',
        can_select_address_v1: 'Choisissez une adresse',

        bad_time_range:
            'La valeur de la première heure choisie doit être inférieure à la seconde.',
        on: 'Le',
        pick_up_label: 'Enlèvement',
        pickup_label: 'Enlèvement',

        pick_up: 'Enlèvement du',

        delivery_label: 'Destination',
        delivery: 'Livraison du',

        between: 'Entre',
        from: 'À partir de ',
        before: "Jusqu'à",
        package_add_not_allowed:
            'Ajoutez une étape pour pouvoir ajouter un colis',
        number: 'Numéro',
        package_type: 'Type de colis',
        width: 'Largeur (cm)',
        length: 'longueur (cm)',
        height: 'Hauteur (cm)',
        packages: 'Colis',
        package: 'Colis',

        references: 'Réferences',
        comments: 'Commentaires',
        add_steps: 'Ajouter des étapes',
        add_step: 'Ajouter une étape',

        contact: 'Contact',
        new_contact: 'Nouveau contact',
        update_contact: 'Mise à jour du contact',
        update_ref_address:
            "Reporter les modifications sur l'adresse postale de référence",
        full_order: 'Voir la commande',
        order_step: 'Étape de commande',
        reference_postal_address: 'Adresse postale de référence',
        no_ref_address: 'Aucune adresse postale de référence trouvée',
        weekly_planning: 'Planning hebdomadaire',
        required_field: 'Ce champ doit être rensseigné',
        select_client_info: 'Veuillez selectionner un client',
        select_driver_info: 'Veuillez selectionner un livreur',
        select_vehicle_info: 'Veuillez selectionner un véhicule',

        select_contact_info: 'Veuillez selectionner un contact',
        bad_zip_format: "Le code postal n'est pas au bon format",
        bad_phone_format: "Le numéro de téléphone n'est pas au bon format",
        bad_time_format: "L'heure renseignée n'est pas au bon format",
        bad_hour_format:
            "Le nombre d'heures au contrat n'a pas le bon format xx:xx",
        bad_date_format: "La date renseignée n'est pas au bon format",
        back: 'Retour',
        address_type: "Type d'adresse",
        destination: 'Destination',
        origin: 'Origine',
        new_address: 'Nouvelle adresse de livraison',
        order_step_correction: 'Correction des adresses de livraison',
        generate_invoice: 'Générer facture',
        business_hour_updated: 'Horaires mis à jour',
        business_hour_update_error:
            'Erreur lors de la mise à jour des horaires',
        save: 'Sauvegarder',
        contact_firstname: 'Prénom du contact',
        contact_lastname: 'Nom du contact',
        contact_name_imported: 'Nom du contact importé',
        open: 'Ouvert',
        closed: 'Fermé',
        waiting_time: "Temps d'attente",
        waiting_time_info:
            "Le temps d'attente est exprimé en minutes. La valeur prise par défaut est 5 minutes.",
        driver_info: 'Informations livreur',
        additional_info: 'Informations complémentaires',
        contacts: 'Contacts',
        new_item: 'Créer',
        select_one: 'Sélectionner',
        contact_and_wait: "Contact & temps d'attente",
        delivery_hours: 'Horaires',
        add_contact: 'Ajouter un contact',
        client_update_ok: 'Client mis à jour avec succès',
        client_update_ko: 'Erreur lors de la mise à jour ',
        client_delete_ok: 'Client supprimé avec succès',
        client_delete_ko: 'Erreur lors de la suppression du client',
        user_delete_ok: 'Utilisateur supprimé avec succès',
        user_delete_ko: "Erreur lors de la suppression de l'utilisateur",
        order_delete_ok: 'Commande supprimée avec succès',
        order_delete_ko: 'Erreur lors de la suppression de la commande',
        delivery_delete_ok: 'Livraison supprimée avec succès',
        delivery_delete_ko: 'Erreur lors de la suppression de la livraison',
        address_delete_ok: 'Adresse de livraison supprimé avec succès',
        address_delete_ko:
            "Erreur lors de la suppression de l'adresse de livraison",
        address_update_ok: 'Adresse mise à jour avec succès',
        address_update_ko: 'Erreur lors de la mise à jour ',
        update_address: "Mise à jour d'une adresse",
        to: 'à',
        firstname: 'Prénom',
        lastname: 'Nom',
        phone: 'Téléphone',
        phone_imported: 'Téléphone importé',
        transfer: 'Virement',
        all: 'Tous',
        search_name: 'Nom',
        action: 'Actions',
        dashboard: 'Tableau de bord',
        En: 'En',
        Fr: 'Fr',
        by_name: 'par nom',
        optionnal: 'facultatif',
        contact_information: 'Informations du contact',
        client_information: 'Informations du client',
        password: 'Mot de passe',
        login: "Adresse email ou nom d'utilisateur",
        email_address: 'Adresse email',
        confirm_password: 'Confirmez le mot de passe',
        order: 'Ordre de tri',
        pw_notice:
            'Le mot de passe doit contenir au moins sept caractères et comporter une majuscule, une minuscule, un chiffre et un caractère spécial. Vous devez au moins atteindre le niveau vert pour valider votre mot de passe',
        logout: 'Déconnexion',
        notifications: 'Notifications',
        open_menu: 'Ouvrir le menu',
        forgot_password: 'Mot de passe oublié ?',
        connect: 'Connexion',
        show: 'Afficher',
        hide: 'Masquer',
        nightmode: 'Mode nuit',
        lang_switch: 'Changer la langue',
        company_name: 'Raison sociale',
        address: 'Adresse',
        postal_code: 'Code postal',
        city: 'Ville',
        registration_number: 'SIRET',
        client_type: 'Type de client',
        payment_type: 'Type de paiement',
        search: 'Rechercher',
        new_client: 'Nouveau client',
        update_order: 'Mise à jour commande',
        clients: 'Clients',
        client: 'Client',
        creation_date: 'Date de création',
        modification_date: 'Date de mise à jour',

        invoices: 'Factures',
        vehicles: 'Véhicules',
        vehicle_type: 'Type de véhicule',
        vehicleType: 'Type de véhicule',
        price_charged: 'Prix facturé (€)',
        orders: 'Commandes | Livraisons',
        becycle_orders: 'Commandes | Livraisons',

        new_order: 'Nouvelle commande | Je programme ma livraison',
        import_orders: 'Importer un fichier de commandes',
        imported_orders: 'Fichiers importés',
        imp_orders: 'Commandes importées',
        imported_order: 'Commande importée',

        tours: 'Tournées',
        suppliers: 'Fournisseurs',
        areas: 'Secteurs',
        users: 'Utilisateurs',
        drivers: 'Livreurs',
        settings: 'Paramétrage',
        login_error: "Nom d'utilisateur ou mot de passe incorrect",
        delivery_addresses: 'Adresses',
        mass_update: 'Mises à jour en masse',
        loading: 'Chargement...',
        name: 'Nom',
        missing_login:
            "Merci de renseigner une adresse email ou un nom d'utilisateur valide.",
        missing_password: 'Merci de renseigner un  mot de passe.',
        reference: 'Référence',
        number_of_parcels: 'Nombre de colis',
        delivery_mode: 'Mode de livraison',
        id: 'Id',
        weight: 'Poids (kg)',
        unit_weight: 'Poids unitaire (kg)',

        worth: 'Valeur (€)',
        reset_pw_ok:
            'Si le compte renseigné est valide, un courriel sera envoyé avec les instructions pour mettre à jour le mot de passe.',
        reset_pw_instructions:
            'Les instructions de réinitialisation du mot de passe seront envoyées à votre adresse de courriel enregistrée.',
        reset_pw_modal_title: 'Réinitialiser votre mot de passe',
        pw_reset: 'la réinitialisation du mot de passe',
        send: 'Envoyer',
        cancel: 'Annuler',
        password_strength: {
            start: 'Commencez à taper pour vérifier la sécurité de votre mot de passe',
            weak: 'Très faible',
            better: 'faible ',
            nice: 'Moyen',
            good: 'Bon',
            perfect: 'Fort',
        },
        passwords_dont_match: 'Les mots de passe ne correspondent pas',
        client_create_ok: 'Client créé avec succès !',
        client_create_ko: 'Echec lors de la création du client ',
        search_address:
            'Rechercher une adresse. Les champs adresse seront automatiquement remplis.',
        no_records_found: 'Aucun enregistrement trouvé',
        sort_by: 'Trier par',
        items_per_page: 'Enregistrements par page',
        occasional: 'Course occasionnelle',
        month_invoice: 'Facturation au mois',
        monthinvoice: 'Facturation au mois',
        notselected: 'Non sélectionné',
        check: 'Chèque',
        filters: 'Filtres',
        page: 'Page',
        of: 'sur',
        results: '{n} Résultat | {n} Résultats',
        no_filter: 'Aucun filtre',
        steps: 'Étapes',
        step: 'Étape',

        status: 'Statut',
        in_progress: 'En cours',
        inprogress: 'En cours',
        finished: 'Terminé(e)',
        by_client: 'par client',
        by_reference: 'par réference',
        delivery_type: {
            express: 'Express',
            fast: 'Rapide',
            normal: 'Normal',
        },
        express: 'Express',
        fast: 'Rapide',
        normal: 'Normal',
        delivery_status: {
            in_progress: 'En cours',
            finished: 'Terminée',
            errors: 'Erreurs',
        },
        uploads: {
            in_progress: 'En cours',
            finished: 'Terminé',
            uploaded: 'Chargé',
            errors: 'Erreurs',
        },
        delete: 'Supprimer',
        edit: 'Modifier',
        update_client: 'Mise à jour client',
        for: 'pour',
        opening_time: 'Ouverture',
        closing_time: 'fermeture',
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
        delete_selection: 'Supprimer les éléments sélectionnés',
    },
}
export default translations
