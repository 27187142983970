<template>
    <div class="mx-auto bg-white">
        <stepper
            :key="refresh"
            :givenStep="currentStep"
            :totalStepsCount="[1, 2]"
            @next="currentStep += 1"
            @prev="currentStep -= 1"
            @submit="saveNotification()"
            @cancel="resetForm()"
        >
            <template v-if="currentStep === 1" #step1>
                <div class="space-y-4">
                    <h2 class="text-2xl font-bold mb-1">
                        {{
                            `${$t('type_event')} ${
                                isDuplicate === true
                                    ? ' - (' + $t('copy_noun') + ')'
                                    : ''
                            }`
                        }}
                    </h2>
                    <hr class="mb-2" />
                    <div class="grid grid-cols-6 gap-4">
                        <!-- <div class="col-span-6">
                            <Multiselect
                                v-if="!isUpdate && !isDuplicate"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="notificationForm.Id"
                                :options="notificationOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_one')"
                                :allow-empty="true"
                                :can-clear="true"
                                @select="setNotification($event)"
                                @clear="setNotification()"
                            ></Multiselect>
                             <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="noHarborError"
                            >
                                <div class="error-msg">
                                    {{ $t('no_results') }}
                                </div>
                            </div> -
                        </div>
                        <div
                            v-if="!isUpdate"
                            class="col-span-6 text-base font-bold"
                        >
                            {{ $t('or') + ' ' + $t('create') }}
                        </div> -->
                        <div class="col-span-2">
                            <label class="block mb-2">{{ $t('name') }}</label>
                            <input
                                v-model="notificationForm.Name"
                                @blur="v$.notificationForm.Name.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.Name
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-2">
                            <label class="block mb-2">{{
                                $t('EntityName')
                            }}</label>
                            <Multiselect
                                :id="'delaiUnit' + index"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="notificationForm.EntityName"
                                :options="entitiesOptions"
                                :hide-selected="true"
                                label="name"
                                track-by="name"
                                :searchable="false"
                                :placeholder="$t('select_one')"
                                :allow-empty="false"
                                :can-clear="false"
                                :object="false"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.EntityName
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2">{{ $t('code') }}</label>
                            <input
                                :disabled="isUpdate"
                                v-model="notificationForm.Code"
                                @blur="v$.notificationForm.Code.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.Code
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="block mb-2">{{
                            $t('description')
                        }}</label>
                        <textarea
                            v-model="notificationForm.Description"
                            class="border rounded px-3 py-2 mt-1 h-32 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.notificationForm.Description
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center pt-4 justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="notificationForm.Active"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('activate_event') }}</label
                        >
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 2" #step2>
                <div class="justify-center my-4">
                    <h2 class="text-2xl font-bold mb-1">
                        {{
                            notificationForm.Name +
                            ' / ' +
                            notificationForm.EntityName +
                            ' / ' +
                            notificationForm.Code
                        }}
                    </h2>
                    <div class="text-sm italic">
                        {{ notificationForm.Description }}
                    </div>
                </div>
                <hr class="mb-3" />
                <div class="space-y-4">
                    <h2 class="text-xl flex justify-center font-bold mb-1">
                        {{ `${$t('trigger_tc', 2)}` }}
                    </h2>
                    <hr class="mb-3" />
                    <!-- <div class="grid grid-cols-6 gap-4 mt-10"> 
                         <div class="col-span-2"> 
                             <span v-if="emailTemplates.length > 0">
                                <div
                                    class="font-bold text-md mb-4 items-center"
                                >
                                    <fa-icon
                                        icon="envelopes-bulk"
                                        class="mr-2"
                                    ></fa-icon>
                                    {{ $t('available_email_templates') }}
                                </div>
                                <hr class="my-4" />
                                <div
                                    v-for="(
                                        emailTemplate, indexTemplate
                                    ) in emailTemplates"
                                    :key="indexTemplate"
                                >
                                    <div class="w-full h-full">
                                        <h3
                                            class="text-md font-medium my-1 theme-color"
                                        >
                                            {{ $t(emailTemplate.name) }}
                                        </h3>
                                        <iframe
                                            :srcdoc="emailTemplate.htmlContent"
                                            style="
                                                width: -webkit-fill-available;
                                            "
                                        >
                                        </iframe>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                            </span>
                            <span v-if="smsTemplates.length > 0">
                                <div
                                    class="font-bold text-md mb-2 items-center"
                                >
                                    <fa-icon
                                        icon="comment-sms"
                                        class="mr-2"
                                    ></fa-icon>
                                    {{ $t('available_sms_wa_templates') }}
                                </div>
                                <hr class="my-4" />

                                <div
                                    v-for="(
                                        smsTemplate, indexsmsTemplate
                                    ) in smsTemplates"
                                    :key="indexsmsTemplate"
                                >
                                    <div class="w-full h-full">
                                        <h3
                                            class="text-md font-medium my-1 theme-color"
                                        >
                                            {{ $t(smsTemplate.name) }}
                                        </h3>
                                        <iframe
                                            :srcdoc="smsTemplate.htmlContent"
                                            style="
                                                width: -webkit-fill-available;
                                            "
                                        >
                                        </iframe>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div class="col-span-4"> -->
                    <div
                        v-for="(
                            trigger, index
                        ) in notificationForm.EventTriggers"
                        :key="index"
                    >
                        <div class="grid grid-cols-12 gap-2 mb-2 items-center">
                            <div class="col-span-6">
                                <label
                                    :for="'triggerMS' + index"
                                    class="font-medium"
                                    >{{ $t('trigger_tc', 1) }}</label
                                >
                                <Multiselect
                                    :id="'triggerMS' + index"
                                    v-model="trigger.Id"
                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                    :options="triggersOptions"
                                    :hide-selected="true"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :placeholder="$t('select_trigger')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    :object="false"
                                    @select="selectTrigger($event, index)"
                                ></Multiselect>
                                <div
                                    class="text-xs italic mb-2"
                                    v-for="error of v$.notificationForm
                                        .EventTriggers.$each.$response.$errors[
                                        index
                                    ].Action"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-3">
                                <label
                                    :for="'delai' + index"
                                    class="font-medium"
                                    >{{ $t('delay') }}</label
                                >
                                <input
                                    :id="'delai' + index"
                                    type="number"
                                    :min="0"
                                    :step="1"
                                    v-model="trigger.Delai"
                                    class="w-full px-3 py-2 border rounded-md"
                                />
                                <div
                                    class="text-xs italic mb-2"
                                    v-for="error of v$.notificationForm
                                        .EventTriggers.$each.$response.$errors[
                                        index
                                    ].Delai"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <label
                                    :for="'delaiUnit' + index"
                                    class="font-medium"
                                    >{{ $t('unit_delay') }}</label
                                >
                                <Multiselect
                                    :id="'delaiUnit' + index"
                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="trigger.UnitDelai"
                                    :options="delaiUnitOptions"
                                    :hide-selected="true"
                                    label="name"
                                    track-by="name"
                                    :searchable="false"
                                    :placeholder="$t('select_one')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    :object="false"
                                ></Multiselect>
                                <div
                                    class="text-xs italic mb-2"
                                    v-for="error of v$.notificationForm
                                        .EventTriggers.$each.$response.$errors[
                                        index
                                    ].UnitDelai"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div class="flex justify-end">
                                    <label
                                        :for="'trash-canIcon' + index"
                                        class="font-medium"
                                    ></label>
                                    <fa-icon
                                        :id="'trash-canIcon' + index"
                                        icon="trash-can"
                                        :title="$t('remove')"
                                        class="cursor-pointer error-msg mt-3"
                                        @click.prevent="
                                            selectTrigger(trigger, index, true)
                                        "
                                    ></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="trigger.TemplateEmail"
                            class="w-full h-full"
                            :key="refreshIFrame"
                        >
                            <h3 class="text-lg font-bold mt-4">
                                {{ $t(trigger.TemplateEmail.name) }}
                            </h3>
                            <iframe
                                :srcdoc="trigger.TemplateEmail.htmlContent"
                                class="h-96"
                                style="width: -webkit-fill-available"
                            >
                            </iframe>
                        </div>
                        <hr class="mt-3" />
                    </div>

                    <div
                        v-if="
                            notificationForm.EventTriggers.length === 0 ||
                            notificationForm.EventTriggers[
                                notificationForm.EventTriggers.length - 1
                            ].UnitDelai !== ''
                        "
                        class="flex justify-center items-center my-2"
                    >
                        <button
                            type="button"
                            @click.prevent="addTrigger()"
                            class="themed-button text-base ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                        >
                            <fa-icon icon="plus" class="fa-sm mr-2"></fa-icon>

                            {{ $t('add_trigger') }}
                        </button>
                    </div>
                    <!-- </div>
                    </div> -->
                </div>
            </template>
        </stepper>
    </div>
</template>

<script>
import Stepper from '@/components/atoms/Stepper.vue'
import { useNotificationStore } from '@/stores/notificationStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    name: 'CreateOrUpdateNotification',
    components: {
        Stepper,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            emailTemplates: [],
            smsTemplates: [],
            notificationOptions: [],
            refresh: 0,
            refreshIFrame: 0,
            // selectedTriggers: [],
            triggersOptions: [],
            noTriggerError: false,
            notificationForm: {
                Id: '',
                Name: '',
                Description: '',
                Code: '',
                EntityName: '',
                Active: true,
                EventTriggers: [],
                EventTriggersToRemove: [],
            },
            currentStep: 1,
            delaiUnitOptions: [
                { name: this.$t('minutes'), value: 'minutes' },
                { name: this.$t('hours'), value: 'hours' },
                { name: this.$t('days'), value: 'days' },
            ],
        }
    },
    props: {
        notificationId: {
            type: Number,
            default: null,
        },

        isUpdate: {
            type: Boolean,
            default: false,
        },
        isDuplicate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
        ...mapWritableState(useNotificationStore, {
            notification: 'current',
            entitiesOptions: 'entitiesOptions',
        }),
    },

    mounted() {
        this.getEntities()
        this.getEmailsTemplates().then((res) => {
            this.emailTemplates = []
            if (res && res.status === 200) {
                res.data.templates.forEach((template) => {
                    if (template.id !== 8 && template.isActive) {
                        this.emailTemplates.push(template)
                    }
                })
            }
        })
        this.searchTriggers({
            query: [],
            filter: [],
            sortDirection: 'ASC',
            sortField: 'Action',
            perPage: 10000,
            page: 1,
        })
            .then((res) => {
                this.triggersOptions = []

                res.data.Records.forEach((trigger) => {
                    // todo set triggeroptions
                    const option = {
                        value: trigger.Id,
                        name: `${trigger.Id} - ${this.$t(trigger.Action)}`,
                        Action: trigger.Action,
                        Delai: trigger.Delai,
                        UnitDelai: trigger.UnitDelai,
                        IdTemplateEmail: trigger.IdTemplateEmail,
                        IdTemplateSMS: trigger.IdTemplateSMS,
                        IdTemplateWhatsApp: trigger.IdTemplateWhatsApp,
                        TemplateEmail: trigger.TemplateEmail,
                        Id: trigger.Id,
                    }
                    this.triggersOptions.push(option)
                })
            })
            .catch((err) => {
                this.noTriggerError = true
            })
        // this.triggersOptions = [
        //     { name: this.$t('sendEmail'), value: 'sendEmail' },
        //     { name: this.$t('sendSms'), value: 'sendSms', disabled: true },
        //     {
        //         name: this.$t('sendEmailWhatsApp'),
        //         value: 'sendEmailWhatsApp',
        //         disabled: true,
        //     },
        //     {
        //         name: this.$t('sendEmailSms'),
        //         value: 'sendEmailSms',
        //         disabled: true,
        //     },
        //     {
        //         name: this.$t('sendWhatsApp'),
        //         value: 'sendWhatsApp',
        //         disabled: true,
        //     },
        //     {
        //         name: this.$t('sendSmsWhatsApp'),
        //         value: 'sendSmsWhatsApp',
        //         disabled: true,
        //     },
        //     {
        //         name: this.$t('sendEmailSmsWhatsApp'),
        //         value: 'sendEmailSmsWhatsApp',
        //         disabled: true,
        //     },
        // ]

        if (
            (this.isUpdate === true || this.isDuplicate === true) &&
            this.notificationId
        ) {
            this.fetchOne(this.notificationId).then((res) => {
                this.currentStep = 1

                this.notificationForm = structuredClone(res.data)

                this.notificationForm.EventTriggersToRemove = []
                this.notificationForm.Active =
                    this.notificationForm.Active === 1
                // this.selectedTriggers = []
                const notificationOption = {
                    value: this.notificationForm.Id,
                    name: this.notificationForm.Name,
                    Name: this.notificationForm.Name,
                    Id: this.notificationForm.Id,
                    Code: this.notificationForm.Code,
                    EntityName: this.notificationForm.EntityName,
                    Description: this.notificationForm.Description,
                    Active: this.notificationForm.Active === 1,
                    EventTriggers: this.notificationForm.EventTriggers,
                }
                this.notificationOptions.push(notificationOption)
                if (this.notificationForm.EventTriggers.length > 0) {
                    this.notificationForm.EventTriggers.forEach((trigger) => {
                        // todo set triggeroptions
                        trigger.value = trigger.Id
                        trigger.name = `${trigger.Id} - ${this.$t(trigger.Action)}`

                        // this.selectedTriggers.push(selectedTrigger)
                    })
                }

                if (this.isDuplicate === true) {
                    this.notificationForm.Id = ''
                    this.notificationForm.Name = `${this.$t(
                        'copy_noun',
                    )} - ${this.notificationForm.Name}`
                    this.notificationForm.Code = ''
                }
                this.refresh += 1
            })
        }
    },
    validations() {
        return {
            notificationForm: {
                Name: {
                    required,
                },
                Description: {
                    required,
                },
                Code: {
                    required,
                },
                EntityName: {
                    required,
                },
                EventTriggers: {
                    $each: helpers.forEach({
                        Delai: {
                            required,
                        },
                        UnitDelai: {
                            required,
                        },
                        Action: {
                            required,
                        },
                    }),
                },
            },
            // selectedTriggers: {
            //     $each: helpers.forEach({
            //         Delai: {
            //             required,
            //         },
            //         UnitDelai: {
            //             required,
            //         },
            //         Action: {
            //             required,
            //         },
            //     }),
            // },
        }
    },
    methods: {
        ...mapActions(useNotificationStore, [
            'create',
            'update',
            'fetchOne',
            'searchTriggers',
            'getEmailsTemplates',
            'getEntities',
        ]),

        resetForm() {
            this.notificationForm = {
                Id: '',
                Name: '',
                Description: '',
                Code: '',
                EntityName: '',
                Active: true,
                EventTriggers: [],
                EventTriggersToRemove: [],
            }

            this.currentStep = 1
            this.$emit('cancel')
        },

        setNotification(notificationId = null) {
            if (notificationId === null) {
                this.notificationForm = {
                    Id: '',
                    Name: '',
                    Code: '',
                    EntityName: '',
                    Active: true,
                    Description: '',
                    EventTriggers: [],
                    EventTriggersToRemove: [],
                }

                this.currentStep = 1
                return
            }
            const notification = this.notificationOptions.find(
                (pr) => pr.Id === notificationId,
            )
            this.notificationForm = notification
        },
        addTrigger() {
            this.notificationForm.EventTriggers.push({
                Id: '',
                Delai: 0,
                Action: '',
                name: '',
                value: 0,
                UnitDelai: 'minutes',
                IdEventType: this.notificationForm.Id ?? null,
                IdTemplateEmail: 0,
                IdTemplateSMS: 0,
                IdTemplateWhatsApp: 0,
                TemplateEmail: null,
            })
        },
        selectTrigger(trigger, index = null, remove = false) {
            // TODO ne fonctionne pas comme pour les clients tableau normal
            const trig = this.triggersOptions.find((tr) => tr.value === trigger)
            if (remove === false) {
                const selectedTrigger = {
                    Id: trig.value,
                    name: trig.name,
                    value: trig.value,
                    Name: trig.name,
                    DefaultPricing: false,
                    Delai: trig.Delai,
                    Action: trig.Action,
                    UnitDelai: trig.UnitDelai,
                    IdEventType: this.notificationForm.Id ?? null,
                    IdTemplateEmail: trig.IdTemplateEmail,
                    IdTemplateSMS: trig.IdTemplateSMS,
                    IdTemplateWhatsApp: trig.IdTemplateWhatsApp,

                    TemplateEmail: trig.TemplateEmail,
                }
                this.refreshIFrame += 1
                this.notificationForm.EventTriggers[index] = selectedTrigger
            } else {
                this.notificationForm.EventTriggers.splice(index, 1)
                if (trigger.Id > 0 && trigger.Id !== '') {
                    this.notificationForm.EventTriggersToRemove.push({
                        Id: trigger.Id,
                    })
                }
            }
            // this.pricingForm.PricingParamClients = this.selectedParams
        },
        // selectTrigger(trigger, index = null) {
        //     console.log('trigger', trigger)
        //     console.log('index', index)
        //     if (trigger.value === 0) {
        //         this.selectedTriggers = []
        //         this.notificationForm.EventTriggers.forEach((trToRem) => {
        //             this.notificationForm.TriggersToRemove.push({
        //                 Id: trToRem.Id,
        //             })
        //         })
        //     } else if (index === null) {
        //         const selectedTrigger = {
        //             Id: trigger.value,
        //             name: trigger.name,
        //             value: trigger.value,
        //             Name: trigger.name,
        //             DefaultPricing: false,
        //             Delai: trigger.Delai,
        //             Action: trigger.Action,
        //             UnitDelai: trigger.UnitDelai,
        //             IdTypeEvent: trigger.IdTypeEvent,
        //             IdTemplateEmail: trigger.IdTemplateEmail,
        //             IdTemplateSMS: trigger.IdTemplateSMS,
        //             TemplateEmail: trigger.TemplateEmail,
        //         }
        //         this.selectedTriggers.push(selectedTrigger)
        //     } else {
        //         this.selectedTriggers.splice(index, 1)
        //         if (trigger.Id > 0) {
        //             this.notificationForm.TriggersToRemove.push({
        //                 Id: trigger.Id,
        //             })
        //         }
        //     }
        //     this.notificationForm.EventTriggers = this.selectedTriggers
        // },

        async saveNotification() {
            this.v$.notificationForm.$reset()
            const isValid = await this.v$.notificationForm.$validate()
            if (!isValid) {
                this.$toast.error(this.$t('form_errors'))
                return
            }
            if (this.isUpdate === false) {
                this.create(this.notificationForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_created'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                this.update(this.notificationForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_updated'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            }
        },
    },
    unmounted() {
        this.notification = null
    },
}
</script>

<style scoped>
/* Styles Tailwind déjà intégrés dans les classes */
</style>
