<template>
    <div class="mr-22 ml-3 bg-white darky">
        <div class="darky bg-white mb-20 ml-2 pb-20 z-20 w-full fixed">
            <nav-tabs
                id="navtabs"
                :key="updateNavTabs"
                :navTabs="tabs"
                :redirect="false"
                @navigate="changeContent($event, true)"
            />
        </div>
        <component
            class="pt-20"
            :is="componentToDisplay"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import NavTabs from '@/components/elements/NavTabs.vue'
import actionsAccess from '@/resources/actionsAccess'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { mapStores } from 'pinia'

import EventTypeList from './notifications/EventTypeList.vue'
import TriggersList from './notifications/TriggersList.vue'

export default {
    name: 'NotificationsWrapper',
    components: {
        NavTabs,
        EventTypeList,
        TriggersList,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            tabs: [],
            componentToDisplay: this.humanize('eventType_list'),
            updateNavTabs: 0,
        }
    },
    validations() {
        return {}
    },
    mounted() {
        window.scrollTo(0, 0)
        this.tabs = []
        // if (checkAccess(this.userStore, this.settingsActionsAccess.orders)) {
        //     this.tabs.push({
        //         icon: 'cart-shopping',
        //         name: 'orders_settings',
        //         disabled: false,
        //     })
        // }
        // if (checkAccess(this.userStore, this.settingsActionsAccess.pricings)) {
        this.tabs.push(
            {
                icon: 'bullhorn',
                name: 'eventType_list',
                disabled: false,
                wrappedIn: 'notifications_settings',
            },
            {
                icon: 'power-off',
                name: 'triggers_list',
                disabled: true,
            },
            {
                icon: 'envelopes-bulk',
                name: 'emailTemplates_list',
                disabled: true,
            },
            {
                icon: 'comment-sms',
                name: 'smsTemplates_list',
                disabled: true,
            },
            {
                icon: ['fab', 'whatsapp'],
                name: 'waTemplates_list',
                disabled: true,
            },
            // bell-concierge
            // {
            //     icon: 'map-location',
            //     name: 'postal_groups_list',
            //     disabled: false,
            // },
            // {
            //     icon: 'map-location',
            //     name: 'pricing_postal_groups',
            //     disabled: false,
            // },
        )
        if (this.$route.query && this.$route.query.to) {
            this.componentToDisplay = this.humanize(this.$route.query.to)
        } else {
            this.changeContent('eventType_list')
        }
    },

    unmounted() {},
    computed: {
        ...mapStores(useUserStore),
        settingsActionsAccess() {
            return actionsAccess.settings.pricings
        },
    },
    watch: {
        $route(to, from) {
            if (to.query && to.query.to) {
                this.componentToDisplay = this.humanize(to.query.to)
            } else {
                this.changeContent('eventType_list', true)
            }
        },
    },
    methods: {
        changeContent(tab, andAddQuery = false) {
            if (andAddQuery === true) {
                const query = { ...this.$route.query, to: tab }
                this.$router.replace({ query })
            }
            // const query = { ...this.$route.query, to: tab }
            // this.$router.replace({ query })
            this.componentToDisplay = this.humanize(tab)
            this.updateNavTabs += 1
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
