<template>
    <!-- <div
        class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
    > -->
    <div
        class="flex darky bg-white mb-20 pt-4 z-10 pb-2 items-center w-full fixed"
        :class="marginTop"
    >
        <ul
            v-for="(tab, index) in navTabs"
            :key="index"
            class="flex flex-wrap -mb-px text-sm font-bold text-center text-zinc-400"
        >
            <router-link
                :to="
                    tab.disabled === false && redirect === true
                        ? { name: tab.name }
                        : ''
                "
                @click="
                    tab.disabled === false ? $emit('navigate', tab.name) : ''
                "
                :disabled="tab.disabled"
                :class="tab.disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
                <li class="mr-2">
                    <div
                        v-if="tab.multiIcons"
                        :title="`${$t(tab.names[0])} & ${$t(tab.names[1])}`"
                        class="p-4 rounded-t-lg border-b-2 border-r-2 border-t-2 tab-highlight group flex items-center whitespace-nowrap"
                        :class="
                            $route.name === tab.name ||
                            $route.query.page === tab.name
                                ? 'nav-tab rounded-t-lg border-b-2 active'
                                : ''
                        "
                    >
                        <div
                            v-for="(icon, index) in tab.multiIcons"
                            :key="index"
                            :class="$route.name === tab.name ? 'nav-tab' : ''"
                        >
                            <fa-icon
                                :icon="icon"
                                class="mr-1"
                                :class="index !== 0 ? 'ml-2' : ''"
                            ></fa-icon>
                            <div class="hidden xl:inline nav-tab-text">
                                <span>
                                    {{ $t(tab.names[index]) }}
                                    {{
                                        index !== tab.multiIcons.length - 1
                                            ? ' & '
                                            : ''
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else-if="tab.comboIcon"
                        :title="$t(tab.name)"
                        class="p-4 rounded-t-lg border-b-2 border-r-2 border-t-2 tab-highlight group flex items-center whitespace-nowrap"
                        :class="
                            $route.name === tab.name ||
                            $route.query.page === tab.name
                                ? 'nav-tab rounded-t-lg border-b-2 active'
                                : ''
                        "
                    >
                        <div :class="$route.name === tab.name ? 'nav-tab' : ''">
                            <fa-layer class="fa-fw mr-2">
                                <fa-icon :icon="tab.iconA"></fa-icon>

                                <fa-icon
                                    transform="shrink-6"
                                    :icon="tab.iconB"
                                ></fa-icon>
                                <!-- transform="shrink-6" -->
                            </fa-layer>
                            <div class="hidden xl:inline nav-tab-text">
                                <span>
                                    {{ $t(tab.name) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        :title="$t(tab.name)"
                        class="p-4 rounded-t-lg border-b-2 border-r-2 border-t-2 tab-highlight group"
                        :class="
                            $route.name === tab.name ||
                            $route.query.to === tab.name
                                ? 'nav-tab rounded-t-lg border-b-2 active'
                                : ''
                        "
                    >
                        <fa-icon :icon="tab.icon" class="mr-2"></fa-icon>
                        <div class="hidden xl:inline nav-tab-text">
                            <span>
                                {{ $t(tab.name) }}
                            </span>
                        </div>
                    </div>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
import { useUserStore } from '@/stores/userStore'
import { mapState } from 'pinia'

export default {
    name: 'NavLayout',
    computed: {
        ...mapState(useUserStore, { isB2C: 'isB2C' }),
    },
    props: {
        navTabs: {
            type: Array,
            default: () => [],
        },
        redirect: {
            type: Boolean,
            default: true,
        },
        marginTop: {
            type: String,
            default: 'mt-12',
        },
    },
}
</script>

<style></style>
